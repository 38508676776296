import React, { useEffect, useState, useRef } from 'react'
import { FileUploader } from "react-drag-drop-files";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { CloseIcon, DownIcon, ImageTypeIcon, PDFTypeIcon, UpIcon, UploadIcon, VideoTypeIcon } from '../../Static/icons/iconshere';
import AOS from "aos";
import UploadComponent from './components/uploadComponent';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { BtnLoading } from '../../animations/BtnLoading';
import { debounce } from '../../utils/useWindowDimensions';

const dynamicContent = {
    image: [],
    video: [],
    pdf: []
}

const emptyFile = {
    url: null,
    splittedImages : []
}

const ENVContent = () => {
    const configData = useSelector((state) => state.configSlice);
    const userData = useSelector((state) => state.userSlice);

    const { uuid } = useParams();

    const [loading, setLoading] = useState(false);
    const [activeType, setActiveType] = useState('image');
    const [content, setContent] = useState(dynamicContent);
    const [inProgress, setInProgress] = useState(0);
    const [canSave, setCanSave] = useState(true);

    const getDynamicContent = async () => {
        try {
            const { data } = await axios.get(`${configData.config.UNO_URL}/api/resource/environment/dynamic/content/${uuid}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userData?.token}`,
                }
            });
            const response = data?.data?.versions[0].data || dynamicContent;
            setContent(response);
        } catch (error) {
            console.log(error);
        }
    };

    const increment = (type) => {
        var previousContent = { ...content };
        previousContent[type].push(emptyFile)
        setContent(previousContent);
    };
    const updateItem = (type, index, item) => {
        var previousContent = { ...content };
        previousContent[type][index] = item;
        setContent(previousContent);
    }
    const setLength = (type, number) => {
        var previousContent = { ...content };
        var existing = previousContent[type].length;
        var difference = parseInt(number) - existing;
        if (difference > 0) {
            for (var i = 0; i < difference; i++) {
                increment(type);
            }
        } else if (difference < 0) {
            for (var i = 0; i < Math.abs(difference); i++) {
                decrement(type);
            }
        }
    }
    const decrement = (type) => {
        var previousContent = { ...content };
        previousContent[type].pop()
        setContent(previousContent);
    };

    const handleSaveContent = async () => {
        try {
            await axios.post(`${configData.config.UNO_URL}/api/resource/environment/update/dynamic/content/${uuid}`, { data: content }, {
                headers: {
                    "Content-Type ": "application/json",
                    Authorization: `Bearer ${userData?.token}`,
                }
            });
            window.location.reload();
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }

    const handleCancel = () => {
        getDynamicContent();
    }

    
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    useEffect(() => {
        getDynamicContent()
    }, [uuid]);


    const allFiles = [...content.image, ...content.pdf, ...content.video];
    var toError = allFiles.filter((e) => e.url == null).length;
    var toSave = allFiles.length > 0 ? allFiles.every((e) => e.url != null) : false;
  
    const debounceHandleSaveReview = debounce(handleSaveContent, 1000, setLoading);
    
      return (
        <>
            <div className="envdetails detailspage container-fluid" data-aos="fade-left">
                <div className="row">
                    <div className="col-md-12 dynamicC ">
                        <div className="form-group">
                            <label htmlFor="exampleFormControlInput1">Environment Name <span>*</span></label>
                            <div className='filetype mt-3'>
                                <div className={activeType === 'image' ? 'selectparent active' : 'selectparent'}>
                                    <span className='fileitem' onClick={() => setActiveType("image")}>
                                        <ImageTypeIcon />
                                    </span>
                                </div>
                                <div className={`input-upload-num ${activeType !== 'image' && 'd-none'}`}>
                                    <input
                                        type="number"
                                        id="numberInput"
                                        value={content['image'].length}
                                        onChange={(e) => setLength('image', Number(e.target.value))}
                                    />

                                    <div className='updown'>
                                        <button onClick={() => increment("image")}>
                                            <UpIcon />
                                        </button>
                                        <button onClick={() => decrement("image")}>
                                            <DownIcon />
                                        </button>
                                    </div>
                                </div>

                                <div className={activeType === 'video' ? 'selectparent active' : 'selectparent'}>
                                    <span className='fileitem' onClick={() => setActiveType("video")}>
                                        <VideoTypeIcon />
                                    </span>
                                </div>
                                <div className={`input-upload-num  ${activeType !== 'video' && 'd-none'}`}>
                                    <input
                                        type="number"
                                        id="numberInput"
                                        value={content['video'].length}
                                        onChange={(e) => setLength('video', Number(e.target.value))}
                                    />

                                    <div className='updown'>
                                        <button onClick={() => increment("video")}>
                                            <UpIcon />
                                        </button>
                                        <button onClick={() => decrement("video")}>
                                            <DownIcon />
                                        </button>
                                    </div>
                                </div>

                                <div className={activeType === 'pdf' ? 'selectparent active' : 'selectparent'}>
                                    <span className='fileitem' onClick={() => setActiveType("pdf")}>
                                        <PDFTypeIcon />
                                    </span>
                                </div>
                                <div className={`input-upload-num ${activeType !== 'pdf' && 'd-none'}`}>
                                    <input
                                        type="number"
                                        id="numberInput"
                                        value={content['pdf'].length}
                                        onChange={(e) => setLength('pdf', e.target.value)}
                                    />

                                    <div className='updown'>
                                        <button onClick={() => increment("pdf")}>
                                            <UpIcon />
                                        </button>
                                        <button onClick={() => decrement("pdf")}>
                                            <DownIcon />
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className='row fordynamicparent'>
                    <div className={` uploadInventory dynamic-content`}>
                        {content && content[activeType]?.length ? (
                            content[activeType].map((item, index) => (
                                <div className='upload-parent' key={`${activeType}-${index}`}>
                                    <UploadComponent
                                        canSave={canSave}
                                        setCanSave={setCanSave}
                                        fileType={activeType}
                                        item={item}
                                        updateItem={updateItem}
                                        inProgress={inProgress}
                                        setInProgress={setInProgress}
                                        index={index}
                                    />
                                </div>
                            ))
                        ) : null}
                    </div>
                    </div>

                    <div className="col-md-12 pe-2 mt-0">
                        <div className="footerbtn text-end">
                            <button onClick={handleCancel}>Cancel</button>
                            {toSave == true && <button onClick={debounceHandleSaveReview} >
                                {
                                    loading ? <BtnLoading/> : "Save"
                               } 
                            </button>}
                        </div>

                         {toError > 0 ? <p className='error text-end'>To save, kindly upload files into every available place.</p> : null }
                    
                    </div>
                </div>

            </div>
        </>
    )
}

export default ENVContent
