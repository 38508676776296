import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from "react-redux";
import { FormControl, RadioGroup, Radio, FormControlLabel } from "@mui/material";
import SubSectionAssets from './SubSectionAssets';
import SubSectionQuiz from './SubSectionQuiz';

const AssetTab = ({ basicData }) => {
    const configData = useSelector((state) => state.configSlice);
    const userData = useSelector((state) => state.userSlice);
    const params = useParams();
    const uuid = params.uuid;
    const [contentType, setContentType] = useState("media");
    const [resource, setResource] = useState("");
    const [section, setSection] = useState("");
    const [subSection, setSubSection] = useState("");

    useEffect(()=>{
        if(basicData){
          setSubSection(basicData.name);
          setSection(basicData.parent.name);
          setResource(basicData.parent.parent.name);
          setContentType(basicData?.versions[0]?.data?.contentType || "");
        }
      },[basicData]);

  return (
    <>

        <div className='filterhere'>
            <div className='row lms detailspage mt-4'>
                
                <div className="col-md-12 pt-3 mb-3 d-flex align-item-center justify-content-start">
                  <div className='headdetail gap-1'>
                  <span className="pagetitle ">Course Name : {resource} </span>
                  <span className="pagetitle ">Section Name : {section}</span>
                  <span className="pagetitle ">SubSection Name : {subSection}</span>
                  </div>
                </div>

                <div className='col-md-12 mb-3'>
                    <div className='form-group'>
                    <label> Select Asset <span>*</span> </label>
                    
                        <FormControl fullWidth className="mb-0 mt-2 pt-0 d-flex align-items-start">
                            <RadioGroup
                            row
                            aria-labelledby="price-visibility-label"
                            name="questionType"
                            className="gap-3"
                            value={contentType}
                            onChange={(event)=>setContentType(event.target.value)}
                            >
                            <FormControlLabel
                                className="quizlabel"
                                value="media"
                                control={<Radio />}
                                label="Media"
                            />
                            <FormControlLabel
                                className="quizlabel"
                                value="quiz"
                                control={<Radio />}
                                label="Assessment"
                            />
                        
                            </RadioGroup>
                        </FormControl>

                    </div>
                </div>

            {contentType === "media" &&
                <SubSectionAssets basicData={basicData} />
            }
            {contentType === "quiz" &&
                <SubSectionQuiz  />
            }

            </div>
        </div>

    </>
  )
}

export default AssetTab
