import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import LottiePlayer from "lottie-react";
import loadingAnimation from '../../animations/loading.json';

const CenteredDiv = styled('div')(() => ({
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    position : 'fixed',
    overflow : 'hidden',
}));
const FullScreenLoader  = ({ loading = false }) => {
    if (!loading) return null;
    return (
        <CenteredDiv>
            <LottiePlayer
                animationData={loadingAnimation}
                style={{ height: 250 }}
                autoplay
                loop
            />
        </CenteredDiv>
    )
};
FullScreenLoader.propTypes = {
   loading : PropTypes.bool
  };
  
export default FullScreenLoader;