import React, { useRef, useState } from "react";
import { UploadIcon, CloseIcon } from "../../../Static/icons/iconshere";
import { ConnectingAirportsOutlined } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { type } from "@testing-library/user-event/dist/type";


const fileTypes = {
  "image": ['image/*'],
  "video": ['video/*'],
  "pdf": ['.pdf'],

}

const UploadComponent = ({ fileType, item, updateItem, index}) => {
  const configData = useSelector((state) => state.configSlice);
  const userData = useSelector((state) => state.userSlice);
  var [uploading, setUploading] = useState(false);
  var [uploadProgress, setUploadProgress] = useState(0);

  const { uuid } = useParams();
  const dropAreaRef = useRef(null);

  const handleFileChange = async (event) => {
    setUploading(true);
    setUploadProgress(0)
    const files = event.target.files[0];

    let types;
    if (fileType === "image") types = files?.type.split("/")[0];
    if (fileType === "video") types = files?.type.split("/")[0];
    if (fileType === "pdf") types = files?.type.split("/")[1];

    if (types === fileType) {
      const formData = new FormData();
      formData.append('file', files);

      try {
        const { data } = await axios.post(
          `${configData.config.UNO_URL}/api/resource/environment/upload/dynamic/${fileType}/${uuid}`, formData, {
          headers: {
            Authorization: `Bearer ${userData?.token}`,
            'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: (progressEvent) => {
              let progress = (progressEvent.loaded / progressEvent.total) * 100;
              if (progress < 100) {
                  setUploadProgress(Math.floor(progress));
              }
          }
        }
        );
        delete data.status;
        var newItem = { ...data};
        updateItem(fileType, index, newItem);
        setUploadProgress(100)
        setUploading(false);
        // setUploadedFiles([...uploadedFiles, ...updatedFiles]);
      } catch (error) {
        console.error('Error uploading file:', error);
        setUploading(false);
      }
    }

  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    handleFileChange({ target: { files } });
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDragEnter = () => {
    dropAreaRef.current.classList.add('drag-over');
  };

  const handleDragLeave = () => {
    dropAreaRef.current.classList.remove('drag-over');
  };

  const formatSize = (bytes) => {
    if (bytes >= 1048576) {
      return (bytes / 1048576).toFixed(2) + ' MB';
    } else if (bytes >= 1024) {
      return (bytes / 1024).toFixed(2) + ' KB';
    } else {
      return bytes + ' bytes';
    }
  };

  // const handleCancelFile = (index) => {
  //   const filteredFiles = uploadedFiles.filter((_, i) => i !== index);
  //   setUploadedFiles(filteredFiles);
  // };

  return (
    <div className='upperdiv'>
      {!item.url ? (
        <div
          ref={dropAreaRef}
          className="dropandselect"
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
        >
          <label htmlFor="fileUpload">
            <UploadIcon />
            <p>
              Drag & Drop or <span> Choose file </span> to upload
            </p>
            <input
              type="file"
              id="fileUpload"
              name="fileUpload"
              onChange={handleFileChange}
              accept={fileTypes[fileType]}
            />
          </label>
        </div>
      )
        :
        (
          <div className="uploadInventory dynamic-content">
           

              {item.url && (
                <>
                  {fileType === "image" && (
                    <img src={item.url} alt="Preview" />
                  )}
                  {fileType === "video" && (
                    <video
                      style={{ width:"100%", height:"100%", maxWidth: '100%', maxHeight: '100%', objectFit: 'cover' }}
                      controls
                    >
                      <source src={item.url} type="video/mp4" />
                    </video>
                  )}
                  {fileType === "pdf" && (
                    <iframe src={item.url} title="PDF Preview"></iframe>
                  )}
                </>
              )}

              <div className="uploaddata">
                <div className="sidedata">
                  <span className="canceldelete" onClick={() => updateItem(fileType, index, { url: null })} style={{ cursor: 'pointer', lineHeight: '1' }}>
                    <CloseIcon />
                  </span>
                </div>
          
              </div>

            

          </div>
        )}
      {uploading && <div className="mb-1 progress">
        <div className="uploadProgressBar">
          <div className="progress mt-2" style={{ height: '10px' }}>
            <div className="progress-bar progress-bar-striped progress-bar-animated bg-danger" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{ width: `${uploadProgress}%`, height: '10px' }}></div>
          {/* <p className="filesize">{uploadProgress}%</p> */}
          </div>
          {/* <p>processing<span className="dot-animation"></span></p> */}
        </div>
      </div>}
    </div>
  );
};

export default UploadComponent;
