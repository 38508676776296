import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import { FileUploader } from "react-drag-drop-files";
import axios from "axios";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState, convertFromHTML } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { AddIcon, DeleteIcon } from "../../Static/icons/iconshere";
import { BtnLoading } from "../../animations/BtnLoading";
import { createNewCustomTags, skillStream2DTagCategoryWithTags } from "../utils";
import { debounce } from "../../utils/useWindowDimensions";

const BasicDetailsTab = ({ setResource }) => {
  const params = useParams();
  const id = params?.uuid;
  const tagsData = useSelector((state) => state.tagsSlice);
  const configData = useSelector((state) => state.configSlice);
  const userData = useSelector((state) => state.userSlice);
  const [learningPoints, setLearningPoints] = useState([]);
  const [outcomesPoints, setOutcomesPoints] = useState([]);
  const [loading, setLoading] = useState(false);
  const [progressBar, setProgressBar] = useState(false);
  const [moduleName, setModuleName] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [description, setDescription] = useState("");
  const [thumbnail, setThumbnail] = useState("");
  const [fileError, setFileError] = useState(null);
  const [categoryTagsOptions, setCategoryTagsOptions] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [aboutProjectState, setAboutProjectState] = useState(
    EditorState.createEmpty()
  );

  const handleHandoverAboutChangeInput = (event) => {
    setAboutProjectState(event);
    setDescription("" + draftToHtml(convertToRaw(event.getCurrentContent())));
  };

  const uploadThumbnailImage = async (file) => {
    try {
      setProgressBar(true);
      const formData = new FormData();
      formData.append("file", file);

      let { data } = await axios.post(`${configData.config.UNO_URL}/api/resource/2d/lms/upload/thumbnail/${id}`, formData, {
          headers: {
            Authorization: `Bearer ${userData?.token}`,
            "Content-Type ": "multipart/form-data",
          },
        }
      );

      setThumbnail(data.url);
    } catch (error) {
      console.log(error);
    } finally {
      setProgressBar(false);
    }
  };

  const resetAllFields = () =>{
    setModuleName("");
    setDescription("");
    setLearningPoints([]);
    setOutcomesPoints([]);
    setThumbnail("");
    setFileError(null);
  }

  const getBasicDetails = async () => {
    try {
      let { data } = await axios.get(`${configData.config.UNO_URL}/api/resource/2d/lms/basic/${id}`, {
          headers: {
            Authorization: `Bearer ${userData.token}`,
          }});

      setModuleName(data?.data?.name);
      setResource({
          name: data?.data?.name || "",
          thumbnail: data?.data?.versions[0]?.data?.thumbnail || ""
      });
      setLearningPoints(data?.data?.versions[0]?.data?.learningPoints || []);
      setOutcomesPoints(data?.data?.versions[0]?.data?.outcomesPoints || []);
      setThumbnail(data?.data?.versions[0]?.data?.thumbnail || "");
      setDescription(data?.data?.versions[0]?.data?.description);
      setShortDescription(data?.data?.versions[0]?.data?.short);
      const description = EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(data?.data?.versions[0]?.data?.description || "")));
      setAboutProjectState(description);

      let moduleTags = data?.data?.tags?.map((item) => ({
        value: item.id,
        label: item.name,
        categoryId: item.categoryId,
      }));

      let _data = skillStream2DTagCategoryWithTags();
      setSelectedCategory(moduleTags?.filter(e => e?.categoryId === _data?.categories.filter(cat => cat?.name === 'Category')[0]?.id));

      
    } catch (error) {
      console.log(error);
    }
  };

  const getTagList =() =>{
    let _data = skillStream2DTagCategoryWithTags();
    setCategoryTagsOptions(_data?._categoryLevelTags)
  };


  useEffect(() => {
    getTagList();
  }, [tagsData]);

  useEffect(() => {
    getBasicDetails();
    resetAllFields();
  }, [id]);

  const addLearningPointer = () => {
    setLearningPoints((prevPoints) => [...prevPoints, ""]);
  };

  const deleteLearningPointer = (index) => {
    var newPoints = [...learningPoints];
    newPoints.splice(index, 1);
    setLearningPoints(newPoints);
  };

  const handleLearningPointChange = (event, index) => {
    var points = [...learningPoints];
    points[index] = event.target.value;
    setLearningPoints(points);
  };

  const addOutcomesPointer = () => {
    setOutcomesPoints((prevPoints) => [...prevPoints, ""]);
  };

  const deleteOutcomesPointer = (index) => {
    var newPoints = [...outcomesPoints];
    newPoints.splice(index, 1);
    setOutcomesPoints(newPoints);
  };

  const handleOutcomesPointChange = (event, index) => {
    var points = [...outcomesPoints];
    points[index] = event.target.value;
    setOutcomesPoints(points);
  };

  const handleUpdateModuleDetails = async () => {
    try {

      let allTagsList = [];
      selectedCategory?.map((e) => {
        return allTagsList.push(Number(e?.value));
      });
      let bodyData = {
        name: moduleName,
        tags: [...allTagsList],
        data: {
          thumbnail: thumbnail,
          learningPoints: learningPoints,
          outcomesPoints: outcomesPoints,
          description: description,
          short: shortDescription
        },
      };

      await axios.post(`${configData.config.UNO_URL}/api/resource/2d/lms/basic/${id}`, bodyData, {
          headers: {
            Authorization: `Bearer ${userData.token}`,
          }});
      getBasicDetails()
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const removeThumbnail = () => {
    setThumbnail("");
    setFileError(null);
  };

  const debounceHandleSaveReview = debounce(handleUpdateModuleDetails, 1000, setLoading);
  
  return (
    <>
      <div className="allcompages lms">
        <div className="detailspage container-fluid lms">
          <div className="mainbox">
            <div className="row mt-3">
              <div className="col-md-4">
                <div className="row">
                  <div className="col p-0">
                  <div className="imgupload">
                  <label className="mb-1">Thumbnail Image
                    <span>*</span>
                  </label>
                  
                  
                </div>
                  <div className="modulepic justify-content-start">
                    {progressBar ? (
                      <div className="uploadProgressBar">
                        <div  className="progress mt-2" style={{ height: "10px" }}>
                          <div
                            className="progress-bar progress-bar-striped progress-bar-animated bg-danger"
                            role="progressbar"
                            aria-valuenow="75"
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={{ width: "100%", height: "10px" }}
                          ></div>
                        </div>
                        <p>
                          Uploading<span className="dot-animation"></span>
                        </p>
                      </div>
                    ) : (
                      <>
                        <div className="thumbnailImageDiv">
                          {thumbnail.length > 0 ? (
                            <div>
                              <img
                                id="thumbnailImage"
                                src={thumbnail}
                                alt="Uploaded Thumbnail"
                              />
                              <span
                                className="canceldelete"
                                onClick={removeThumbnail}
                              >
                                X
                              </span>
                            </div>
                          ) : (
                            <div className="fileuploader">
                              <FileUploader
                                handleChange={uploadThumbnailImage}
                                name="thumbnailInput"
                                label="drag & drop or choose file to upload"
                              />
                            </div>
                          )}
                        </div>
                      </>
                    )}
                    </div>
                    { fileError == "" ? 
                    <p className="recommend">Recommended Ratio* (16:9)</p>
                    :
                    <p className="fileError">{fileError}</p>
                    }
                  </div>
                  
                </div>
                <div className="row">
                <div className="col-12">
                <div className="form-group pt-3 mt-1">
                  <label htmlFor="exampleFormControlInput1">
                    What you will learn ?
                  </label>
                  <div className="pointer-list">
                    {learningPoints?.map((point, index) => (
                      <span key={index} className="pointer-here">
                        <input
                          placeholder={`Point number : ${index + 1}`}
                          className="form-control mt-1"
                          id="exampleFormControlShortDesc"
                          value={point}
                          onChange={(event) =>
                            handleLearningPointChange(event, index)
                          }
                        />
                        <button
                          className="delete-point"
                          onClick={() => deleteLearningPointer(index)}
                        >
                          <DeleteIcon />
                        </button>
                      </span>
                    ))}
                    <button className="addpoint" onClick={addLearningPointer}>
                      <AddIcon />
                      Add
                    </button>
                  </div>
                </div>
                </div>
                <div className="col-12">
                <div className="form-group pt-3 mt-1">
                  <label htmlFor="exampleFormControlInput1">Outcomes :</label>
                  <div className="pointer-list">
                    {outcomesPoints?.map((point, index) => (
                      <span key={index} className="pointer-here">
                        <input
                          placeholder={`Point number : ${index + 1}`}
                          className="form-control mt-1"
                          id="exampleFormControlShortDesc"
                          value={point}
                          onChange={(event) =>
                            handleOutcomesPointChange(event, index)
                          }
                        />
                        <button
                          className="delete-point"
                          onClick={() => deleteOutcomesPointer(index)}
                        >
                          <DeleteIcon />
                        </button>
                      </span>
                    ))}
                    <button className="addpoint" onClick={addOutcomesPointer}>
                      <AddIcon />
                      Add
                    </button>
                  </div>
                </div>
                </div>
                </div>
              </div>


            <div className="col-md-8">
                <div className="row">
                <div className="col-md-6 mt-2">
                <div className="form-group">
                  <label htmlFor="exampleFormControlInput1">
                    Course Title <span>*</span>
                  </label>
                  <input
                    type="text"
                    value={moduleName}
                    onChange={(e) => setModuleName(e.target.value)}
                    className="form-control mt-2"
                    id="exampleFormControlInput1"
                    placeholder="Enter Course Title"
                  />
                </div>
              </div>
              <div className="col-md-6 mt-3">
              <div className="form-group">
                <label htmlFor="exampleFormControlInput1">
                  Category <span>*</span>
                  </label>
                <Select
                  isMulti
                  name="Category"
                  className="basic-multi-select mt-2"
                  classNamePrefix="select"
                  placeholder="Select Category"
                  options={categoryTagsOptions}
                  value={selectedCategory}
                  onChange={setSelectedCategory}
                  components={{ NoOptionsMessage: (props) => createNewCustomTags({ ...props, customName: "Category" }) }}
                  filterOption={(option, inputValue) =>
                    option.label.toLowerCase().includes(inputValue.toLowerCase())
                  }
                />
              </div>
              </div>


              <div className="col-md-6 mt-3">
              <div className="form-group">
                <label htmlFor="exampleFormControlInput1">
                Short Description <span>*</span>
                  </label>
                  <input
                  value={shortDescription}
                  onChange={(e) => setShortDescription(e.target.value)}
                  placeholder="Short Description"
                  className="form-control mt-2"
                  id="exampleFormControlShortDesc"
                />
              </div>
              </div>


              <div className="col-md-12 mt-3">
              <div className="form-group pt-3">
                <label htmlFor="exampleFormControlDescription" className="form-label">
                  Description
                </label>
                <div className="modalDescription">
                  <Editor
                    name="project_about"
                    id="exampleFormControlDescription"
                    editorState={aboutProjectState}
                    stripPastedStyles={true}
                    wrapperClassName="demo-wrapper"
                    className="mt-2"
                    editorClassName="demo-editor"
                    onEditorStateChange={handleHandoverAboutChangeInput}
                    toolbar={{
                      options: ["inline", "remove", "history"],
                      inline: {
                        inDropdown: false,
                        options: ["bold", "italic", "underline", "strikethrough"],
                      },
                    }}
                  />
                  </div>
              </div>
              </div>

                </div>
            </div>
           

           
             
            </div>
          </div>
        </div>

        <div className="col-12">
          <div className="footerbtn text-end">
            <button>Cancel</button>
            <button onClick={debounceHandleSaveReview}>
              {loading ? <BtnLoading /> : "Save & Submit"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default BasicDetailsTab;
