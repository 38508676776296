import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { TextField } from "@mui/material";
import { CommentsDisabledRounded } from '@mui/icons-material';
import { DeleteIcon, EditIcon } from '../../Static/icons/iconshere';

const ScenariosList = ({ showScenario, handleCloseScenario, handleSaveScenario, scenarioList , updateTaskListDeleteScenario}) => {

    const [name, setName] = useState("");
    const [error, setError] = useState(null);
    const [updateScenarioId, setUpdateScenarioId] = useState(null);

    const handleClose = () =>{
        setName("");
        setError(null);
        setUpdateScenarioId(null);
        handleCloseScenario();
    }

    const createScenario = () =>{
        setError(null);
        if(name.trim().length > 0){
            if(scenarioList.length > 0){
            let maxNumber = Math.max(...scenarioList.map(item => item.id))
            handleSaveScenario([...scenarioList,{
                id:Number(maxNumber+1),
                name:name
            }]);
            }else{
                handleSaveScenario([{
                    id:Number(1),
                    name:name
                }]);
            }
           setName("");
            return  
        }
        setError("kindly fill name");
    }

    const updateScenario = () =>{
        setError(null)
        if(name.trim().length > 0){
            let updatedScenario  = scenarioList.map((e)=> e.id == updateScenarioId ? {id:e.id, name: name} : e );
            handleSaveScenario(updatedScenario);
            setName("");
            setUpdateScenarioId(null);
            return
        }
        setError("kindly fill name")
    }

    const handleEditScenario = (e) =>{
      setUpdateScenarioId(e.id);
      setName(e.name);
    }

    const handleDeleteScenario = (e) =>{
     let findIndex =  scenarioList.findIndex(f => f.id == e.id )
     if(findIndex > -1){
        scenarioList.splice(findIndex,1);
        handleSaveScenario(scenarioList);
        updateTaskListDeleteScenario(e.id)
     }
    }

    return (
        <>
            <Modal show={showScenario} size='md' centered onHide={handleClose}>
                <Modal.Body className='platformhead quizmodal p-4'>
                    <form>
                        <div className='row quizmodalhead'>
                            <div className='col-md-12 text-center'>
                                <h5 className='headquiz'>
                                    Create Scenario
                                </h5>
                            </div>
                        </div>

                        <div className='pt-1'>
                            <div className="quesformbox mt-3">
                                <div className="form-group mb-3">
                                    <label htmlFor="">Name</label>
                                    <TextField
                                        placeholder="Name"
                                        fullWidth
                                        name="name"
                                        size='small'
                                        type='text'
                                        variant="outlined"
                                        value={name}
                                        onChange={(e)=> setName(e.target.value)}
                                    />
                                    <p className="error" >{error}</p>
                                </div>

                                <div className='row align-items-center'>
                                    <div className='col-md-6'></div>
                                    <div className='col-md-6'>
                                        <div className='text-end footerbtn text-end'>
                                            <Button variant="secondary" onClick={handleClose}>
                                                Cancel
                                            </Button>
                                            {updateScenarioId ?
                                                <Button onClick={updateScenario}> Update </Button>
                                                :
                                                <Button onClick={createScenario}> Create </Button>
                                            }
                                        </div>                                    
                                    </div>
                                </div>
                            </div>
                    
                            <div className='row align-items-center'>
                                {scenarioList.length > 0 &&
                                    <div className='col-md-12'>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>S.n</th>
                                                <th>Name</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        {scenarioList.map((f,i)=>{
                                            return(
                                                <tbody>
                                                    <tr>
                                                    <td>{i+1}</td>        
                                                    <td>{f.name}</td>        
                                                    <td> 
                                                        <span className='pointer m-4' onClick={()=>handleEditScenario(f)} > <EditIcon /> </span> 
                                                        <span className='pointer' onClick={()=>handleDeleteScenario(f)} > <DeleteIcon /> </span> 
                                                        </td>        
                                                    </tr>
                                                </tbody>
                                            )
                                        })}
                                    </table>
                                    </div>
                                }
                            </div>

                        </div>

                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ScenariosList
