const {  createSlice } = require("@reduxjs/toolkit");

const initialState={
    content:{
    },
    loading:true,
    error:true
}

const contentSlice  = createSlice({
  name:"contentSlice",
  initialState,
  reducers:{
      saveContent:(state,action)=>{
        state.content = action.payload.data;
        state.loading = false;
        state.error = false;
      },
  },

});

export const { saveConfig } = contentSlice.actions;
export default contentSlice.reducer;