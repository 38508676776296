import { createSlice } from "@reduxjs/toolkit";

let initialState={
    allTagsList:{},
    assetTagsList:{},
    skillStream2D:{}
}

const tagsSlice = createSlice({
    name:"tagsSlice",
    initialState,
    reducers:{
        addModuleXRTags:(state,action)=>{
            state.allTagsList = action.payload
        },
        addAssetTags:(state, action)=>{
            state.assetTagsList = action.payload
        },
        addSkillStream2DTags:(state,action)=>{
            state.skillStream2D = action.payload
        },
        removeModuleXRTags:(state)=>{
            state.allTagsList = {}
        },
        removeAssetTags:(state, action)=>{
            state.assetTagsList = {}
        },
        removeSkillStream2DTags:(state, action)=>{
            state.skillStream2D = {}
        }
    }
});

export const { addModuleXRTags, removeModuleXRTags, addAssetTags, removeAssetTags, addSkillStream2DTags, removeSkillStream2DTags } = tagsSlice.actions;
export default tagsSlice.reducer;