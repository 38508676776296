import React from 'react'
import { Nav, Tab } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';


const AssetsTabs = (props) => {
    const navigate = useNavigate();
    const { id } = useParams();
    const Child = props.ChildComponent;
    const { tabName } = props;

    const handleTabSelect = (selectedTab) => {
        if (selectedTab === "back") {
            navigate(-1)
        }
    };

    return (
        <>
            <div className='filterhere'>

                <Nav variant="tabs" activeKey={tabName} onSelect={handleTabSelect}>
                    <Nav.Item>
                        <Nav.Link eventKey="back"><span><i className="bi-chevron-left"></i> Back </span></Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="assetdetails">Upload Assets</Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item>
                        <Nav.Link  eventKey="Users">Users</Nav.Link>
                    </Nav.Item> */}
                </Nav>

            </div>
            <div className='allcompages mt-3'>
                <Child {...props}></Child>
            </div>
        </>
    )
}
export default AssetsTabs