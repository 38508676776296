import React, { useState, useEffect, useRef } from 'react'
import { FileUploader } from "react-drag-drop-files";
import axios from "axios";
import { useSelector, useDispatch } from 'react-redux';
import { UploadStat } from '../../Static/icons/iconshere';
import Progress from 'react-progressbar';
import Releases from '../Releases/releases';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import AOS from "aos";
import "aos/dist/aos.css";
import moment from "moment"
import { usePageTitle } from '../PageTitleContext'
import {
    Button,
    TextField,
    TextareaAutosize,
    FormControl,
    InputLabel,
    MenuItem,
    RadioGroup,
    Radio,
    ListItemIcon,
    Checkbox,
    ListItemText,
    FormLabel,
    FormControlLabel,
} from "@mui/material";
import { BtnLoading } from '../../animations/BtnLoading';
import { debounce } from '../../utils/useWindowDimensions';


const emptyFile = {
    url : null,
    embedLink : null
}
function UploadAssets() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    const locationData = location.state || {};
    const moduleUUID = params?.uuid;
    const configData = useSelector((state) => state.configSlice);
    const userData = useSelector((state) => state.userSlice);

    const [error, setError] = useState(false);
    const [comment, setComment] = useState("");
    const [loading, setLoading] = useState(false);
    const [uploadFile, setUploadFile] = useState(null);
    const [moduleName, setModuleName] = useState("");
    const [versionList, setVersionList] = useState([]);
    const [uploadError, setUploadError] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadedFile, setUploadedFile] = useState(emptyFile);
    const [selectedAssetType, setSelectedAssetType] = useState("ab");
    const [selectedPlatform, setSelectedPlatform] = useState("standalonevr");

    const { setPageTitle } = usePageTitle();

    useEffect(() => {
        setModuleName(locationData?.name)
    }, [locationData])

    const platforms = [
        {
            "name": "Standalone VR",
            "key": "standalonevr"
        },
        {
            "name": "Mobile",
            "key": "mobile"
        },
        {
            "name": "Desktop VR",
            "key": "desktopvr"
        },
        {
            "name": "Desktop",
            "key": "desktop"
        },
        {
            "name": "WebGL",
            "key": "web"
        },
    ];

    const assetType = [
        {
            "name": "Asset Bundles",
            "key": "ab"
        },
        {
            "name": "EXE File",
            "key": "exe"
        }
    ]

    const fileTypes = ["*"];

    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    useEffect(() => {
        setPageTitle("XR Modules");
    }, []);

    const getModuleVersionList = async () => {

        setVersionList([])
        axios.get(`${configData.config.UNO_URL}/api/resource/asset/list/versions/${selectedPlatform}/${moduleUUID}`, {
            headers: {
                Authorization: `Bearer ${userData?.token}`,
                "Content-Type ": "application/json"
            }
        })
            .then((res) => {
                setVersionList(res?.data?.data);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    useEffect(() => {
        getModuleVersionList();
    }, [selectedPlatform])


    const handleChangeUploadFile = (file) => {
        setError(false);
        setUploadFile(file)
        const formData = new FormData();
        formData.append('file', file);
        axios.post(`${configData.config.UNO_URL}/api/resource/asset/upload/${selectedPlatform}/${moduleUUID}`, formData, {
            headers: {
                Authorization: `Bearer ${userData?.token}`,
                "Content-Type": "multipart/form-data"
            },
            onUploadProgress: (progressEvent) => {
                let progress = (progressEvent.loaded / progressEvent.total) * 100;
                if (progress < 100) {
                    setUploadProgress(Math.floor(progress));
                }
            }
        })
        .then((res) => {
            setUploadProgress(100); 
            setUploadedFile({url : res.data.url , embedLink : res.data.embedLink});
        })
        .catch((err) => {
            setError(true);
            setUploadedFile(emptyFile);
            console.log(err);
        });
    };

    const handleChangePlatform = (file) => {
        setSelectedPlatform(file);
        setUploadedFile(emptyFile);
        setUploadFile(null);

    }

    const handleChangeFileType = (type) => {
        setSelectedAssetType(type);
        setUploadedFile(emptyFile);
        setUploadFile(null);

    }

    const uploadModuleAssetFile = async() => {
        try {
            setUploadError(null);
    
            if (uploadedFile?.url?.length === 0 || comment.trim().length === 0) {
                setUploadError("Upload file and fill comment field.");
                setLoading(false)
                return
            }
    
            let data = {
                    type: selectedAssetType,
                    platform: selectedPlatform,
                    assetLink: uploadedFile.url,
                    embedLink : uploadedFile.embedLink,
                    comment: comment
                }
                await axios.post(`${configData.config.UNO_URL}/api/resource/asset/create/version/${selectedPlatform}/${moduleUUID}`, data, {
                    headers: {
                        Authorization: `Bearer ${userData?.token}`,
                        "Content-Type ": "application/json"
                    }
                });

                window.location.reload();
                setComment("");
                setUploadFile(null)
                getModuleVersionList();

           } catch (error) {
            console.log(error);
            setUploadedFile(emptyFile);
            setLoading(false)
            
        }

    }

    const formatSize = (bytes) => {
        if (bytes >= 1048576) {
            return (bytes / 1048576).toFixed(2) + " MB";
        } else if (bytes >= 1024) {
            return (bytes / 1024).toFixed(2) + " KB";
        } else {
            return bytes + " bytes";
        }
    };

    const debounceHandleSaveReview = debounce(uploadModuleAssetFile, 1500, setLoading);
    return (

        <>
            <div className='forassets' data-aos="fade-left">
                <div className='container-fluid'>

                    <div className='row mt-2'>
                        <div className='col-md-12'>
                            <div className='platforupload'>
                                <div className="popupselect">
                                    <p>Platforms</p>
                                    <div className="radio-inputs">
                                        <FormControl fullWidth className="mb-3 d-flex align-items-start" >
                                            <RadioGroup
                                                row
                                                aria-labelledby="price-visibility-label"
                                                name="questionType"
                                                className="gap-3"
                                                value={selectedPlatform}
                                                onChange={(e) => handleChangePlatform(e.target.value)}
                                            >
                                                {platforms.map((platform, index) => {
                                                    return (
                                                        <FormControlLabel
                                                            key={index}
                                                            className="quizlabel"
                                                            value={platform.key}
                                                            control={<Radio />}
                                                            label={platform.name}
                                                        />
                                                    )
                                                })}
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-6 mt-2'>
                            <div className="popupselect fortype">
                                <p>Select File Type</p>
                                <div className="radio-inputs">
                                    <FormControl fullWidth className="mb-3 d-flex align-items-start" >
                                        <RadioGroup
                                            row
                                            aria-labelledby="price-visibility-label"
                                            name="questionType"
                                            className="gap-3"
                                            value={selectedAssetType}
                                            onChange={(e) => handleChangeFileType(e.target.value)}
                                        >

                                            {assetType.map((element, index) => {
                                                return (
                                                    <FormControlLabel
                                                        key={index}
                                                        className="quizlabel"
                                                        value={element.key}
                                                        control={<Radio />}
                                                        label={element.name}
                                                    />
                                                )
                                            })}

                                        </RadioGroup>
                                    </FormControl>
                                </div>
                            </div>

                            <div className="DragDropMenu uploadfilesss mt-3">
                                <div className='fileuploader'>
                                    <FileUploader
                                        handleChange={handleChangeUploadFile}
                                        label={uploadFile?.name ? "drag & drop or choose file to upload" : ""}
                                        name="file"
                                        // types={fileTypes}
                                    >
                                        <span className='upload-box-info'>    drag & drop or choose {`${fileTypes}`} file to upload </span>
                                    </FileUploader>
                                </div>
                            </div>

                            {uploadFile ?
                                <div className='uploadstatus'>
                                    <div className="uploadstatus">
                                        {!error &&
                                            <div className="upperdiv">
                                                <div className="uploaddata">
                                                    <div className="xr">
                                                        <p>{uploadFile.name}</p>
                                                        <p
                                                            style={{ cursor: "pointer", lineHeight: '1' }}
                                                            onClick={() => { setUploadFile(null); setUploadProgress(0) }}
                                                        >
                                                            <i className="bi bi-x-circle-fill"></i>
                                                        </p>
                                                    </div>
                                                    <div className=" xr mb-1">
                                                        <p className="filesize">{formatSize(uploadFile.size)} </p>
                                                        <p className="filesize">{uploadProgress}%</p>
                                                    </div>
                                                    <Progress completed={uploadProgress} />
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    {error &&
                                        <div className="uploadProgressBar">
                                            <p className='basicDetailError'> Error in file upload. try again </p>
                                        </div>
                                    }

                                </div>
                                :
                                null
                            }

                            <div className="mb-3 mt-2 VComment">
                                <label htmlFor="exampleFormControlTextarea1" className="form-label">Comment</label>
                                <textarea value={comment} onChange={(e) => setComment(e.target.value)} className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                                <p className='uploadAssetsError'>{uploadError}</p>
                            </div>

                            <div className="deploybtn" >
                                <button onClick={debounceHandleSaveReview} className="button">
                                    {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"></path>
                                    </svg> */}
                                    {
                                        loading ?
                                            <> <BtnLoading /> </>
                                            :
                                            <> Upload </>

                                    }

                                </button>
                            </div>
                        </div>

                        <div className='col-md-6 mt-3'>
                            {versionList.length > 0 ?
                                <div className='cardstyle'>

                                    <div className="deploybtn d-flex justify-content-between">
                                        <div>
                                            <div className='popupselect fortype'>
                                                <p className='mb-0'> {versionList[0]?.createdAt ? moment(versionList[0]?.createdAt).fromNow() : null} </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='filedetails'>
                                        <p>Version :<span>{versionList[0]?.version}</span></p>
                                        <p>Asset :<span><a href={versionList[0]?.data?.assetLink} download>{versionList[0]?.data?.assetLink?.split("/")[versionList[0]?.data?.assetLink?.split("/")?.length - 1]}</a></span></p>
                                        <p>Uploaded By :<span>{versionList[0]?.activities[0]?.users[0]?.name}</span></p>
                                        <p>Timestamp :<span>{versionList[0]?.createdAt ? moment(versionList[0]?.createdAt).format('MMMM Do YYYY, h:mm a') : null}</span></p>
                                        <span className='versionlistv'>{versionList[0]?.data?.comment}</span>
                                    </div>
                                </div>
                                : null}
                        </div>
                    </div>

                    <div className='ReleaseHead'>
                        {versionList.length > 0 ? <h1>Releases</h1> : null}
                    </div>

                    {versionList?.map((list, index) => {
                        return <Releases list={list} key={index + 1} />
                    })}

                </div>
            </div>

        </>
    )
}

export default UploadAssets