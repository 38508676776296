
import React, { useEffect, useState } from "react";
import Slider from '@mui/material/Slider';
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import axios from "axios";
import RangeChart from "./RangeChart";
import { BtnLoading } from "../../animations/BtnLoading";
import { debounce } from "../../utils/useWindowDimensions";

const initialRanges = [
    {
        key: "Time",
        value: 0,
        id: "time",
        rangeid: "time-range",
        thumbid: "time-thumb"
    },
    {
        key: "Critical",
        value: 0,
        id: "crtical-steps",
        rangeid: "crtical-range",
        thumbid: "crtical-thumb"
    },
    {
        key: "Errors",
        value: 0,
        id: "errors",
        rangeid: "errors-range",
        thumbid: "errors-thumb"
    },
    {
        key: "Quiz",
        value: 0,
        id: "quiz",
        rangeid: "quiz-range",
        thumbid: "quiz-thumb"
    }
];


const PassingCriteria = () => {
    const params = useParams();
    const moduleUUID = params?.uuid;
    const configData = useSelector((state) => state.configSlice);
    const userData = useSelector((state) => state.userSlice);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [moduleName, setModuleName] = useState(null);
    const [minimumSuccess, setMinimumSuccess] = useState("");
    const [min_score_to_pass, setMin_score_to_pass] = useState(33)

    const [ranges, setRanges] = useState(initialRanges);

    const handleChange = (index, newValue) => {

        const newValueFraction = newValue / 100;

        const currentTotal = ranges.reduce((total, range, i) => {
            return i === index ? total : total + range.value;
        }, 0);

        if (currentTotal + newValueFraction > 1) return;
    
        const newRanges = ranges.map((range, i) => (
            i === index ? { ...range, value: newValueFraction } : range
        ));
        
        setRanges(newRanges);
    };

    const handleSubmit = async () => {
        try {
        setError(false);
        let _bodyData = {
            passingCriteria: {
                "quiz": ranges.find(e => e.id === "quiz").value, 
                "time":  ranges.find(e => e.id == "time").value, 
                "errors":  ranges.find(e => e.id == "errors").value, 
                "critical":  ranges.find(e => e.id == "crtical-steps").value, 
                "minimumSuccess": minimumSuccess, 
                "min_score_to_pass": min_score_to_pass+""
               }

            }
        await axios.post(`${configData.config.UNO_URL}/api/resource/module/tasks/${moduleUUID}`, _bodyData, {
            headers: {
                Authorization: `Bearer ${userData?.token}`,
                "Content-Type ": "application/json",
            }})

        getModulePassingCriteria();

    } catch (error) {
        console.log(error);
    } finally{
        setLoading(false)
    }
    }

    const handleSaveRanges = (_passingCriteriaData) =>{
        let _initialRanges = initialRanges.map(range => {
            let updatedValue = range.value;
            switch (range.id) {
                case "time":
                    updatedValue = _passingCriteriaData.time || range.value;
                    break;
                case "crtical-steps":
                    updatedValue = _passingCriteriaData.critical || range.value;
                    break;
                case "errors":
                    updatedValue = _passingCriteriaData.errors || range.value;
                    break;
                case "quiz":
                    updatedValue = _passingCriteriaData.quiz || range.value;
                    break;
                default:
                    break;
            }
            return { ...range, value: updatedValue };
        });

        setMin_score_to_pass(_passingCriteriaData.min_score_to_pass);
        setMinimumSuccess(_passingCriteriaData.minimumSuccess);


        setRanges(_initialRanges);
    }

    const getModulePassingCriteria = async () => {
        let {data} = await axios.get(`${configData.config.UNO_URL}/api/resource/module/tasks/${moduleUUID}`, {
            headers: {
                Authorization: `Bearer ${userData?.token}`,
                "Content-Type ": "application/json",
            }
        });
        
        setModuleName(data?.data?.name);
        let _passingCriteriaData = data?.data?.children[0]?.versions[0]?.data?.passingCriteria || [];
        handleSaveRanges(_passingCriteriaData);
    };

    useEffect(() => {
        if (moduleUUID.length > 0) {
            getModulePassingCriteria();
        }
    }, [])


    const debounceHandleSaveReview = debounce(handleSubmit, 1000, setLoading);

    const handleClickCancel = () =>{
            setRanges(initialRanges);
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="mb-0">

                            <p style={{ background: '#EDE58050', padding: '10px', fontSize: '14px', borderRadius: '10px', color: '#000', }}>
                                Customize the passing criteria for assigned courses based on your organization's unique requirements. Modify the following factors:<br />
                                <strong>Criteria Type:</strong> Choose the combination of factors for evaluation.<br />
                                <strong>Time Deviation:</strong> Set an acceptable time variation from the ideal completion time (e.g., 20% allows 80%-120% of ideal time).<br />
                                <strong>Step Deviation:</strong> Define the maximum acceptable error percentage (e.g., 20% means a maximum of 20% incorrect steps are allowed).<br />
                                <strong>Minimum Passes:</strong> Set the minimum number of times the user has to pass the course before achieving the certificate (e.g., 2 times).<br />
                                <br />
                                Please note that some modules have <strong>"Critical Steps."</strong> Users must complete all critical steps for successful evaluation, even if they meet the Step Deviation criterion.
                                Customize now to align course evaluations with your organization's specific goals and learning objectives.<br />
                            </p>


                        </div>
                    </div>

                </div>

                <div className="row">
                    <div className="col-md-12">
                        <div className="questype mt-3">
                            Type of Deviation
                        </div>
                        <div className="questype mt-3">
                            Resource: <b>{moduleName}</b>
                        </div>
                    </div>
                </div>
                <div className="row">
                        <div className="col-md-4">
                            <div className="quesformbox mt-3">
                                <p>Minimum Passes</p>
                                <input name="minimumSuccess" value={minimumSuccess} onChange={(e)=> setMinimumSuccess(e.target.value)} placeholder="Minimum Passes" type="text" className="input mt-2" ></input>
                            </div>
                        </div>
                </div>

                <div className="row">
                    <div className="col-md-6 d-flex align-items-start justify-content-start flex-column ">
                        {ranges.map((range, index) => (
                            <div key={index} className="range-here">
                                <div>
                                    <span>{range.key} Weightage</span>
                                    <span className="for-percentage">
                                        <input
                                            type="number"
                                            id={range.id}
                                            name="inputBar"
                                            step="1"
                                            value={range.value * 100}
                                            onChange={(e) => handleChange(index, parseInt(e.target.value))}
                                        />
                                        <span className="percentage-view">%</span>
                                    </span>
                                </div>
                                <div className="exact-slider">
                                    <Slider
                                        value={parseInt((range.value) * 100)}
                                        aria-label="Default"
                                        valueLabelDisplay="auto"
                                        onChange={(event, newValue) => handleChange(index, parseInt(newValue))}
                                    />
                                </div>
                            </div>
                        ))}

                        <div className="range-here">
                            <div>
                                <span> Enter overall passing percentage</span>
                                <span className="for-percentage">
                                    <input name="min_score_to_pass" value={min_score_to_pass} onChange={(e)=> setMin_score_to_pass(e.target.value)} placeholder="Minimum Passes" type="text" className="input mt-2"/>
                                    <span className="percentage-view">%</span>
                                </span>
                            </div>

                        </div>
                    </div>
                    <div className="col-md-6 d-flex align-items-center justify-content-start " >
                        <RangeChart values={ranges} />
                    </div>
                </div>

                <div className="row mt-5">
                    <div className="col-12">
                        <div className="footerbtn text-end">
                            {error && <p className="basicDetailError" >kindly fill all field.</p>}
                        </div>
                        <div className="footerbtn text-end">
                            <button type="button" className="btn btn-secondary" onClick={handleClickCancel} >
                                Cancel
                            </button>

                            {loading ?
                            <button type="button" className="btn btn-primary">
                                <BtnLoading /> 
                              </button>
                             :
                            <button type="button" onClick={debounceHandleSaveReview} className="btn btn-primary">
                                Save
                            </button>
                            }

                        </div>
                    </div>
                </div>


            </div>
        </>
    )
};

export default PassingCriteria;

