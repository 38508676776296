import React, { useRef, useState, useEffect } from "react";
import AOS from "aos";
import { FileUploader } from "react-drag-drop-files";
import { FileIcon, LogHis, UploadStat, AddImageIcon } from "../../Static/icons/iconshere";
import "aos/dist/aos.css";
import Progress from "react-progressbar";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useSelector , useDispatch } from "react-redux";
import { usePageTitle } from '../PageTitleContext'
import { BtnLoading } from "../../animations/BtnLoading";
import { debounce } from "../../utils/useWindowDimensions";

const Soppage = ({ apiRoute = "module", upload="upload" }) => {
    const fileInputRef = useRef(null);
    const params = useParams();
    const dispatch = useDispatch();
    const moduleUUID = params?.uuid;
    const navigate = useNavigate();
    const location = useLocation();
    const locationData = location.state || {};
    const configData = useSelector((state) => state.configSlice);
    const userData = useSelector((state) => state.userSlice);
    const [loading, setLoading] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState(null);
    const [sopList, setSopList] = useState([]);
    const [error, setError] = useState(null);
    const [moduleName, setModuleName] = useState("");
    const { setPageTitle } = usePageTitle();
    const [sopTitle, setSopTitle] = useState("");

    useEffect(() => {
        setPageTitle("XR Modules");
      }, []);

    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    useEffect(()=>{
        setModuleName(locationData?.name || "");
    },[locationData])

    const formatSize = (bytes) => {
        if (bytes >= 1048576) {
            return (bytes / 1048576).toFixed(2) + " MB";
        } else if (bytes >= 1024) {
            return (bytes / 1024).toFixed(2) + " KB";
        } else {
            return bytes + " bytes";
        }
    };

    const saveModuleSop = async (values) => {
            let data = {
                docs: values
            }
            await axios.post(`${configData.config.UNO_URL}/api/resource/${apiRoute}/sop/${moduleUUID}`, data, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`,
                    "Content-Type": "application/json"
                }
            }).then((response) => {
                setLoading(false)
                getSopList();
                setUploadedFiles(null);
                setSopTitle("");
            }).catch((err) => {
                console.log(err)
            })


    };

    const getSopList = async () => {
        try {
            let {data} = await axios.get(`${configData.config.UNO_URL}/api/resource/${apiRoute}/sop/${moduleUUID}`, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`,
                    "Content-Type": "application/json"
                }
            });
            const sopData = data?.data?.children[0]?.versions[0]?.data?.docs || []
            setSopList([...sopData]);
            
        } catch (error) {
            console.log(error);
        }

    };

    useEffect(() => {
        getSopList()
    }, [])


    const SopListDelete = async (index, sopId) => {
        try {
            alert("Are you sure want to delete this SOP")
            const updatedSopList = [...sopList];
            updatedSopList.splice(index, 1);
            saveModuleSop(updatedSopList);
        } catch (error) {
            console.error('Error deleting SOP:', error);
        }
    };

    const toggleSopVisibility = (index) => {
        const updatedSopList = [...sopList];
        updatedSopList[index].visible = !updatedSopList[index].visible;
        saveModuleSop(updatedSopList);
    };

    const debounceHandleSave = debounce(() => {
        if(uploadedFiles?.path && sopTitle.trim()){
            let newSop = {
                name : sopTitle.trim() ,
                path : uploadedFiles.path,
                visible:true
            }
            saveModuleSop([...sopList, newSop ]);
        }else{
            setLoading(false);
            setError("kindly upload file and set title.")
            setTimeout(() => {
                 setError(null)    
             }, 2500);
        }
       }, 500, setLoading);

    const handleFileUpload = async(event) =>{
        try {
            let file = event.target.files[0];
            const formData = new FormData();
                formData.append('file', file);
                setUploadedFiles({
                    file,
                    processing: true,
                    path:null
                    });
                    const { data } = await axios.post(`${configData.config.UNO_URL}/api/resource/${upload}/sop/${moduleUUID}`, formData, {
                        headers: {
                            Authorization: `Bearer ${userData?.token}`,
                            "Content-Type ": "multipart/form-data"
                        }
                    });
                    setUploadedFiles({
                        file,
                        processing: false,
                        path:data.url
                        });
            
        } catch (error) {
            setUploadedFiles(null);
            setError("Something went wrong.");
            setTimeout(() => {
                setError(null);
            }, 5000);
        } finally{
            fileInputRef.current.value = '';
        }

    }

    const deleteUploadedSop = () =>{
        setUploadedFiles(null);
        setError(null);
        fileInputRef.current.value = '';
    }
    const handleCancelClick = () =>{
        setUploadedFiles(null);
        setSopTitle("");
        setError(null);
        fileInputRef.current.value = '';
    }

    return (
        <div className="container-fluid mt-2" data-aos="fade-left">
            <div className="headdetail">
                {/* <p className="backcrum" onClick={() => navigate(-1)}>
                    <i className="bi-chevron-left"></i> Back
                </p> */}
                <span className="pagetitle">
                    {moduleName}
                </span>
            </div>
            <div className="row">
                <div className="col-md-6 forsop">
                    <div className="row">
                    <div className="col-md-12">
                    <p className="titlehere">Add SOPs</p>
                    <div className="DragDropMenu mt-3">
                        <label htmlFor="fileInput" className="custom-file-upload">
                            <input
                                type="file"
                                ref={fileInputRef}
                                id="fileInput"
                                onChange={handleFileUpload}
                            />
                            <div>
                                <AddImageIcon /><br />
                                <span>drag & drop or choose PDF file to upload <br /></span>
                            </div>
                        </label>
                    
                    </div>
        
                    <div className="uploadstatus">
                        {uploadedFiles &&
                            <div className="upperdiv">
                                <div className="uploaddata">
                                    <div className="xr">
                                        <p>{uploadedFiles.file.name}</p>
                                        <p style={{ cursor: "pointer", lineHeight: '1' }} onClick={deleteUploadedSop} >
                                            <i className="bi bi-x-circle-fill"></i>
                                        </p>
                                    </div>
                                    <div className=" xr mb-1">
                                        <p className="filesize">{formatSize(uploadedFiles.file.size)} </p>
                                        <p className="filesize">{uploadedFiles.processing ? 85 : 100} %</p>
                                    </div>
                                    <div className=" xr mb-1">
                                        <p className="filesize">{uploadedFiles.processing ? "uploading...." : "completed"}</p>
                                    </div>
                                    <Progress completed={uploadedFiles.processing ? 85 : 100} />
                                </div>
                            </div>
                        }
                    </div>

                    </div>
                    <div className="col-md-12">

                    <div className="col-md-12 quesformbox mt-2"> 
                    <p>SOP Title</p>
                    <input value={sopTitle} onChange={(e) => setSopTitle(e.target.value)} type="text"  placeholder="SOP Title" className="input mt-2" />
                    </div>
                    {error && <h6 className="error">{error}</h6> }
                  
                    <div className="footerbtn text-end mt-4">
                    <button type="button" className="btn btn-secondary" onClick={handleCancelClick} > Cancel </button>
                    <button type="button" className="btn btn-primary" onClick={debounceHandleSave} > 
                           { loading ?  <BtnLoading /> : "Save" } </button>
                  </div>
                       

                    </div>
                    </div>
                </div>

                <div className="col-md-6 px-5">
                    <p className="titlehere">SOPs list</p>
                    <div className="splist">

                        {sopList.length > 0 && sopList?.map((sop, index) => (
                            <div key={index} className="soplisthere">
                                <div className="sopdiv">
                                    <div className="sopimg">
                                        <FileIcon />
                                    </div>
                                    <div className="sopdata">
                                    <p>{sop?.name}</p>
                                        <a className="downloadsop" href={sop?.path} target="_blank" download={true}>
                                            Download SOP
                                        </a>
                                        <div className="visibletoggle">
                                            Visible:
                                            <div className="statustoggle">
                                                <div className="checkbox-apple">
                                                    <input
                                                        className="yep"
                                                        id={`check-apple-${index}`} // Make each checkbox have a unique ID
                                                        type="checkbox"
                                                        checked={sop.visible}
                                                        onChange={() => toggleSopVisibility(index)}
                                                    />
                                                    <label htmlFor={`check-apple-${index}`}></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <span className="deletesopbtn" onClick={() => SopListDelete(index)}><i className="bi bi-x-circle-fill"></i></span>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            </div>
        </div>
    );
}

export default Soppage;