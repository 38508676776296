import React, { useEffect, useState } from 'react'
import { Droppable, DragDropContext } from "react-beautiful-dnd";
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { SectionList } from "./SectionList.js";
import { CreateSectionModal } from '../Modal/CreateSectionModal.js';
import axios from 'axios';

function SectionManager({ resource }) {
  const params = useParams();
  const id = params?.uuid;
  const configData = useSelector((state) => state.configSlice);
  const userData = useSelector((state) => state.userSlice);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hierarchy, setHierarchy] = useState([]);
  const [showCreateModal, setShowCreateModal] = useState({
    name: "",
    type: null,
    status: null,
    resourceId: null,
    action: null,
    modal: null
  });


  const saveHierarchy = async (updatedHierarchy) => {
    try {
      var resources = [];
      for(var i in updatedHierarchy){
        resources.push({
          uuid : updatedHierarchy[i].id,
          priority : parseInt(i) + 1,
          parentId : id          
        });

        for (var j in updatedHierarchy[i].children){
          resources.push({
            uuid : updatedHierarchy[i].children[j].id,
            priority : parseInt(j) + 1,
            parentId : updatedHierarchy[i].id          
          });
        }
      }

      await axios.post(`${configData.config.UNO_URL}/api/resource/2d/lms/course/structure/organize`, {resources},{
          headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userData.token}`
          }
      });

   } catch (error) {
      console.log(error);
   }
  }

  const onDragEnd = (res) => {
    const { type, draggableId, destination } = res;
    if (!destination) return;
    const newIndex = destination.index;
    var updatedHierarchy = [...hierarchy];
    if (type === 'section') {
      var previousSectionIndex = updatedHierarchy.findIndex(item => item.children.findIndex((child) => child.id === draggableId) > -1);
      var previousIndex = updatedHierarchy[previousSectionIndex].children.findIndex(item => item.id === draggableId);
      var item = updatedHierarchy[previousSectionIndex].children[previousIndex];
      updatedHierarchy[previousSectionIndex].children.splice(previousIndex, 1);
      var newSectionIndex = updatedHierarchy.findIndex(item => item.id === destination.droppableId);
      updatedHierarchy[newSectionIndex].children.splice(newIndex, 0, item);
    } else if (type === 'sectionList') {
      var previousIndex = updatedHierarchy.findIndex(item => item.id === draggableId);
      var item = updatedHierarchy[previousIndex];
      updatedHierarchy.splice(previousIndex, 1);
      updatedHierarchy.splice(newIndex, 0, item);
    }
    setHierarchy(updatedHierarchy);
    saveHierarchy(updatedHierarchy);
    refresh();
  }

  const handleOpenCreateModal = (id, name, action, modal) => {
    setShowCreateModal({
      type: "section",
      status: true,
      resourceId: id,
      name: name,
      action: action,
      modal: modal
    });
  }

  const refresh = async () => {
    setLoading(true);
    setTimeout(()=> setLoading(false) , 100)
  }

  const handleCloseModal = () => {
    setShowCreateModal({
      name: "",
      type: null,
      status: null,
      resourceId: null,
      action: null,
      modal: null
    })
  };

  useEffect(() => {
    let _list = list.map((item, index) => {
      let child = item.children.map((e, i) => {
        return (
          {
            name: e.name,
            id: e.uuid,
            uuid: e.uuid,
            type: "section",
            active: e.active,
            contentType: e.versions[0].data.contentType,
            courseDuration: e.versions[0].data.courseDuration,
            lastAsset: e.versions[0].data.lastAsset
          }
        )
      });

      return (
        {
          name: item.name,
          id: item.uuid,
          uuid: item.uuid,
          type: "sectionList",
          active: item.active,
          children: child
        }
      )
    });

    setHierarchy(_list);
    refresh();
  }, [list]);

  const getSectionList = async () => {
    try {
      const { data } = await axios.get(`${configData.config.UNO_URL}/api/resource/2d/lms/section/list/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData.token}`
        }
      });

      setList(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(()=>{
    id && getSectionList();
  },[id]);

  const updateActiveStatus = (data, id, value) => {
    return data.map((section) => {
      if (section.id === id) {
        return { ...section, active: value };
      } else if (section.children) {
        return { ...section, children: updateActiveStatus(section.children, id, value) };
      } else {
        return section;
      }
    });
  };

  const changeResourceStatus = async(id, status) =>{
    try {
      await axios.post(`${configData.config.UNO_URL}/api/resource/change/status/${id}`, {status: status}, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData.token}`
        }
      });
      getSectionList();
    } catch (error) {
      console.log(error);
    }
    
  }

  const handleChangeStatus = async(id,status) =>{
     const updatedHierarchy = updateActiveStatus(hierarchy, id, status);
     setHierarchy(updatedHierarchy);
     changeResourceStatus(id,status)
  }

  return (
    <>

      <div className="allcompages">
        <div className="mainbox">

          <div className='head-detail'>
            <div className='info-add'>
              <div className='name-thumb'>
                <img src={resource.thumbnail} />
                <div className="data">
                  <h5>{resource.name}</h5>
                  <p> Course Structure </p>
                </div>
              </div>
              <button className="createbtn" onClick={() => handleOpenCreateModal(id, "", "create", "Add")} >Add Section</button>
            </div>
            <p className='sugg'>Drag and Drop any Section / Sub-Section to re-organize your course!</p>
          </div>
          {loading ? null : <div style={{userSelect:"none"}}>
            {hierarchy.length > 0 ?
              <DragDropContext onDragEnd={onDragEnd}>
                <div>
                  <Droppable droppableId={id} type="sectionList">
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        style={{ backgroundColor: snapshot.isDraggingOver ? "rgba(202, 55, 60, 0.2)" : 'white' }}
                      >
                        {hierarchy.map((sectionList, index) => (
                          <SectionList
                            isDragginOver={snapshot.isDraggingOver}
                            key={`${sectionList.id}`}
                            index={index}
                            getSectionList={getSectionList}
                            handleChangeStatus={handleChangeStatus}
                            {...sectionList}
                          />
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
              </DragDropContext>
              :
              <div className='empty-data'>
                <p>No Sections Yet, Click below to add a new Section</p>
                <button className="createbtn" onClick={() => handleOpenCreateModal(id, "", "create", "Add")} >Add Section</button>
              </div>

            }
          </div>}
        </div>
      </div>

      <CreateSectionModal showCreateModal={showCreateModal} handleCloseModal={handleCloseModal} getSectionList={getSectionList} />
    </>
  )
}

export default SectionManager