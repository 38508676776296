import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { TextField } from "@mui/material";
import Select from 'react-select';
import { CommentsDisabledRounded } from '@mui/icons-material';

const AddTask = ({ showAddTask, handleCloseAddTask, addNewTaskToList, locked, scenarioList }) => {

    const [error,setError] = useState(false);
    const [task, setTask] = useState({
        title : "",
        plannedTime : "",
        priority : "",
        taskDesc : "",
        isCritical : false,
        scenario: null,
        id : null
    });

    let options = scenarioList.map(item => ({
        value: item.id,
        label: item.name
      }));;

    const handleChangeTaskValue = (e) =>{
            const name = e.target.name ;
            const value = e.target.name == "isCritical" ? !task.isCritical : e.target.value;
            setTask({
                ...task,
                [name] : e.target.name == "isCritical" ? value : value.startsWith("0") ? value.slice(1) : value
            });
       }

    const handleClose = () =>{
        setTask({
            id : null,
            title : "",
            plannedTime : "",
            priority : "",
            taskDesc : "",
            isCritical : false,
            scenario: null,
        });
        handleCloseAddTask();
    }

    const createTask = () =>{

        // if(task.title && task.plannedTime && task.priority && task.taskDesc && (options.length > 0 ? task.scenario : true) ){
        if(task.title && task.plannedTime && task.priority && task.taskDesc ){

            if (locked && task.id === null) {
                setError(true);
                setTimeout(() => setError(false), 2500);
                return;
            }

            addNewTaskToList(task);
            setTask({
                id : null,
                title : "",
                plannedTime : "",
                priority : "",
                taskDesc : "",
                isCritical : false,
                scenario: null,
            });


          }else{
            setError(true);
            setTimeout(() => { setError(false) } , 2500);
          }
    }

    const handleScenarioChange = (selectedOption) => {
        setTask({
            ...task,
            scenario : selectedOption.value
        });
      };


    return (
        <>
            <Modal show={showAddTask} size='md' centered onHide={handleClose}>
                <Modal.Body className='platformhead quizmodal p-4'>
                    <form>
                        <div className='row quizmodalhead'>
                            <div className='col-md-12 text-center'>
                                <h5 className='headquiz'>
                                    Create Task
                                </h5>
                            </div>
                        </div>

                        <div className='pt-1'>
                            <div className="quesformbox mt-3">
                            {locked &&
                                <div className="form-group mb-3">
                                    <label htmlFor="">Task Id</label>
                                    <TextField
                                        placeholder="Task Id"
                                        fullWidth
                                        name="id"
                                        size='small'
                                        variant="outlined"
                                        type='number'
                                        value={task.id}
                                        onChange={handleChangeTaskValue}
                                    />
                                </div>
                                }

                                <div className="form-group mb-3">
                                    <label htmlFor="">Title</label>
                                    <TextField
                                        placeholder="Title"
                                        fullWidth
                                        name="title"
                                        size='small'
                                        type={'text'}
                                        variant="outlined"
                                        value={task.title}
                                        onChange={handleChangeTaskValue}
                                    />
                                </div>

                                <div className="form-group mb-3">
                                    <label htmlFor="">Time</label>
                                    <TextField
                                        placeholder="Time"
                                        fullWidth
                                        name="plannedTime"
                                        size='small'
                                        type='number'
                                        variant="outlined"
                                        value={task.plannedTime}
                                        onChange={handleChangeTaskValue}
                                    />
                                </div>

                                <div className="form-group mb-3">
                                    <label htmlFor="">Priority</label>
                                    <TextField
                                        placeholder="Priority"
                                        fullWidth
                                        name="priority"
                                        size='small'
                                        type={'number'}
                                        variant="outlined"
                                        value={task.priority}
                                        onChange={handleChangeTaskValue}
                                    />
                                </div>

                                <div className="form-group mb-3">
                                    <label htmlFor="">Scenario</label>
                                    <Select
                                        options={options}
                                        onChange={handleScenarioChange}
                                        isSearchable={true}
                                        value={options.find(option => option.value === task.scenario)}
                                    />
                                </div>

                                <div className="form-group mb-3">
                                    <label htmlFor="">Description</label>
                                    <TextField
                                        placeholder="Description"
                                        fullWidth
                                        size='small'
                                        type={'text'}
                                        variant="outlined"
                                        name="taskDesc"
                                        rows={3}
                                        multiline
                                        value={task.taskDesc}
                                        onChange={handleChangeTaskValue}
                                    />
                                </div>

                            </div>
                        </div>

                        <div className='row align-items-center'>
                            <div className='col-md-6'>
                                <label className="containercheck">
                                <input
                                    type="checkbox"
                                    name="isCritical"
                                    checked={task.isCritical}
                                    onChange={handleChangeTaskValue}
                                />
                                <div className="checkmark"></div>
                                <p>Critical</p>
                                </label>
                            </div>
                            <div className='col-md-6'>

                                <div className='text-end footerbtn text-end'>
                                     {error && <p className="basicDetailError"> Kindly fill all field </p>}
                                </div>
                                <div className='text-end footerbtn text-end'>
                                    <Button variant="secondary" onClick={handleClose}>
                                        Cancel
                                    </Button>
                                    <Button onClick={createTask}>
                                        Create
                                    </Button>
                                </div>

                            </div>
                        </div>

                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AddTask
