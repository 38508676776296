const {  createSlice} = require("@reduxjs/toolkit");

const initialState={
    user:{},
    token:""
}

const userSlice  = createSlice({
  name:"userSlice",
  initialState,
  reducers:{
      saveUserData:(state,action)=>{
        state.user = action.payload.user;
        state.token = action.payload.token;
      },
      removeUser:(state)=>{
        state.user = {};
        state.token = "";
      }
  },

});

export const { saveUserData, removeUser } = userSlice.actions;
export default userSlice.reducer;