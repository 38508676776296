import React, { useEffect, useState } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';


const ENVTabs = (props) => {

   const navigate = useNavigate();
   const params = useParams();
   const [moduleName, setModuleName] = useState("");
   const UUID = params?.uuid;
   const location = useLocation();
   const locationData = location.state || {};
   
   const Child = props.ChildComponent;
   const { tabName } = props;

   const handleTabSelect = (selectedTab) => {
      if(selectedTab == "back"){
         navigate("/dashboard/environments")
      }else{
         navigate(`/dashboard/environments/${selectedTab}/${UUID}`, { state: { name: moduleName, type: "ENVIRONMENT" } })
      }
   };

   useEffect(() => {
      setModuleName(locationData?.name || "")
   }, [locationData])

   return (
      <>
         <div className='filterhere'>

            <Tabs
               activeKey={tabName}
               onSelect={handleTabSelect}
            >
               <Tab title={<span> <i className="bi-chevron-left"></i> Back </span>} eventKey="back" >
               </Tab>
               <Tab eventKey="details" title="Basic Details">
               </Tab>
               <Tab eventKey="content" title="Dynamic Content">
               </Tab>
               <Tab eventKey="assets" title="Upload Assets">
               </Tab>
               <Tab eventKey="channels" title="Publish">
               </Tab>
            </Tabs>
         </div>

         <div className='allcompages'>
            <Child {...props}></Child>
         </div>

      </>
   )
}
export default ENVTabs;