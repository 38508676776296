import React from 'react'
import moment from "moment"
import { useNavigate, } from 'react-router-dom';

const Releases = (props) => {
    const navigate = useNavigate();
    const assetLink = props?.list?.data?.assetLink;
    const parts = assetLink?.split("/");
    const filename = parts ? parts[parts?.length - 1] : " ";

    return (
        <>
            <div className='releasebody my-4'>
                <div className='row '>
                    <div className='col-md-2'>
                        <div className='releasedata'>
                            <p> {moment(props?.list?.createdAt).fromNow()} </p>
                            <p>
                                <img src={props?.list?.activities[0]?.users[0]?.avatar}></img>
                                {props?.list?.activities[0]?.users[0]?.name}
                            </p>
                            <p className='d-flex'>
                                <i className="bi bi-file-earmark-check"></i>  {props?.list?.version}
                            </p>
                        </div>
                    </div>
                    <div className='col-md-10'>
                        <div className='releasebox'>
                            <div className='releaseinfo border-0'>
                              
                                <div className='d-flex py-3 pb-2 justify-content-between pb-4'>
                                    <div>
                                        <h3 className='mb-0'> Version: {props?.list?.version} </h3>
                                        <p className='timeversion mb-0'> {moment(props?.list?.createdAt).fromNow()} </p>
                                    </div>
                                    <div className='d-flex justify-content-between  text-end'>
                                        <a href={props?.list?.data?.assetLink} download> {filename}  </a>
                                    </div>
                                </div>
                             
                                <div className='releasecmnt'>
                                    <p>{props?.list?.data?.comment}</p>
                                </div>
                            </div>
                            

                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Releases