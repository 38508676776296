const { createSlice } = require("@reduxjs/toolkit");

const initialState={
    apps:[],
    tagsList:[]
}

const applicationSlice  = createSlice({
  name:"applicationSlice",
  initialState,
  reducers:{
    myApplicationList:(state, action)=>{
        state.apps = action.payload;
        },
    myTagsList:(state, action)=>{
        state.tagsList = action.payload;
          }

        }
});

export const { myApplicationList , myTagsList } = applicationSlice.actions;
export default applicationSlice.reducer;