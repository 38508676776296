import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { CloseIcon } from '../../../Static/icons/iconshere';
import { debounce } from '../../../utils/useWindowDimensions';
import { BtnLoading } from '../../../animations/BtnLoading';

const Create2DLmsModal = ({showModal, setShowModal, get2DLmsModuleList}) => {
const [moduleName , setModuleName] = useState("");
const [error, setError] = useState(null);
const [loading, setLoading] = useState(false);
const configData = useSelector((state) => state.configSlice);
const userData = useSelector((state) => state.userSlice);

const createModule = async() =>{ 
   try {
       setError(null);
       
       if(moduleName.trim().length == 0){
           setLoading(false);
           setError("Kindly enter name");
           return
      }

       let _bodyData = {
           name : moduleName.trim()
       }

       await axios.post(`${configData.config.UNO_URL}/api/resource/2d/lms/create`, _bodyData, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData.token}`
        }
        });

        get2DLmsModuleList();
        closeModal();
    
      }catch (error) {
       console.log(error);
       setError(error?.response?.data?.message || "something went wrong")
      }finally{
        setLoading(false);
        setTimeout(()=>{
            setError(null);
        },2500)
    }
    
}

const closeModal = () =>{
    setModuleName("");
    setShowModal(false);
    }

  const debounceHandleSaveReview = debounce(createModule, 1000, setLoading);

  return (
    <>
         <Modal show={showModal} onHide={closeModal} className="NewModule" centered size="md">
                <Modal.Header className='modalheader'>
                     Add Course
                     <span onClick={closeModal} ><CloseIcon/></span>
                     </Modal.Header>
                <Modal.Body>
                    <div className='container-fluid'>
                        <div className='row'>

                            <div className='col-12 mb-2'>
                                <div className="form-group">
                                    <label className="label">Course Name</label>
                                    <input autoComplete="off" placeholder='Name' name="moduleName" id="moduleName" value={moduleName} onChange={(e) => setModuleName(e.target.value)} className="input form-control" type="text" />
                                </div>
                                {error && <p className='errorMessage' >{error}</p>}
                            </div>

                        </div>
                    </div>



                </Modal.Body>

                <Modal.Footer className='footerbtn pt-0'>
                        <Button onClick={closeModal}> Cancel </Button>

                        {loading ? 
                          <Button > <BtnLoading /> </Button>
                             : 
                          <Button onClick={debounceHandleSaveReview}> Save </Button>
                        }
                        
                
                </Modal.Footer>

            </Modal>
    
    </>
  )
}


export default Create2DLmsModal;