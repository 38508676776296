export const UNO_URL = 'https://api.simulanis.io';
// export const UNO_URL = 'http://localhost:3003';
// export const UNO_URL = 'https://simulanis-uno-backend-backup-uat.azurewebsites.net';


export const redirectUrl = "https://apps.simulanis.io"
// export const redirectUrl = "http://localhost:3000"
// export const redirectUrl = "https://appsunouat.azureedge.net"

export const defaultLogo = "https://cdn.simulanis.io/sso/logos/default_simulanis.png";
export const applicationId = "19c180d8-cfeb-47e1-bcff-619591affbab";

export const assetFilterId = 70
