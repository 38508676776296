import React from 'react'

export const BtnLoading = () => {
    return (
        <div className='btnload'>
            <div className="loader">
                <span className="bar"></span>
                <span className="bar"></span>
                <span className="bar"></span>
            </div>
        </div>
  )
}
