



import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const RangeChart = ({values}) => {
  // const [chartState, setChartState] = useState();
  const chartState = {
    series: values.map((item) => (item.value*100)), // Decreased series to 4
    options: {
      chart: {
        type: 'donut',
      },
      dataLabels: {
        enabled: true,
        textAnchor: 'middle',
        distributed: false,
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: '14px',
          fontWeight: 'bold',
          colors: undefined
        },
        background: {
          enabled: true,
          foreColor: '#fff', 
          padding: 5,
          borderRadius: 2,
          borderWidth: 1,
          borderColor: '#fff',
          opacity: 0.9,
          dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: '#000',
            opacity: 0.45
          }
        },
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: '#000',
          opacity: 0.45
        }
      },
      colors: ['#5972FF', '#31BACC', '#CA373C', '#333333'],
      labels: ['Time Weightage', 'Error Weightage', 'Critical Weightage', 'Quiz Weightage'],
      legend: {
        fontSize: '16px',
        fontWeight: "500",
        fontFamily: "Poppins', sans-serif",
        padding: "0 5px" 
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    }
  }

  return (
   <>
      <div id="chart" style={{ maxWidth: "550px" }}>
        <ReactApexChart width='550px' height='300px' options={chartState.options} series={chartState.series} type="donut" />
      </div>
      {/* <div id="html-dist"></div> */}
      </>
  );
};

export default RangeChart;
