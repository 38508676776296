import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import jwtEncode from "jwt-encode";
import jwtDecode from "jwt-decode";
import "./App.css";
import "./Static/css/style.css";
import "./Static/css/custom.css";
import { UNO_URL, applicationId, redirectUrl } from "./env";
import { saveConfig } from "./store/slice/configSlice";
import { myApplicationList } from "./store/slice/applicationSlice";
import { removeUser, saveUserData } from "./store/slice/userSlice";
import Header from "./Components/Header/header";
import SideBar from "./Components/SideBar/sidebar";
import Dashboard from "./Components/Dashboard/dashboard";
import UploadAssets from "./Components/UploadAssets/UploadAssets";
import DeployPage from "./Components/DeployPage/deploypage";
import Details from "./Components/Details/details";
import Tasklist from "./Components/TaskList/tasklist";
import SopPage from "./Components/SOP/soppage";
import Videopage from "./Components/video/videoPage";
import QuizPage from "./Components/Quiz/QuizPage";
import Channels from "./Components/Channels/channels";
import ChannelsSelect from "./Components/Channels/channelselect";
import FullScreenLoader from "./Components/FullScreenLoader/fullScreenLoader";
import {
  getAllModuleList,
  getAllSkillStream2DTags,
  getAllXRModuleTags,
  getAssetTagsList,
} from "./Components/utils";
import { PageTitleProvider } from "./Components/PageTitleContext";
import ModuleTabs from "./Components/ModuleTabs";
import PassingCriteria from "./Components/PassingCriteria/PassingCriteria";
import ENVDashboard from "./Components/ENVPages/ENVDashboard";
import ENVTabs from "./Components/ENVPages/ENVTabs";
import ENVDetails from "./Components/ENVPages/ENVDetails";
import ENVAssets from "./Components/ENVPages/ENVAssets";
import ENVContent from "./Components/ENVPages/ENVContent";
import AssetDashboard from "./Components/AssetsPages/AssetsDashboard";
import AssetDetails from "./Components/AssetsPages/AssetDetails";
import AssetsTabs from "./Components/AssetsPages/AssetsTabs";
import AssetsChildDashboard from "./Components/AssetsPages/AssetsChildDashboard";
import LMSDashboard from "./Components/2DLMS/Dashboard";
import LMSDetailsPage from "./Components/2DLMS/LMSDetailsPage.js";
import SubSectionDetails from "./Components/2DLMS/SubSections/SubSectionDetails.js";

const App = () => {
  const dispatch = useDispatch();
  const [darkTheme, setDarkTheme] = useState(true);
  const [sideBar, setSideBar] = useState(false);
  const [defaultRoute, setDefaultRoute] = useState("");
  const [loading, setLoading] = useState(true);
  const configData = useSelector((state) => state.configSlice);
  const userData = useSelector((state) => state.userSlice);


  const getConfigurationData = async () => {
    try {
      const { data } = await axios.get(`${UNO_URL}/api/application/configuration/${applicationId}`);
      const config = { ...data.data.data };
      // config.UNO_URL = "http://localhost:3003";
      // config.SSO_URL = "http://localhost:5000";
      
      dispatch(saveConfig({ data: config }));
    } catch (error) {
          console.log(error);
    }
  };

  useEffect(() => {
    getConfigurationData();
  }, []);

  const fetchApplication = async (token) => {
    try {
      var { data } = await axios.get(
        `${configData?.config?.UNO_URL}/api/application/mine`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(myApplicationList(data.data));
    } catch (error) {
      console.log(error);
    }
  };

  const getUserDetail = async (config) => {
    const urlParams = new URLSearchParams(window.location.search);
    const _tokenParam = urlParams.get("referrer");
    let token = "";
    if (_tokenParam) {
      const decoded = jwtDecode(_tokenParam);
      localStorage.setItem("token", decoded.token);
      token = decoded.token;
    } else if (localStorage.getItem("token")) {
      token = localStorage.getItem("token");
    } else {
      const payload = {
        origin: window.location.origin,
        redirectUri : window.location.pathname
    };
      const token = jwtEncode(payload, "");
      return (window.location.href = `${redirectUrl}?referrer=${token}`);
    }
    await axios
      .get(`${configData.config.UNO_URL}/api/user/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
      
        if (response?.status === 200) {
          if(response.data.data.user.permissions.includes("CONTENT.XR") ){
                  setDefaultRoute("/dashboard/xr");
          }else if(response.data.data.user.permissions.includes("CONTENT.3D")){
                  setDefaultRoute(`/dashboard/3d/${configData.config["3dAssetsId"]}`);
          }else if(response.data.data.user.permissions.includes("CONTENT.ENVIRONMENT")){
                  setDefaultRoute(`/dashboard/environments`);
          }

          localStorage.setItem("token", response?.data?.data?.token);
          const data = {
            token: response?.data?.data?.token,
            user: response?.data?.data?.user,
          };

          fetchApplication(response?.data?.data?.token);
          dispatch(saveUserData(data));
          setLoading(configData.loading);
          getAllXRModuleTags();
          getAllModuleList();
          getAllSkillStream2DTags();
          getAssetTagsList(config.assetFilterId);
          
        }
      })
      .catch((err) => {
        dispatch(removeUser());
        localStorage.clear();
        sessionStorage.clear();
        const payload = {
          origin: window.location.origin,
          redirectUri : window.location.pathname
      };
        const token = jwtEncode(payload, "");
        window.location.href = `${redirectUrl}?referrer=${token}`;
        console.log(err);
      });
  };

  useEffect(() => {
    if (configData.loading === false) {
      getUserDetail(configData.config);
    }
  }, [configData]);

  return (
    <>
      <PageTitleProvider>
        {loading ? (
          <FullScreenLoader loading={loading} />
        ) : (
          <div className={darkTheme ? "night" : ""}>
            <BrowserRouter>
              <Header
                darkTheme={darkTheme}
                setDarkTheme={setDarkTheme}
                sideBar={sideBar}
                setSideBar={setSideBar}
              />
              <div style={{ display: "flex" }}>
                <SideBar sideBar={sideBar} setSideBar={setSideBar} />
                <div className={sideBar ? "mainbody sideactive" : "mainbody"}>
                  <Routes>
                    <Route path="/" element={<Navigate to="/dashboard/xr" />} />
                      {
                        <>
                          {userData?.user?.permissions?.includes("CONTENT.XR") &&  
                              <>
                                <Route path="/dashboard/xr" element={<Dashboard />} />
                                {userData?.user?.permissions?.includes("CONTENT.XR.ASSETS") && <Route path="/dashboard/xr/assets/:uuid" element={<ModuleTabs tabName={"assets"} ChildComponent={UploadAssets} />} />}
                                {userData?.user?.permissions?.includes("CONTENT.XR.TASKS") && <Route path="/dashboard/xr/tasklist/:uuid" element={<ModuleTabs tabName={"tasklist"} ChildComponent={Tasklist} />} />}
                                {userData?.user?.permissions?.includes("CONTENT.XR.QUIZ") && <Route path='/dashboard/xr/quiz/:uuid' element={<ModuleTabs tabName={"quiz"} ChildComponent={QuizPage} />} />}
                                {userData?.user?.permissions?.includes("CONTENT.XR.SOPS") && <Route path="/dashboard/xr/sop/:uuid" element={<ModuleTabs tabName={"sop"} ChildComponent={SopPage} />} />}
                                {userData?.user?.permissions?.includes("CONTENT.XR.PASSING") && <Route path="/dashboard/xr/criteria/:uuid" element={<ModuleTabs tabName={"criteria"} ChildComponent={PassingCriteria} />} />}
                                <Route path="/dashboard/xr/details/:uuid" element={<ModuleTabs tabName={"details"} ChildComponent={Details} />} />
                                <Route path="/dashboard/xr/video/:uuid" element={<ModuleTabs tabName={"video"} ChildComponent={Videopage} />} />
                                <Route path="/dashboard/xr/channels/:uuid" element={<ModuleTabs tabName={"channels"} ChildComponent={Channels} />} />
                                <Route path="/dashboard/xr/channels/select/:uuid" element={<ModuleTabs tabName={"channels"} ChildComponent={ChannelsSelect} />} />
                                <Route path="/dashboard/xr/deploy/:uuid/:id" element={<ModuleTabs tabName={"channels"} ChildComponent={DeployPage} />} />
                              </>
                          }
                          

                          {userData?.user?.permissions?.includes("CONTENT.ENVIRONMENT") && 
                                    <>
                                      <Route path="/dashboard/environments" element={<ENVDashboard />} />
                                      <Route path="/dashboard/environments/details/:uuid" element={<ENVTabs tabName={"details"} ChildComponent={ENVDetails} />} />
                                      <Route path="/dashboard/environments/assets/:uuid" element={<ENVTabs tabName={"assets"} ChildComponent={ENVAssets} />} />
                                      <Route path="/dashboard/environments/content/:uuid" element={<ENVTabs tabName={"content"} ChildComponent={ENVContent} />} />
                                      <Route path="/dashboard/environments/channels/:uuid" element={<ENVTabs tabName={"channels"} ChildComponent={Channels} />} />
                                      <Route path="/dashboard/environments/channels/select/:uuid" element={<ENVTabs tabName={"channels"} ChildComponent={ChannelsSelect} />} />
                                    </> 
                            }

                          {userData?.user?.permissions?.includes("CONTENT.3D") && 
                              <> 
                              <Route path="/dashboard/3d/:id" element={<AssetDashboard />} />
                              <Route path="/dashboard/3d/:parentId/:id" element={<AssetsChildDashboard />} />
                              <Route path="/dashboard/3d/:id/details" element={<AssetsTabs tabName={"assetdetails"} ChildComponent={AssetDetails} />} />
                              </>
                          }

                        {userData?.user?.permissions?.includes("CONTENT.2D") && 
                          <>
                          <Route path="/dashboard/2d/lms" element={<LMSDashboard />} />
                          <Route path="/dashboard/2d/lms/details/:uuid" element={<LMSDetailsPage />} />
                          <Route path="/dashboard/2d/lms/subsection/details/:uuid" element={ <SubSectionDetails/> } />
                          <Route path="/dashboard/2d/lms/channels/select/:uuid" element={<ChannelsSelect />} />
                          
                          </>
                        }

                        </>
                      }
                    <Route path='*' element={<Navigate to={defaultRoute} />} />
                  </Routes>
                </div>
              </div>
            </BrowserRouter>
          </div>
        )}
      </PageTitleProvider>
    </>
  );
};

export default App;
