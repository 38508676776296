import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import jwtEncode from 'jwt-encode';
import axios from "axios";
import { SideNav, SimLogo, AppsIcon, SimApps } from '../../Static/icons/iconshere'
import { usePageTitle } from '../PageTitleContext';

import { redirectUrl , applicationId } from '../../env';
import { removeConfig } from "../../store/slice/configSlice"
import { removeUser } from '../../store/slice/userSlice';


const   Header = ({ sideBar, setSideBar }) => {
    const { pageTitle } = usePageTitle();
    const dispatch = useDispatch();
    const userData = useSelector(state => state.userSlice);
    const configData = useSelector((state) => state.configSlice);
    const applications = useSelector(state => state.applicationSlice).apps;
    const nameParts = userData.token?.length > 0 ? userData?.user?.name?.split(' ') : "";
    const placeholder = nameParts?.length > 0 ? nameParts?.length >= 2 ? `${nameParts[0][0]}${nameParts[1][0]}` : `${nameParts[0][0]}` : "";
    
    const logOutUser = async () => {
        try {
            await axios.post(`${configData.config.UNO_URL}/api/auth/logout`, {}, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`,
                },
            });
            
        } catch (error) {
            console.log(error);
        }finally{
            localStorage.clear();
            sessionStorage.clear();
            return window.location.href = `${redirectUrl}`;
        }
    }

    const moveToHome = () => {
        window.location.href = "/dashboard/home"
    }

    const redirectApplication = (link) =>{
        if(userData?.token && link){
          const payload = { token: userData?.token };
          const token = jwtEncode(payload, "");

          if(link.includes("apps.simulanis.io")){
            const newUrl = `${link}`;
            window.location.href = newUrl;

          }else{
              const newUrl = `${link}?referrer=${token}`;
              window.location.href = newUrl;
          }

        }
      }

    return (
        <header>
            <div className='sidetoggle'>
                <div className={sideBar ? "ps-1 activebar" : "ps-1"} onClick={() => setSideBar(!sideBar)} style={{ width: "25px", height: "25px" }}>
                    <SideNav />
                </div>
                <div className='mainlogo ' onClick={moveToHome} ><SimLogo /></div>
                <p className='currentpage'>{pageTitle}</p>
            </div>
            <div className='leftmain'>
                <div className='profile'>


                    <div className="dropdown profileavatar">
                        <p>{userData?.user?.name}</p>
                        <button className="btn btn-secondary dropdown-toggle themetoggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <div className='useravatar'>
                            {userData?.user?.avatar?.length ?
                                <img src={userData?.user?.avatar} alt={userData?.user?.name} title={userData?.user?.name} referrerPolicy='no-referrer' />
                                :
                                <div className="avatar-placeholder">{placeholder.toUpperCase()}</div>
                            }
                            </div>
                        </button>
                        <div className="dropdown-menu themehere popupon">
                            <div className="profilemenuhead" tabIndex="0">
                            {userData?.user?.avatar?.length ? 
                                <img src={userData?.user?.avatar} alt={userData?.user?.name} title={userData?.user?.name} className="profilepik" referrerPolicy={'no-referrer'} />
                                :
                                <div className="avatar-placeholder">{placeholder.toUpperCase()}</div>
                            }
                                <div className="profilemenudata">
                                    <p>{userData?.user?.name}</p>
                                    <p>{userData?.user?.email}</p>
                                </div>
                            </div>

                            <p className="logoutbtn" onClick={logOutUser}>Log out</p>
                        </div>
                    </div>

                    <div className="dropdown profileavatar appnavigator">
                        <button className="btn btn-secondary dropdown-toggle themetoggle " type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <SimApps />
                        </button>
                        <div className="dropdown-menu themehere popupon">
                            <div className="menubg">
                            {applications.map((e) => {
                                    if (e.uuid === applicationId) return null;
                                    return <div onClick={()=>redirectApplication(e.channel?.origins[0]?.url)}  key={e.uuid} className='appshere'>
                                            <div className="appshere">
                                                    <img src={e.icon || configData.config.defaultLogo}
                                                        onError={(e) => {
                                                            e.target.src = `${configData.config.defaultLogo}`
                                                            e.onerror = null;
                                                        }}
                                                        alt=''
                                                        referrerPolicy={'no-referrer'}
                                                    />
                                                    <p>{e.name}</p>
                                            </div>
                                             </div>
                                })}
                        
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </header>
    )
}

export default Header