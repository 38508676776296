import React, { useEffect, useState } from "react";
import AOS from "aos";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "aos/dist/aos.css";
import { useFormik } from "formik";
import Modal from 'react-bootstrap/Modal';
import {
  CloseIcon,
  EnableDeleteIcon,
  DisableDeleteIcon,
  EditIcon,
  QuesIcon,
  QuizIcon,
  Quizbar,
  TickHere,
} from "../../Static/icons/iconshere";
import {
  Button,
  TextField,
  TextareaAutosize,
  FormControl,
  InputLabel,
  MenuItem,
  RadioGroup,
  Radio,
  ListItemIcon,
  Checkbox,
  ListItemText,
  FormLabel,
  Select,
  FormControlLabel,
} from "@mui/material";
import axios from "axios";
import { usePageTitle } from '../PageTitleContext'
import NoDataFound from "./NoDataFound/NoDataFound";
import { BtnLoading } from "../../animations/BtnLoading";
import { debounce } from "../../utils/useWindowDimensions";


const QuizPage = ({ apiPath ="module" }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const locationData = location.state || {};
  const moduleUUID = params?.uuid;
  const maxOptions = 4;
  const [error, setError] = useState(null);
  const [moduleName, setModuleName] = useState(null);
  const [deleteQuizId, setDeleteQuizId] = useState(null);
  const [multipleDeleteQuizIds, setMultipleDeleteQuizIds] = useState([]);
  const [quizQuestionList, setQuizQuestionList] = useState([]);
  const [allQuizIds, setAllQuizIds] = useState([]);
  const configData = useSelector((state) => state.configSlice);
  const userData = useSelector((state) => state.userSlice);
  const { setPageTitle } = usePageTitle();
  const [showAddQuizModule, setShowAddQuizModule] = useState(false);
  const [showEditQuizModule, setShowEditQuizModule] = useState(false);
  const [showDeleteAllQuiz, setShowDeleteAllQuiz] = useState(false);
  const [showDeleteQuiz, setShowDeleteQuiz] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setPageTitle("XR Modules");
  }, []);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  useEffect(() => {
    setModuleName(locationData?.name)
  }, [locationData])

  const formik = useFormik({
    initialValues: {
      id: null,
      question: "",
      answerId: 0,
      options: [{ name: "" }, { name: "" }],
      quesType: 0, // 0 Means MCQ and 1 Means True/False
      weightAge: 1, // Question Marks
    },

    onSubmit: async (e) => {
      let formikData = formik.values;
      setError(null);

      if (formikData.question.trim() === "") {
          setError("Question should not be empty.");
          setTimeout(()=> setLoading(false), 500 )
          return;
      }
      
      if (formikData.quesType === 0) {
        for (let option of formikData.options) {
          if (option.name.trim() === "") {
              setError("All options' names should be filled.");
              setTimeout(()=> setLoading(false), 500 )
            return;
          }
        }
      }else if (formikData.quesType === 1) {
        formikData.options = [{ name: "False" }, { name: "True" }];
      }

      let result = null;
      if (formikData.id > 0) {
        closeEditModal();
        result = quizQuestionList.map(item => {
          if (item.id === formikData.id) {
            return { ...item, ...formikData };
          }
          return item;
        });

      } else {
        const _quizList = [...quizQuestionList, formikData]
        result = _quizList.map((item, index) => {
          return {
            ...item,
            id: index + 1
          };
        });
      }

      result.forEach((item, index) => {
        item.options.forEach((option, optionIndex) => {
          option.id = optionIndex;
        });
      });

      let data = {
        questions: [...result]
      }
      setQuizQuestionList([...result])
      handleSaveQuiz(data);

    },
  });

  const handleSaveQuiz = async (data) => {
    try {
      await axios.post(`${configData.config.UNO_URL}/api/resource/${apiPath}/quiz/${moduleUUID}`, data, {
       headers: {
         Authorization: `Bearer ${userData?.token}`,
         "Content-Type ": "application/json",
       }
     });
      
     formik.resetForm();
     getQuizAllQuestion();
    } catch (error) {
      console.log(error);
    } finally{
      setLoading(false)
    }
    


  }

  const getQuizAllQuestion = async () => {
    try {
      await axios.get(`${configData.config.UNO_URL}/api/resource/${apiPath}/quiz/${moduleUUID}`, {
        headers: {
          Authorization: `Bearer ${userData?.token}`,
          "Content-Type ": "application/json",
        }
      })
        .then(({ data }) => {
          let questions = data?.data?.children[0]?.versions[0]?.data?.questions || [];
          setQuizQuestionList([...questions]);
          let allIds = []
          questions.map(e => {
            allIds.push(e.id)
          });

          setAllQuizIds([...allIds])

        }).catch((err) => {
          console.log(err)
        });

    } catch (error) {

    }
  }

  useEffect(() => {
    if (moduleUUID.length) {
      getQuizAllQuestion();
    }
  }, [moduleUUID])


  const handleAddTextField = () => {
    if (formik.values.options.length < maxOptions) {
      formik.setFieldValue("options", [...formik.values.options, { name: "" }]);
    }
  };

  const handleDeleteTextField = (i) => {
    const updatedFields = [...formik.values.options];
    updatedFields.splice(i, 1);
    formik.setFieldValue("options", updatedFields);

    let correct = formik.values.answerId;
    if (correct === i) {
      formik.setFieldValue("answerId", 0);
    } else if (correct > i) {
      formik.setFieldValue("answerId", correct - 1);
    }
  };

  const changeQuestionType = (e) => {
    formik.setFieldValue("quesType", Number(e));
    formik.setFieldValue("answerId", Number(0));
  };

  const openModal = () => {
    formik.resetForm();
    setShowAddQuizModule(true);
  }

  const closeModal = () => {
    formik.resetForm();
    setError(null);
    setShowAddQuizModule(false)
  }

  const openEditModal = (e , qusId) => {
    formik.resetForm();
    formik.setFieldValue("id", e?.id)
    formik.setFieldValue("question", e?.question)
    formik.setFieldValue("answerId", e?.answerId)
    formik.setFieldValue("options", e?.options)
    formik.setFieldValue("quizType", e?.quizType)
    formik.setFieldValue("quesType", e?.quesType)
    formik.setFieldValue("weightAge", e?.weightAge)
    setShowEditQuizModule(true);
  }

  const closeEditModal = () => {
    formik.resetForm();
    setError(null);
    setShowEditQuizModule(false)
  }

  const openDeleteModal = (id) => {
    formik.resetForm();
    setDeleteQuizId(null)
    setShowDeleteQuiz(true);
    setDeleteQuizId(id)
  }

  const closeDeleteModal = () => {
    formik.resetForm();
    setDeleteQuizId(null)
    setShowDeleteQuiz(false)
  }
  const openDeleteAllModal = () => {
    formik.resetForm();
    setShowDeleteAllQuiz(true)
  }

  const closeDeleteAllModal = () => {
    formik.resetForm();
    setMultipleDeleteQuizIds([])
    setShowDeleteAllQuiz(false)
  }

  const handleDeleteQuiz = () => {
    const list = [...quizQuestionList]
    const updatedData = list.filter(item => item.id !== deleteQuizId);

    const result = updatedData.map((item, index) => {
      return {
        ...item,
        id: index + 1
      };
    });

    let data = {
      questions: [...result]
    }
    setQuizQuestionList([...result])
    handleSaveQuiz(data);
    let listQuiz = [...multipleDeleteQuizIds];
    const idIndex = listQuiz.findIndex(item => item === deleteQuizId);
    if (idIndex != -1) {
      listQuiz.splice(idIndex, 1);
      setMultipleDeleteQuizIds([...listQuiz]);
     }
    closeDeleteModal();
  }

  const selectMultipleQuiz = (id) => {
    let data = [...multipleDeleteQuizIds];
    const idToInsert = id;
    const idIndex = data.findIndex(item => item === idToInsert);
    if (idIndex === -1) {
      data.push(idToInsert);
    } else {
      data.splice(idIndex, 1);
    }
    setMultipleDeleteQuizIds([...data]);
  }

  const selectAllQuiz = () => {
    setMultipleDeleteQuizIds([]);
    if (multipleDeleteQuizIds.length !== allQuizIds.length) {
      setMultipleDeleteQuizIds([...allQuizIds]);
    }
  }

  const handleDeleteMultiQuiz = () => {
    const filteredArray = quizQuestionList.filter(obj => !multipleDeleteQuizIds.includes(obj.id));

    const result = filteredArray.map((item, index) => {
      return {
        ...item,
        id: index + 1
      };
    });

    let data = {
      questions: [...result]
    }
    setQuizQuestionList([...result])
    setMultipleDeleteQuizIds([])
    handleSaveQuiz(data);
    setShowDeleteAllQuiz(false)
  }

  const qusTypeChange= (e) =>{
        formik.setFieldValue("options", [{ name: "" }, { name: "" }])
        changeQuestionType(e.target.value);
   }

   const debounceHandleSaveReview = debounce(formik.handleSubmit, 500, setLoading);

  return (
    <>
      <div className="container-fluid" data-aos="fade-left">
        <div className="row align-items-center">
          <div className="col-6">
            <div className="headdetail">
              <span className="pagetitle"> {moduleName} </span>
            </div>
          </div>

          <div className="col-md-6  uppersection forheadbtn text-end align-items-center">
            <button
              style={{ marginTop: "5px" }}
              onClick={openModal}
            >
              <i className="bi-ui-checks" />
              Add Question
            </button>
          </div>
        </div>

        <div className='col-md-12 channelselect'>

          {allQuizIds.length > 0 &&
            <div className="form-check listchild">
              <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" checked={multipleDeleteQuizIds.length == allQuizIds.length} onChange={selectAllQuiz} />
              <label className="form-check-label" htmlFor="defaultCheck1">
                {multipleDeleteQuizIds.length}/{allQuizIds.length}
              </label>
              {multipleDeleteQuizIds.length > 0 ?
                <div className="svgbtn" onClick={openDeleteAllModal} style={{ marginTop: "-1px" }} >
                  <EnableDeleteIcon />
                </div>
                :
                <div className="svgbtn" style={{ marginTop: "-1px" }} >
                  <DisableDeleteIcon />
                </div>
              }

            </div>}

        </div>


        {quizQuestionList.length > 0 ?
          quizQuestionList.map((e, eId) => {
            return (
              <div className="row m-4 mt-2 quebodybg" key={eId} >
                <div className="col-md-11 " style={{ fontWeight: "500" }}>
                  <div className="form-check listchild quizhead">
                    <input className="form-check-input" type="checkbox" value="" checked={multipleDeleteQuizIds.includes(e.id)} id="defaultCheck2" onClick={() => selectMultipleQuiz(e.id)} />
                    <label className="form-check-label" htmlFor="defaultCheck2">
                      Question {e?.id}
                    </label>
                  </div>
                </div>

                <div className="col-md-1 text-end" style={{ cursor: "pointer" }}>
                  <div style={{ display: "flex", gap: "15px", alignItems: "center", justifyContent: "end" }}>
                    <div className="svgbtn" onClick={() => openEditModal(e)}>
                      <EditIcon />
                    </div>

                    {multipleDeleteQuizIds.includes(e.id) ?
                        <div className="svgbtn" onClick={() => openDeleteModal(e.id , (eId + 1))} >
                          <EnableDeleteIcon />
                        </div>
                         :
                        <div className="svgbtn" >
                          <DisableDeleteIcon />
                        </div>
                     }


                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 mt-3">
                    <div className="questionbox">
                      {e.question}
                    </div>
                  </div>
                </div>

                <div className="row pt-2">
                  {e.quesType == 0 ?
                    e.options.map((option, index) => (
                      <div className="col-md-6 py-2" key={index}>
                        <div className="ansparent">
                          {String.fromCharCode(65 + index)}

                          <div className={e.answerId == index ? "correctansw answbox" : "answbox"}>
                            <div className="optionndtick">
                              {option.name}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                    :
                    <>
                      <div className="col-md-6 py-2">
                        <div className="ansparent">
                          A
                          <div className={e.answerId == 1 ? "correctansw answbox" : "answbox"} >
                            <div className="optionndtick">
                              True
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 py-2">
                        <div className="ansparent">
                          B
                          <div className={e.answerId == 0 ? "correctansw answbox" : "answbox"}>
                            <div className="optionndtick">
                              False
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  }

                </div>

              </div>

            )
          })

          :
          <NoDataFound/>
          }

      </div>

      <Modal show={showAddQuizModule} onHide={closeModal} className="NewModule" centered size="md">
        <Modal.Body>
          <div className="modal-body platformhead quizmodal">
            <div className="row quizmodalhead">
              <div className="col-md-4 text-start">
              </div>
              <div className="col-md-12 text-center">
                <p className="headquiz"> Add Quiz Question</p>
              </div>
            </div>

            <div className="questype mt-3">
              Type
              <FormControl
                fullWidth
                className="mb-3 d-flex align-items-center"
              >
                <RadioGroup
                  row
                  aria-labelledby="price-visibility-label"
                  name="questionType"
                  value={formik.values.quesType}
                  onChange={qusTypeChange}
                >
                  <FormControlLabel
                    className="quizlabel"
                    value={0}
                    control={<Radio />}
                    label="MCQ"
                  />
                  <FormControlLabel
                    className="quizlabel"
                    value={1}
                    control={<Radio />}
                    label="True/False"
                  />
                </RadioGroup>
              </FormControl>
            </div>

            <div className="quesformbox ">
              <p>Question {quizQuestionList.length+1} </p>

              <input
                placeholder="Enter your Question...."
                type="text"
                name="question"
                className="input"
                value={formik.values.question}
                onChange={(e) =>
                  formik.setFieldValue("question", e.target.value)
                }
              />
            </div>

            {formik.values.quesType == 0 ? (
              <>
                {formik.values.options.map((field, eId) => (
                  <div className="row pt-2" key={eId}>
                    <div className="col-md-12 ">
                      <div className="ansparent ">
                        {String.fromCharCode(65 + eId)}

                        <input
                          placeholder={eId === 0 ? "Correct Option" : `Wrong Option `}
                          name={`options[${eId}].name`}
                          type="text"
                          className={eId === 0 ? "input correctone" : `input `}
                          onChange={formik.handleChange}
                          value={field.name}
                        />

                        {formik.values.options.length > 2 && (
                          eId !== 0 &&
                          <div className="cancelansw" onClick={() => handleDeleteTextField(eId)}>
                            <CloseIcon />{" "}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}

                {formik.values.options.length < maxOptions && (
                  <div className="col-md-12 text-end mt-2">
                    <p
                      style={{ color: "#ca373c", cursor: "pointer", fontSize: "13px" }}
                      onClick={handleAddTextField}
                    >
                      +  Add Option
                    </p>
                  </div>
                )}
              </>
            ) : (
              <div className="questype mt-3">
                Answer :
                <FormControl
                  fullWidth
                  className="mb-3 d-flex align-items-center"
                >
                  <RadioGroup
                    row
                    aria-labelledby="price-visibility-label"
                    name="questionType"
                    onChange={(e) =>
                      formik.setFieldValue(
                        "answerId",
                        Number(e.target.value)
                      )
                    }
                    value={formik.values.answerId}
                  >
                    <FormControlLabel
                      className="quizlabel"
                      value={0}
                      control={<Radio />}
                      label="False"
                    />
                    <FormControlLabel
                      className="quizlabel"
                      value={1}
                      control={<Radio />}
                      label="True"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            )}
          </div>
        </Modal.Body>

        <Modal.Footer className='mt-2 d-flex j'>
          <p className="errorMessage"> {error} </p>
          <div className='footerbtn ustify-content-between'>
            <button onClick={closeModal}>Cancel</button>
            {loading ?
            <Button > <BtnLoading /> </Button>
            :
            <button onClick={debounceHandleSaveReview}>Save</button>
            }

          </div>
        </Modal.Footer>
      </Modal>

      <Modal show={showEditQuizModule} onHide={closeEditModal} className="NewModule" centered size="md">
        <Modal.Body>
          <div className="modal-body platformhead quizmodal">
            <div className="row quizmodalhead">
              <div className="col-md-4 text-start">
              </div>
              <div className="col-md-12 text-center">
                <p className="headquiz"> Edit Quiz Question</p>
              </div>
            </div>

            <div className="questype mt-3">
              Type
              <FormControl
                fullWidth
                className="mb-3 d-flex align-items-center"
              >
                <RadioGroup
                  row
                  aria-labelledby="price-visibility-label"
                  name="questionType"
                  value={formik.values.quesType}
                  onChange={qusTypeChange}
                >
                  <FormControlLabel
                    className="quizlabel"
                    value={0}
                    control={<Radio />}
                    label="MCQ"
                  />
                  <FormControlLabel
                    className="quizlabel"
                    value={1}
                    control={<Radio />}
                    label="True/False"
                  />
                </RadioGroup>
              </FormControl>
            </div>

            <div className="quesformbox ">
              <p>Question {formik.values.id} </p>

              <input
                placeholder="Enter your Question...."
                type="text"
                name="question"
                className="input"
                value={formik.values.question}
                onChange={(e) =>
                  formik.setFieldValue("question", e.target.value)
                }
              />
            </div>

            {formik.values.quesType == 0 ? (
              <>
                {formik.values.options.map((field, eId) => (
                  <div className="row pt-2" key={eId}>
                    <div className="col-md-12 ">
                      <div className="ansparent ">
                        {String.fromCharCode(65 + eId)}

                        <input
                          placeholder={eId === 0 ? "Correct Option" : `Wrong Option `}
                          name={`options[${eId}].name`}
                          type="text"
                          className={eId === 0 ? "input correctone" : `input `}
                          onChange={formik.handleChange}
                          value={field.name}
                        />

                        {formik.values.options.length > 2 && (
                          eId !== 0 &&
                          <div className="cancelansw" onClick={() => handleDeleteTextField(eId)}>
                            <CloseIcon />{" "}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}

                {formik.values.options.length < maxOptions && (
                  <div className="col-md-12 text-end mt-2">
                    <p
                      style={{ color: "#ca373c", cursor: "pointer", fontSize: "13px" }}
                      onClick={handleAddTextField}
                    >
                      +  Add Option
                    </p>
                  </div>
                )}
              </>
            ) : (
              <div className="questype mt-3">
                Answer :
                <FormControl
                  fullWidth
                  className="mb-3 d-flex align-items-center"
                >
                  <RadioGroup
                    row
                    aria-labelledby="price-visibility-label"
                    name="questionType"
                    onChange={(e) =>
                      formik.setFieldValue(
                        "answerId",
                        Number(e.target.value)
                      )
                    }
                    value={formik.values.answerId}
                  >
                    <FormControlLabel
                      className="quizlabel"
                      value={0}
                      control={<Radio />}
                      label="False"
                    />
                    <FormControlLabel
                      className="quizlabel"
                      value={1}
                      control={<Radio />}
                      label="True"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            )}
          </div>
        </Modal.Body>

        <Modal.Footer className='mt-2 d-flex justify-content-between'>
          <p style={{ color: "red" }}> {error} </p>
          <div className='footerbtn'>
            <button onClick={closeEditModal}>Cancel</button>
            <button onClick={formik.handleSubmit}>Save</button>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal centered show={showDeleteQuiz} onHide={closeDeleteModal}>
        <Modal.Header className="popupheader delete pt-4">
          Delete Question
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group pt-4 pb-5 m-0">
                <p className="popupheading delete p-0 m-0">Do you really want to delete this Question?</p>
              </div>
              <div className="row">
                <div className="col-12 d-flex justify-content-center mt-2 mb-2 footerbtn">
                  <button onClick={closeDeleteModal}>Cancel</button>
                  <button className="ms-3" onClick={handleDeleteQuiz}>Delete</button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal centered show={showDeleteAllQuiz} onHide={closeDeleteAllModal}>
        <Modal.Header className="popupheader delete pt-4">
          Delete Question
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group pt-4 pb-5 m-0">
                <p className="popupheading delete p-0 m-0">Do you really want to delete selected Questions?</p>
              </div>
              <div className="row">
                <div className="col-12 d-flex justify-content-center mt-2 mb-2 footerbtn">
                  <button onClick={closeDeleteAllModal}>Cancel</button>
                  <button className="ms-3" onClick={handleDeleteMultiQuiz}>Delete</button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

    </>
  );
};

export default QuizPage;
