import React from 'react'
// import Lottie from 'react-lottie';
import nodatafound from '../../../Static/nodatafound1.json'; // Replace 'your-animation.json' with your JSON file path


const NoDataFound = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: nodatafound,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        },
        width: 140,
        height: 140,
    };

    return (
        <>
            <div className='text-center mt-5'>
                {/* <Lottie
                    options={defaultOptions}
                    height={defaultOptions.height}
                    width={defaultOptions.width}
                /> */}
                <h5>No Questions Found</h5>
                {/* <p>Add a question using Add Question button on top</p> */}
            </div>
        </>
    )
}

export default NoDataFound
