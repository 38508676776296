import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone'
import csvtojson from "csvtojson";
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import taskListCsvFileLocked from "../../Static/taskList.csv";

const CsvTaskModule = ({ showCsvModule , handleCloseCsvModule, handleSaveCsvList, locked }) => {
    const [list, setList] = useState([]);

    useEffect(()=>{
        setList([]);
    },[showCsvModule])

    function validateTaskObject(task) {
        const requiredProperties = ["Id", "Title", "Description", "Priority", "Time", "Critical"];
    
        for (const prop of requiredProperties) {
            if (!(prop in task) || (typeof task[prop] === "string" && task[prop].trim() === "")) {
                return false;
            }
        }
    
        if (!["0", "1"].includes(task.Critical)) {
            return false;
        }
    
        if (isNaN(Number(task.Id)) || isNaN(Number(task.Priority)) || isNaN(Number(task.Time))) {
            return false;
        }
        return true;
    }

    function validate(taskList) {
        for (const task of taskList) {
            if (!validateTaskObject(task)) {
                return false;
            }
          }
        return true;
        }
    
    const { getRootProps, getInputProps } = useDropzone({
        onDrop: async (acceptedFiles) => {
            const file = acceptedFiles[0];
            if (file && file.type === "text/csv") {
                const reader = new FileReader();
                reader.onload = async (e) => {
                  const csvString = e.target.result;
                  const jsonArray = await csvtojson().fromString(csvString);

                 if(jsonArray.length ){
                    if(validate(jsonArray)){
                    var taskList = jsonArray.map((e) => ({
                        id: Number(e.Id.trim()),
                        title: e.Title.trim(),
                        taskDesc: e.Description.trim(),
                        priority: Number(e.Priority.trim()),
                        plannedTime: e.Time.trim(),
                        isCritical: Boolean(Number(e.Critical.trim())),
                    }));
                    setList(taskList);
                    }else{
                        alert("Invalid file formate.");
                    }
                  }else{
                        alert("File is empty.")
                  }
                };
                reader.readAsText(file);
              }else{
                alert("Kindly upoad csv file.");
              }
        },
        maxFiles: 1,
        multiple: false,
        accept: '.csv'
    });

    const saveCSVList = () =>{
        handleSaveCsvList(list);
    }

    return (
        <>
            <Modal className='csvuploaddrr' size="lg" show={showCsvModule} centered onHide={handleCloseCsvModule}>
                <Modal.Body >

            {list.length <= 0 ?
                <div className="modalcont mt-3">
                    <div className="row">
                    <div className="col-12">
                        <div className='csvuploadefs'>
                        
                        <div {...getRootProps()} className="dropzone">
                        <input {...getInputProps()} accept=".csv" name="csv"  />
                        <FileUploadOutlinedIcon />
                        <p>Drag & drop or choose file to upload csv </p>
                        </div>

                        <div className='d-flex align-items-center justify-content-between mt-3'>
                            <p className="mb-0">
                            <a style={{textDecorationColor:"#66666e"}} href={taskListCsvFileLocked} download className="uloadoanload">
                                <FileDownloadIcon />
                                <span style={{color:"#66666e"}}>Download User CSV Templates</span>
                            </a>
                            </p>
                            <div>
                            <button onClick={handleCloseCsvModule} className='createbtn'>Cancel</button>
                            </div>
                        </div>

                        </div>
                    </div>
                    </div>
                </div>
                :
                <div className="modalcont mt-3">
                    <div className="row">
                    <div className="col-12 csvList">
                    <table>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Title</th>
                                <th>Description</th>
                                <th>Priority</th>
                                <th>Planned Time</th>
                                <th>Is Critical</th>
                            </tr>
                        </thead>
                        <tbody>
                            {list.map(task => (
                                <tr key={task.id}>
                                    <td>{task.id}</td>
                                    <td>{task.title}</td>
                                    <td>{task.taskDesc}</td>
                                    <td>{task.priority}</td>
                                    <td>{task.plannedTime}</td>
                                    <td>{Number(task.isCritical)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    </div>
                    </div>
                    <div className='d-flex align-items-center justify-content-between mt-3'>
                            <p className="mb-0" />
                            <div>
                            <button onClick={handleCloseCsvModule} className='createbtn'>Cancel</button>
                            <button onClick={saveCSVList} className='createbtn'>Save</button>
                            </div>
                        </div>
                </div>
            }
                </Modal.Body>
            </Modal >
      </>
    )
}

export default CsvTaskModule;