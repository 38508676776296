import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import AOS from "aos";
import "aos/dist/aos.css";
import { getAllModuleList, createNewCustomTags, moduleTagCategoryWithTags } from '../utils';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import logo from '../../Static/images/logo.png';
import Tooltip from '@mui/material/Tooltip';
import { modulePlatforms } from '../../utils/constants';
import {
    FormControl,
    RadioGroup,
    Radio,
    FormControlLabel,
} from "@mui/material";
import { usePageTitle } from '../PageTitleContext'
import { CloseIcon, SearchesCIcon, SortIcon } from '../../Static/icons/iconshere';


const Dashboard = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const configData = useSelector((state) => state.configSlice);
    const tagsData = useSelector((state) => state.tagsSlice);
    const userData = useSelector((state) => state.userSlice);
    const moduleData = useSelector((state) => state.moduleSlice);
    const moduleList = moduleData?.allModulesList || [];

    const [moduleName, setModuleName] = useState("");
    const [searchText, setSearchText] = useState("");
    const [error, setError] = useState(null);
    const [tagCategories, setTagCategories] = useState([]);
    const [tagsToDisplay, setTagsToDisplay] = useState([]);

    // const [selectedSectors, setSelectedSectors] = useState([]);
    // const [selectedPackages, setSelectedPackages] = useState([]);
    const [selectedSectors, setSelectedSectors] = useState({});
    const [selectedPackages, setSelectedPackages] = useState({});
    const [selectedJobRoles, setSelectedJobRoles] = useState([]);

    const [sectorTagOptions, setSectorTagOptions] = useState([]);
    const [packagesTagOptions, setPackagesTagOptions] = useState([]);
    const [jobRolesTagsOptions, setJobRolesTagsOptions] = useState([]);

    const [showAddModule, setShowAddModule] = useState(false);
    const { setPageTitle } = usePageTitle();


    const [showAllTags, setShowAllTags] = useState(false);
    const showMoreTags = () => {
        setShowAllTags(true);
    };


    useEffect(() => {
        setPageTitle("XR Modules");
    }, []);




    useEffect(() => {
        if (tagsData?.allTagsList?.name) {
            let _data = moduleTagCategoryWithTags();
            setSectorTagOptions(_data?._sectorsList);
            setTagCategories([_data._sectorsList[0].categoryId, _data._packagesList[0].categoryId, _data._jobRolesTags[0].categoryId]);
            setPackagesTagOptions(_data?._packagesList);
            setJobRolesTagsOptions(_data?._jobRolesTags);
        }
    }, [tagsData])

    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    const createModule = () => {
        setError(null);

        if (moduleName.length > 0 && Object.keys(selectedSectors).length > 0 && Object.keys(selectedPackages).length > 0 && selectedJobRoles.length) {

            let allIds = [];

            // selectedSectors.map((e) => {
            //     return (
            //         allIds.push(e.value)
            //     )
            // });
            // selectedPackages.map((e) => {
            //     return (
            //         allIds.push(e.value)
            //     )
            // });

            allIds.push(selectedSectors.value);
            allIds.push(selectedPackages.value);

            selectedJobRoles.map((e) => {
                return (
                    allIds.push(e.value)
                )
            });

            let createModule = {
                "name": moduleName,
                "tags": allIds
            }

            axios.post(`${configData.config.UNO_URL}/api/resource/module/create`, createModule, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userData.token}`
                }
            })
                .then((res) => {
                    closeModal();
                    getAllModuleList();
                })
                .catch((err) => {
                    console.log(err);
                })

        } else {
            setError("Fill All fields.");
        }
    }

    const openModal = () => {
        setModuleName("");
        setSelectedSectors([]);
        setSelectedPackages([]);
        setSelectedJobRoles([]);
        setError(null);
        setShowAddModule(true);
    }

    const closeModal = () => {
        setError(null);
        setShowAddModule(false)
    }

    const options = [
        { value: 'Automotive', label: 'Automotive' },
        { value: 'Pharmaceutical', label: 'Pharmaceutical' },
        { value: 'Mining', label: 'Mining' }
    ]

    return (
        <>
                    <div className='stuckhere modules'>
                    <div className='row'>
                    <div className="col-md-6">
                        <form className=" app-search app-search1 d-flex justify-content-start ">
                            <div className="position-relative">
                            <SearchesCIcon/>
                            <input placeholder="Search Modules...."   className="form-control" type="text" value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                            </div>
                        </form>
                    </div>
                    <div className="col-md-6  d-flex align-items-center justify-content-end mainbtns"> 
                            {userData?.user?.permissions?.includes("CONTENT.XR.CREATE") &&
                            <button onClick={openModal} className='createbtn'>Add Modules</button>
                        }
                    </div>
                    </div>
                    </div>

                    <div className='lowersection'>
                        <div className='container-fluid px-4'>
                            {moduleList.length > 0 ?
                                <div className='modulelist'>
                                    <div className="course-tiles">
                                        {moduleList?.map((e, index) => {
                                            const MAX_TAGS_TO_DISPLAY = 2;
                                            const allTags = e.tags.filter(tag => tagCategories.includes(tag.categoryId));
                                            const displayedTags = allTags.slice(0, MAX_TAGS_TO_DISPLAY);
                                            const remainingTags = allTags.slice(MAX_TAGS_TO_DISPLAY);
                                            const remainingTagsCount = e.tags.filter(tag => tagCategories.includes(tag.categoryId)).length - displayedTags.length;
                                            if (searchText.length && !(e.name.toLowerCase().includes(searchText.toLowerCase()))) return null;

                                            return (
                                                <div className="tilecard" >
                                                    <div className='modulecard module' key={index + 1} onClick={() => navigate(`/dashboard/xr/details/${e.uuid}`, { state: { name: e.name, type: "MODULE" } })}>                                                                                                  
                                                                <div className='moduleicon'>
                                                                    {e?.versions[0]?.data?.thumbnail ? (
                                                                        <img src={e.versions[0].data.thumbnail} alt="Thumbnail" />
                                                                    ) : (
                                                                        <img src={logo} className='defaultimg' alt="Default Thumbnail" />
                                                                    )}
                                                                </div>
                                                                <div className='moduledata'>
                                                                    <Tooltip title={e?.name}>
                                                                    <p className='modulename'>{e?.name}</p>
                                                                    </Tooltip>
                                                                    <div className="detailtags pb-0" style={{ justifyContent: "center" }}>
                                                                        {displayedTags.map((tag, index) => {
                                                                            return (
                                                                                <React.Fragment key={index + 1}>
                                                                                    <Tooltip title={tag.name}>
                                                                                    <p> {tag.name} </p>
                                                                                    </Tooltip>

                                                                                    <Tooltip title={remainingTags.map((remTag) => remTag.name).join(', ')} placement="top" arrow>
                                                                                        <span className='remainitmorebutton'>{index === MAX_TAGS_TO_DISPLAY - 1 && remainingTagsCount > 0 ? <p>+{remainingTagsCount} More</p> : null}</span>
                                                                                    </Tooltip>

                                                                                </React.Fragment>
                                                                            );
                                                                        })}


                                                                    </div>
                                                                     
                                                                        <p className='moduledes'>{e?.versions[0]?.data?.short}</p>
                                                                  

                                                                    <div className='moduleright'>
                                                                        <div className="devicelist">
                                                                        {e?.tags?.length > 0 && (

                                                                            <>

                                                                            {e.tags.map((tag, i) => {
                                                                                const platformInfo = modulePlatforms.find(platform => platform.name === tag.name);
                                                                                return platformInfo ? (
                                                                                    <Tooltip key={i} title={platformInfo.name} placement="top" arrow>
                                                                                        <img src={platformInfo.icon} alt={platformInfo.name} />
                                                                                    </Tooltip>
                                                                                ) : null;
                                                                            })}
                                                                            </>
                                                                             )}
                                                                        </div>
                                                                    </div> 
                                                                </div>
                                                    </div>
                                                </div>

                                            )
                                        })
                                        }
                                    </div>

                                </div>
                                :
                                <div className='modulelist'>
                                    <p> No Module Found </p>
                                </div>
                            }
                        </div>
                    </div>

            <Modal show={showAddModule} onHide={() => setShowAddModule(false)} className="NewModule" centered size="md">
                <Modal.Header className='modalheader'>
                     Enter Module Details 
                     <span onClick={() => setShowAddModule(false)} ><CloseIcon/></span>
                     </Modal.Header>
                <Modal.Body>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-12 mb-2'>
                                <div className="form-group">
                                    <label className="label">Name</label>
                                    <input autoComplete="off" placeholder='Module Name' name="moduleName" id="moduleName" value={moduleName} onChange={(e) => setModuleName(e.target.value)} className="input form-control" type="text" />
                                </div>
                            </div>

                            <div className='col-12 mb-2'>
                                <div className="input-group">
                                    <label className="label">Sectors</label>
                                    <Select
                                        // isMulti
                                        name="sectors"
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder="Select Sectors"
                                        options={sectorTagOptions}
                                        value={selectedSectors}
                                        onChange={setSelectedSectors}
                                        components={{ NoOptionsMessage: (props) => createNewCustomTags({ ...props, customName: "Sectors" }) }}
                                        filterOption={(option, inputValue) =>
                                            option.label.toLowerCase().includes(inputValue.toLowerCase())
                                        }
                                    />
                                </div>
                            </div>

                            <div className='col-12 mb-2'>
                                <div className="input-group">
                                    <label className="label">Packages</label>
                                    <Select
                                        // isMulti
                                        name="Packages"
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder="Select Packages"
                                        options={packagesTagOptions}
                                        value={selectedPackages}
                                        onChange={setSelectedPackages}
                                        components={{ NoOptionsMessage: (props) => createNewCustomTags({ ...props, customName: "Packages" }) }}
                                        filterOption={(option, inputValue) =>
                                            option.label.toLowerCase().includes(inputValue.toLowerCase())
                                        }
                                    />
                                </div>
                            </div>

                            <div className='col-12 mb-0'>
                                <div className="input-group">
                                    <label className="label">Job Roles</label>
                                    <Select
                                        isMulti
                                        name="jobRoles"
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder="Select Job Roles"
                                        options={jobRolesTagsOptions}
                                        value={selectedJobRoles}
                                        onChange={setSelectedJobRoles}
                                        components={{ NoOptionsMessage: (props) => createNewCustomTags({ ...props, customName: "Job Roles" }) }}
                                        filterOption={(option, inputValue) =>
                                            option.label.toLowerCase().includes(inputValue.toLowerCase())
                                        }
                                    />

                                        <p style={{ color: "red", fontSize:"13px", minHeight:"20px", fontWeight:"400" , margin:"0", marginTop:"10px"}}> {error} </p>
                                </div>
                            </div>

                        </div>
                    </div>



                </Modal.Body>

                <Modal.Footer className='footerbtn pt-0'>
                 
                               
                        <Button onClick={closeModal}> Cancel </Button>
                        <Button onClick={createModule}> Save </Button>
                   
                      
                </Modal.Footer>

            </Modal>

            <div className="modal fade modal-sm " id="PSBoxModal" tabIndex="-1" aria-labelledby="PSBoxModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content p-2">
                        <div className="modal-body platformhead quizmodal">
                            <div className='row quizmodalhead'>

                                <div className='col-md-12 text-center'>
                                    <p className='headquiz'>
                                        Passing Criteria
                                    </p>
                                </div>

                            </div>

                            <div className="questype mt-3">
                                Type of Deviation
                                <FormControl fullWidth className="mb-3 d-flex align-items-center" >
                                    <RadioGroup
                                        row
                                        aria-labelledby="price-visibility-label"
                                        name="questionType"
                                    // value={QuizType}
                                    // onChange={(e)=>{setQuiztype(e.target.value)}}
                                    >
                                        <FormControlLabel
                                            className="quizlabel"
                                            value={0}
                                            control={<Radio />}
                                            label="Time"
                                        />
                                        <FormControlLabel
                                            className="quizlabel"
                                            value={1}
                                            control={<Radio />}
                                            label="Step"
                                        />
                                        <FormControlLabel
                                            className="quizlabel"
                                            value={2}
                                            control={<Radio />}
                                            label="Time and Step"
                                        />
                                        <FormControlLabel
                                            className="quizlabel"
                                            value={3}
                                            control={<Radio />}
                                            label="Time or Step"
                                        />
                                    </RadioGroup>
                                </FormControl>

                            </div>
                            <div className="quesformbox mt-3">
                                <p>Minimum Passes</p>
                                <input placeholder="Minimum Passes" type="text" name="text" className="input mt-2"></input>
                            </div>
                            <div className="quesformbox mt-3 ">
                                <p>Time Deviation (%)</p>
                                <input placeholder="Time Deviation (%)" type="text" name="text" className="input mt-2"></input>
                            </div>
                            <div className="quesformbox mt-3 ">
                                <p>Time Deviation (%)</p>
                                <input placeholder="Time Deviation (%)" type="text" name="text" className="input mt-2"></input>
                            </div>
                        </div>
                        <div className="modal-footer footerbtn text-end">
                            <button type="button" >Close</button>
                            <button type="button" >Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Dashboard