import React,{ useState, useEffect} from 'react'
import QuizPage from '../../Quiz/QuizPage';

const SubSectionQuiz = () => {

  return (
    <>
    <div className="container-fluid ">
         <QuizPage apiPath ="2d/lms" />
    </div>
    </>
  )
}

export default SubSectionQuiz;