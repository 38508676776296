import { configureStore } from "@reduxjs/toolkit";
import contentSlice from "./slice/contentSlice";
import userSlice from "./slice/userSlice";
import configSlice from "./slice/configSlice";
import tagsSlice from "./slice/tagsSlice";
import moduleSlice from "./slice/moduleSlice";
import applicationSlice from "./slice/applicationSlice";



export const store = configureStore({
    reducer:{
        configSlice,
        contentSlice,
        userSlice,
        tagsSlice,
        moduleSlice,
        applicationSlice
    }
});
