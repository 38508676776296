import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { useSelector, useDispatch } from 'react-redux';
import { usePageTitle } from '../PageTitleContext'

const resourceType = {
    "MODULE": "xr",
    "ENVIRONMENT": "environments",
    "2DLMS": "2d/lms",
}

const Channels = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    if(!location.state.name || !location.state.type){
       navigate("/dashboard/xr");
    }
    const locationData = location.state;
    const moduleUUID = params?.uuid;
    const [moduleName, setModuleName] = useState("");
    const [allChannelList, setAllChannelList] = useState([]);
    const [selectedChannel, setSelectedChannel] = useState([]);
    const [allChannelIds, setAllChannelIds] = useState([]);
    const configData = useSelector((state) => state.configSlice);
    const userData = useSelector((state) => state.userSlice);
    const { setPageTitle } = usePageTitle();

    useEffect(() => {
        setModuleName(locationData.name)
    }, [locationData])

    useEffect(() => {
        setPageTitle("XR Modules");
    }, []);

    const getAllChannelList = async() => {
        try {
            let {data} = await axios.get(`${configData.config.UNO_URL}/api/channel/list`, {
                    headers: {
                        Authorization: `Bearer ${userData?.token}`,
                        "Content-Type ": "application/json"
                    }});

            setAllChannelList(data.data);
            let allIds = data.data.map((e) => e.id)
            setAllChannelIds(allIds)
            
        } catch (error) {
            console.log(error);
        }

    }

    useEffect(() => {
        if (moduleUUID?.length > 0) {
            getAllChannelList()
        }
    }, [moduleUUID])

    const selectChannel = (id) => {
        let channelIds = selectedChannel;
        const index = channelIds.indexOf(id);
        index !== -1 ? channelIds.splice(index, 1) : channelIds.push(id)
        setSelectedChannel([...channelIds])
    }

    const unSelectAllChannel = (e) => {
        if (selectedChannel.length == allChannelList.length) {
            setSelectedChannel([])
        } else {
            setSelectedChannel([...allChannelIds])
        }
    }

    const handleClickChannelAssign = () => {

        let resultArray = selectedChannel.map(id => {
            const foundItem = allChannelList.find(item => item.id === id);
            if (foundItem) {
                return { id: foundItem.id, name: foundItem.name };
            }
        });

        const stateData = { channel: resultArray, name: moduleName, type: locationData.type };
        navigate(`/dashboard/${resourceType[locationData.type]}/channels/select/${moduleUUID}`, { state: stateData });
    }

    return (
        <>
            <div className='container-fluid'>
                <div className='channelspage '>
                    <div className="headdetail  mb-2 gap-0">
                        <span className="pagetitle mb-0 pb-0"> {moduleName} </span>
                        <p className='mt-0' style={{ fontWeight: "500", fontSize: "14px" }}>Select Channels</p>
                    </div>
                </div>
                <div className='mainbox '>
                    <div className='row'>
                        <div className='col-md-12 channelselect'>
                            {allChannelList.length > 0 &&
                                <div className="form-check listchild">
                                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" checked={selectedChannel?.length == allChannelList?.length} onChange={unSelectAllChannel} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                        {`${selectedChannel.length}/${allChannelList.length}`}
                                    </label>
                                </div>
                            }
                        </div>

                        <div className='row mt-2'>
                            {allChannelList.map((e, i) => {
                                return (
                                    <div className='col-xl-3 col-lg-4 col-md-6' key={i}>
                                        <div className='channelbox' onClick={() => selectChannel(e.id)}>
                                            <div className='channelimg'>
                                                <div className="form-check listchild">
                                                    <input className="form-check-input" type="checkbox" id={e?.id} checked={selectedChannel.includes(e.id)} />
                                                </div>
                                                <img src={e.logo}></img>
                                            </div>
                                            <div className='channeldata'>

                                                <div className='channeluser pb-0'>
                                                    <div className='pb-1 nameimg d-flex flex-column justify-content-start align-items-start'>
                                                        {e.name}

                                                    </div>
                                                </div>
                                                <p className='m-0 pt-1 ps-1' style={{ fontSize: "12px", fontWeight: "500" }}>Details of last Synchronization Activity</p>
                                                <div className='channeluser'>

                                                    <div className='nameimg'>
                                                        {/* <img src={e?.operations[0]?.user?.avatar}></img> */}
                                                        {/* {e?.operations[0]?.user?.name} */}
                                                    </div>
                                                    <div className='channeldate'>
                                                        {/* {moment(e?.createdAt).format('DD/MM/YY, h:mm a')} */}
                                                    </div>
                                                </div>

                                                <div className='channeldetails'>
                                                    {/* <p>Details</p> */}
                                                    {/* <div className='detailtags'>
                                                        {Object.keys(e?.operations[0].requestData.operations).map((key) => {
                                                            if (key == "assets") return
                                                            else return (
                                                                <p>
                                                                    {details.filter((f) => f.key == key)[0].name}
                                                                </p>
                                                            )
                                                        })}
                                                    </div> */}
                                                </div>

                                                {/* {e?.operations[0].requestData.operations.assets.length > 0 ?
                                                    <div className='channeldetails'>
                                                        <p>Platforms</p>
                                                        <div className='detailtags platformtags'>

                                                            {e?.operations[0].requestData.operations.assets.map((f, i) => {
                                                                return (<p>
                                                                    <img src={platforms.filter((j) => j.key == f.platform)[0].icon} title={platforms.filter((j) => j.key == f.platform)[0].name} />
                                                                    {f.version}
                                                                </p>)
                                                            })}
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                                } */}

                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>


                    </div>
                </div>
                <div className='row mt-3'>
                    <div className="col-md-12 pe-2 mt-3">
                        <div className="footerbtn text-end">

                               <button 
                                    onClick={handleClickChannelAssign}
                                    disabled={selectedChannel.length === 0}
                                    title={selectedChannel.length === 0 ? "Disabled" : ""}
                                    className={selectedChannel.length === 0 ? "disabled" : ""}
                                > 
                                Next 
                                </button>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Channels
