import React, {useEffect, useState} from 'react'
import {
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@mui/material";
import { FileUploader } from "react-drag-drop-files";
import Modal from 'react-bootstrap/Modal';
import Progress from "react-progressbar";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import axios from "axios";
import { BtnLoading } from '../../../animations/BtnLoading';
import { GalleryIcon} from '../../../Static/icons/iconshere';
import { debounce } from '../../../utils/useWindowDimensions';


const SubSectionBasicDetails = ({ basicData }) => {
  const params = useParams();
  const uuid = params.uuid;
  const configData = useSelector((state) => state.configSlice);
  const userData = useSelector((state) => state.userSlice);
  const [uploadedProductFiles, setUploadedProductFiles] = useState([]);
  const fileTypes = ["JPG", "PNG", "GIF", "MP4", "PDF", "PPT"];
  const [loading, setLoading] = useState(false)
  const [mediaGalleryShow, setMediaGalleryShow] = useState(false);
  const GalleryClose = () => setMediaGalleryShow(false);
  const GalleryShow = () => setMediaGalleryShow(true);
  const [allSavedFileUrl, setAllSavedFileUrl] = useState([]);
  const [title, setTitle] = useState("");
  const [courseDuration, setCourseDuration] = useState("");
  const [moreInfo, setMoreInfo] = useState("");
  const [resource, setResource] = useState("");
  const [section, setSection] = useState("");
  const [subSection, setSubSection] = useState("");
  const [error, setError] = useState(null);

  useEffect(()=>{
    if(basicData){
      let version = basicData.versions[0].data;
      setTitle(basicData.name);
      setSubSection(basicData.name);
      setCourseDuration(version.courseDuration);
      setMoreInfo(version?.moreInfo || "");
      setAllSavedFileUrl([...version.media]);
      setSection(basicData.parent.name);
      setResource(basicData.parent.parent.name);
    }
  },[basicData])

  const handleProductImageChange = (files) => {
    const maxImageSize = 10 * 1024 * 1024;
    const filteredFiles = Array.from(files);
    const allFiles = [];
    filteredFiles.forEach((file) => {
      allFiles.push(file);
      // if (file.type.startsWith("image/") && file.size <= maxImageSize) {
      //   allFiles.push(file);
      // } else if (file.type.startsWith("video/")) {
      //   allFiles.push(file);
      // }else if (file.type.startsWith("video/")) {
      //   allFiles.push(file);
      // }
    });

    const updatedFiles = [...allFiles].map((file) => ({
      file,
      progress: 0,
    }));
    setUploadedProductFiles([...uploadedProductFiles, ...updatedFiles]);
    updatedFiles.forEach((file) => {
      uploadFile(file.file);
    });

  }

  const uploadFile = async (file) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      const response = await axios.post(`${configData.config.UNO_URL}/api/resource/2d/lms/upload/media/${uuid}`, formData, {
        headers: {
          Authorization: `Bearer ${userData?.token}`,
          "Content-Type ": "multipart/form-data"
        },
        onUploadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 100;
          updateFileProgress(file, Math.floor(progress));
        }
      });
      updateFileProgress(file, 100, response?.data?.url);
    } catch (error) {
      console.error(error);

    }
  };

  const updateFileProgress = (file, progress, url = null) => {
    setUploadedProductFiles((prevFiles) =>
      prevFiles.map((upload) => {
        if (upload.file === file) {
          return { ...upload, progress, url };
        }
        return upload;
      }));
  };

  const deleteProductImage = (type, url) => {
    if (type === "allSavedFileUrl") {
      const filteredData = allSavedFileUrl.filter(obj => obj.url !== url);
      setAllSavedFileUrl(filteredData)
    } else if (type === "uploadedProductFiles") {
      const filteredData = uploadedProductFiles.filter(obj => obj.url !== url);
      setUploadedProductFiles(filteredData)
    }
  }

  const saveProductImagesFile = async () => {
    let allFileArray = [...allSavedFileUrl, ...uploadedProductFiles].map(({ url }) => ({ url }));
    setAllSavedFileUrl(allFileArray)
    setUploadedProductFiles([]);
    GalleryClose();
  }

  const handleUpdateModuleDetails = async() => {
  try {
    setError(null);
    if(title.length === 0 || courseDuration.length === 0 ){
      setError("Title and Duration can't be empty.");
      return false;
    }

    let bodyData = {
      name: title,
      data: {
        media: allSavedFileUrl,
        courseDuration: courseDuration,
        moreInfo: moreInfo
      },
    };

    await axios.post(`${configData.config.UNO_URL}/api/resource/2d/lms/subSection/basic/${uuid}`, bodyData, {
      headers: {
        Authorization: `Bearer ${userData.token}`,
        }});
    
  } catch (error) {
    console.log(error);
  }finally{
    setLoading(false);
  }

  };


  const debounceHandleSaveReview = debounce(handleUpdateModuleDetails, 1000, setLoading);

  return (
    <>
    <div className='container-fluid'>
      <div className='mainbox lms detailspage mt-3'>
             <div className="col-md-12 pt-3  d-flex align-item-center justify-content-start">
                  <div className='headdetail gap-1'>
                  <span className="pagetitle ">Course Name : {resource} </span>
                  <span className="pagetitle ">Section Name : {section}</span>
                  <span className="pagetitle ">SubSection Name : {subSection}</span>
                  </div>
              </div>

              <div className='row mt-3'>
                <div className="col-md-4">
                  <div className="row">
                    <div className="col">
                      <div className="modulepic justify-content-start">
                        <button onClick={GalleryShow}  className="productpopup">
                          <GalleryIcon />
                          <p>Choose files to upload</p>
                        </button>
                      </div>
                    </div>
                    <div className="col p-0">
                      <label className="mb-2">Resources</label>
                      <p className="fileSuccess hoverCursor" > {allSavedFileUrl.length} Files Uploaded</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className='row mt-3'>
                  <div className='col-lg-8'>
                  <div className="form-group">
                    <label htmlFor="exampleFormControlInput1">
                      Title <span>*</span>
                    </label>
                    <input
                      type="text"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      className="form-control mt-2"
                      id="exampleFormControlInput1"
                      placeholder="Enter Title"
                    />
                  </div>
                  </div>
              </div>

              <div className='row mt-3'>
                  <div className='col-lg-4'>
                  <div className="form-group">
                    <label htmlFor="exampleFormControlInput1">
                      Duration(In Minutes)<span>*</span>
                    </label>
                    <input
                      type="text"
                      value={courseDuration}
                      onChange={(e) => setCourseDuration(e.target.value)}
                      className="form-control mt-2"
                      id="exampleFormControlInput1"
                      placeholder="Enter Duration"
                    />
                  </div>
                  </div>
              </div>
              <div className='row mt-3'>
                  <div className='col-lg-4'>
                  <div className="form-group">
                    <label htmlFor="exampleFormControlInput1">
                      Additional Info
                    </label>
                    <textarea
                      type="text"
                      value={moreInfo}
                      onChange={(e) => setMoreInfo(e.target.value)}
                      className="form-control mt-2"
                      id="exampleFormControlInput1"
                      placeholder="Enter Duration"
                    />
                  </div>
                  </div>
              </div>
            
              <div className='col-12 mt-5'>
                <div className="text-end">
                  <div className="footerbtn text-end">
                    <button>Cancel</button>
                    <button onClick={debounceHandleSaveReview} >
                      {loading ? <BtnLoading /> : "Save"}
                    </button>
                  </div>
              {error && <p className='uploadAssetsError'> {error} </p> }
              </div>
              </div>

      </div>
    </div>

       <Modal size="lg" centered show={mediaGalleryShow} onHide={GalleryClose}>
        <Modal.Header closeButton>Upload Media Gallery (Max file size: 10MB) </Modal.Header>
        <div className="modal-body productimages">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 p-1">
                <div className="fileuploader popup">
                <FileUploader
                  name="productFileInputs"
                  multiple
                  types={fileTypes}
                  label="drag & drop or choose file to upload (Max file size: 10MB)"
                  handleChange={handleProductImageChange}
                  className="w-100"
                  />
                </div>
              </div>
            </div>


            <div className="row">

              <div className="productthumbnail">

                {allSavedFileUrl?.map((upload, index) => (
                  <div className="uploadImageProcess" key={index}>
                    <div className="productimgprev">

                      {upload?.url?.split("/").pop().split(".").pop() == "mp4" ?
                        <>
                          <video width="113" height="115" controls>
                            <source src={upload.url || ""} type="video/mp4" />
                            <source src={upload.url || ""} type="video/ogg" />
                            Your browser does not support the video tag.
                          </video>
                          {upload?.url?.length > 0 && <i className="bi bi-x" title="delete" onClick={() => deleteProductImage("allSavedFileUrl", upload.url)}  ></i>}
                        </>
                        :
                        <>
                          <img className="prdimgprw" src={upload.url || ""} alt="image" />
                          {upload?.url?.length > 0 && <i className="bi bi-x" title="delete" onClick={() => deleteProductImage("allSavedFileUrl", upload.url)}  ></i>}
                        </>
                      }

                    </div>
                  </div>
                ))}

                {uploadedProductFiles.map((upload, index) => (
                  <div className="uploadImageProcess" key={index}>
                    <div className="productimgprev">
                      {upload?.url?.split("/").pop().split(".").pop() == "mp4" ?
                        <>
                          <video width="113" height="115" controls>
                            <source src={upload.url || ""} type="video/mp4" />
                            <source src={upload.url || ""} type="video/ogg" />
                            Your browser does not support the video tag.
                          </video>
                          {upload?.url?.length > 0 && <i className="bi bi-x" title="delete" onClick={() => deleteProductImage("uploadedProductFiles", upload.url)}  ></i>}
                        </>
                        :
                        <>
                          <img className="prdimgprwblr" src={upload.url || ""} alt="image" />
                          {upload?.url?.length > 0 && <i className="bi bi-x" title="delete" onClick={() => deleteProductImage("uploadedProductFiles", upload.url)}  ></i>}
                        </>
                      }
                    </div>
                    {upload.progress < 100 && <Progress completed={upload.progress} />}
                  </div>
                ))}

              </div>
            </div>

          </div>
        </div>

        <div className="col-12">
          <div style={{ padding: "1rem" }} className="footerbtn text-end">
            <button type="button" className="btn btn-secondary" onClick={GalleryClose} >Close</button>
            <button type="button" className="btn btn-primary" onClick={saveProductImagesFile} >Save changes</button>
          </div>
        </div>

      </Modal>

    </>
  )
}

export default SubSectionBasicDetails
