import React, { useEffect, useState } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';


const ModuleTabs = (props) => {
   const navigate = useNavigate();
   const params = useParams();
   const [moduleName, setModuleName] = useState("");
   const UUID = params?.uuid;
   const location = useLocation();
   const locationData = location.state || {};
   const Child = props.ChildComponent;
   const { tabName } = props;

   const handleTabSelect = (selectedTab) => {
      if(selectedTab == "back"){
         navigate("/dashboard/xr")
      }else{
         navigate(`/dashboard/xr/${selectedTab}/${UUID}`, { state: { name: moduleName, type: "MODULE" } })
      }
   };

   useEffect(() => {
      setModuleName(locationData?.name || "")
   }, [locationData])

   return (
      <>
         <div className='filterhere'>

            <Tabs
               activeKey={tabName}
               onSelect={handleTabSelect}
            >
               <Tab title={<span> <i className="bi-chevron-left"></i> Back </span>} eventKey="back" />  
               <Tab eventKey="details" title="Basic Details" /> 
               <Tab eventKey="assets" title="Upload Assets" />  
               <Tab eventKey="tasklist" title="Tasklist" />  
               <Tab eventKey="quiz" title="Quiz" /> 
               <Tab eventKey="sop" title="SOP" /> 
               <Tab eventKey="criteria" title="Passing Criteria" />   
               <Tab eventKey="video" title="Video" /> 
               <Tab eventKey="channels" title="Publish" />  
            </Tabs>
         </div>
         <div className='allcompages'>
            <Child {...props}></Child>
         </div>
      </>
   )
}
export default ModuleTabs;