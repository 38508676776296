import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Draggable, Droppable } from "react-beautiful-dnd";
import Tooltip from '@mui/material/Tooltip';
import { SectionList } from "./SectionList.js";
import { EditIcon } from "../../../Static/icons/iconshere.js";

let contentType = {
  "media" : "Media",
  "quiz" : "Assessment"
}




export function Section({ children = [], getSectionList, handleChangeStatus, ...props }) {

  const navigate = useNavigate();

  return (

    <>
      <Draggable draggableId={props.id} index={props.index} type="block">
          {(provided, snapshot) => (
            <div
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
              style={{
                ...provided.draggableProps.style,
                padding: 0,
                marginTop: 0,
                backgroundColor: snapshot.isDragging ? "rgba(202, 55, 60, 0.2)" : "rgba(255, 0, 0, 0.2)",
                borderBottom: "1px solid #a8a8a8",
              }}
            >
              <div className="section-item child">
                    <p> {props.index+1} | {props.name} </p>
                    <p> {props.contentType ?  contentType[props.contentType] + ` (${(props.lastAsset || "").toUpperCase()}) |`: null} {props.courseDuration || 0} Minutes
                    <div className="checkbox-wrapper-8">
                      
                      <input
                        type="checkbox"
                        id={props.uuid}
                        className="tgl tgl-skewed"
                        checked={props.active}
                        onChange={()=> handleChangeStatus(props.uuid, !props.active)}
                      />

                      <label
                        htmlFor={props.uuid}
                        data-tg-on="Active"
                        data-tg-off="Inactive"
                        className="tgl-btn"
                      />

                    </div>
                    <Tooltip title="Edit SubSection Details" onClick={() => navigate(`/dashboard/2d/lms/subsection/details/${props.uuid}`)} >
                       <span> <EditIcon/> </span>
                    </Tooltip>
                    
                    </p>
                </div>
              <Droppable
                droppableId={`inner-block-${props.id}`}
                type="blockList"
              >
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{
                      backgroundColor: snapshot.isDraggingOver
                        ? "green"
                        : "white"
                    }}
                  >
                  </div>
                )}
              </Droppable>
            </div>
          )}
      </Draggable>

     </>

 
  );
}
