import React, { useEffect, useState } from 'react'
import { FileUploader } from "react-drag-drop-files";
import { Editor } from "react-draft-wysiwyg";
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { CancelIcon } from '../../Static/icons/iconshere';
import { ContentState, EditorState, convertFromHTML, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { BtnLoading } from '../../animations/BtnLoading';
import Progress from 'react-progressbar';
import { debounce } from '../../utils/useWindowDimensions';

const fileTypes = ["JPG", "PNG", "GIF"];
const mediaFileTypes = ["MP4", "AVI", "MKV", "MOV", "WMV", "FLV", "WEBM", "OGG", "3GP"];
const resourceType = ['thumbnail', 'media']

const ENVDetails = () => {
    const configData = useSelector((state) => state.configSlice);
    const envTagId = useSelector((state) => state.configSlice.config.environmentTagId);
    const userData = useSelector((state) => state.userSlice);
    const [desc, setDesc] = useState("");
    const [maxUser, setMaxUsers] = useState(0);
    const [portNumber, setPortNumber] = useState("0000");
    const [envName, setEnvName] = useState("");
    const [thumbnail, setThumbnail] = useState(null);
    const [thumbnailUrl, setThumbnailUrl] = useState(null);
    const [thumbnailProgress, setThumbnailProgress] = useState(0);
    const [media, setMedia] = useState(null);
    const [mediaUrl, setMediaUrl] = useState(null);
    const [mediaProgress, setMediaProgress] = useState(0);
    const [uploadProcessing, setUploadProcessing] = useState(false);
    const [environmentTags, setEnvironmentTags] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedTags, setSelectedTags] = useState([]);
    const [selectedDesc, setSelectedDesc] = useState(EditorState.createEmpty());
    const [envUploadText, setEnvUploadText] = useState("Save");
    const [isBtnDisabled, setIsBtnDisabled] = useState(true);
    const [error, setError] = useState(null);
    
    const navigate = useNavigate()
    const { uuid } = useParams();

    const getEnvironmentDetail = async () => {
        try {
            const { data } = await axios.get(`${configData.config.UNO_URL}/api/resource/environment/details/${uuid}`, {
                headers: {
                    Authorization: `Bearer ${userData.token}`
                }
            });
            const response = data?.data;
            setEnvName(response?.name || "")
            setSelectedTags(data?.data?.tags || [])
            setMaxUsers(response?.versions[0]?.data?.maxUsers || 0)
            setPortNumber(response?.versions[0]?.data?.port || "0000")
            setThumbnailUrl(response?.versions[0]?.data?.thumbnail )
            setMediaUrl(response?.versions[0]?.data?.video )
            setDesc(response.versions[0]?.data?.description || "")
            const description = EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(response.versions[0]?.data?.description || "")));
            setSelectedDesc(description);
        } catch (error) {
            console.log(error)
        }
    }

    const handleNameChange = (e) => {
        const value = e.target.value;
        setEnvName(value);
        setIsBtnDisabled(value.trim() === '');
    }

    const removeHtmlTags = (htmlString) => {
        const div = document.createElement('div');
        div.innerHTML = htmlString;
        return div.textContent || div.innerText || '';
    };

    const handleCheckboxChange = (value) => {

        const selectedTag = environmentTags.find((tag) => tag.name === value);
        const isSelected = selectedTags.some((tag) => tag.name === value);

        if (isSelected) {
            setSelectedTags(selectedTags.filter((tag) => tag.name !== value));
        } else {
            setSelectedTags([...selectedTags, selectedTag]);
        }
    };

    const handleChangeMaxUsers = (event) => {
        setError(null)
        const regex = /^[0-9]*$/;
        if (regex.test(event.target.value)) {
            setMaxUsers(event.target.value);
        }
    }
    const handlePortNumber = (event) => {
        setError(null)
        const regex = /^[0-9]*$/;
        if (regex.test(event.target.value)) {
            setPortNumber(event.target.value);
        }
    }

    const handleChangeDescription = (event) => {
        setSelectedDesc(event);
        setDesc("" + draftToHtml(convertToRaw(event.getCurrentContent())));
    };

    const handleImageUpload = async (file) => {
        const uploadedFile = file;
        if (uploadedFile) {
            setUploadProcessing(true)
            setThumbnail(uploadedFile)
            const formData = new FormData();
            formData.append('file', uploadedFile);
            
            try {
                var response = await axios.post(`${configData.config.UNO_URL}/api/resource/upload/${resourceType[0]}/${uuid}`, formData, {
                    headers: {
                        Authorization: `Bearer ${userData?.token}`,
                        "Content-Type ": "multipart/form-data"
                    },
                    onUploadProgress: (progressEvent) => {
                        let progress = (progressEvent.loaded / progressEvent.total) * 100;
                        if (progress < 100) {
                            setThumbnailProgress(Math.floor(progress));
                        }
                    }
                });
                setThumbnailProgress(100);
                setUploadProcessing(false)
                setThumbnailUrl(response.data.url)
            } catch (error) {
                console.log(error);
                setUploadProcessing(false)
            }
        }
    };

    const handleMediaUpload = async (file) => {
        const uploadedFile = file;
        if (uploadedFile) {
            setUploadProcessing(true)
            setMedia(uploadedFile)
            const formData = new FormData();
            formData.append('file', uploadedFile);
            try {
                var response = await axios.post(`${configData.config.UNO_URL}/api/resource/upload/${resourceType[1]}/${uuid}`, formData, {
                    headers: {
                        Authorization: `Bearer ${userData?.token}`,
                        "Content-Type ": "multipart/form-data"
                    },
                    onUploadProgress: (progressEvent) => {
                        let progress = (progressEvent.loaded / progressEvent.total) * 100;
                        if (progress < 100) {
                            setMediaProgress(Math.floor(progress));
                        }
                    }
                });
                setMediaProgress(100)
                setUploadProcessing(false)
                setMediaUrl(response.data.url)
            } catch (error) {
                console.log(error)
                setUploadProcessing(false)
            }
        }
    };

    const handleSaveDetails = async () => {

        const tags = selectedTags.map(item => item.id);
        let filteredText = removeHtmlTags(desc);

        if (parseInt(maxUser) === 0 || maxUser === "") {
            setError("Max users can not be empty")
            setLoading(false)
            return
        }
        if (!portNumber.length) {
            setError("Port Number is Required")
            setLoading(false)
            return
        }

        const envData = {
            name: envName,
            tags: tags,
            data: {
                video: mediaUrl,
                maxUsers: maxUser ? parseInt(maxUser, 10) || 0 : 0,
                description: filteredText,
                thumbnail: thumbnailUrl,
                port : portNumber
            }
        }

        try {
            await axios.post(`${configData.config.UNO_URL}/api/resource/environment/update/${uuid}`, envData, {
                headers: {
                    "Content-Type ": "application/json",
                    Authorization: `Bearer ${userData?.token}`,
                }
            });
            window.location.reload();
        } catch (error) {
            console.log(error)
            setEnvUploadText("Failed")
        }
    }

    const cancleUploadThumbnail = () => {
        setThumbnail(null)
        setThumbnailUrl(null)
        setThumbnailProgress(0)
    }
    const cancleUploadMedia = () => {
        setMedia(null)
        setMediaUrl(null)
        setMediaProgress(0)
    }

    useEffect(() => {
        if (envName !== "") setIsBtnDisabled(false)
    }, [envName])

    useEffect(() => {
        getEnvironmentDetail();
        axios.get(`${configData.config.UNO_URL}/api/tag/list/${envTagId}`, {
            headers: {
                Authorization: `Bearer ${userData?.token}`
            }
        }).then((response) => {
            setEnvironmentTags(response.data?.data?.children[0]?.tags)
        }).catch((err) => {
            console.log(err);
        })
    }, []);

    const debounceHandleSaveReview = debounce(handleSaveDetails, 1000, setLoading);

    const formatSize = (bytes) => {
        if (bytes >= 1048576) {
            return (bytes / 1048576).toFixed(2) + " MB";
        } else if (bytes >= 1024) {
            return (bytes / 1024).toFixed(2) + " KB";
        } else {
            return bytes + " bytes";
        }
    };

    return (
        <>

            <div className="envdetails detailspage container-fluid" >
                <div className="mainbox">
                    <div className="row">
                        <div className="col-md-4">
                            <div className='d-flex justify-content-between flex-column gap-2 h-100'>
                                <div className="form-group">
                                    <label htmlFor="exampleFormControlInput1">Environment Name <span>*</span></label>
                                    <input
                                        type="text"
                                        value={envName}
                                        onChange={handleNameChange}
                                        className="form-control mt-2"
                                        id="exampleFormControlInput1"
                                        placeholder="Enter course name"
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleFormControlInput1">Max Users <span>*</span></label>
                                    <input
                                        type="text"
                                        value={maxUser}
                                        className="form-control mt-2"
                                        id="exampleFormControlInput1"
                                        placeholder="Enter max users"
                                        onChange={handleChangeMaxUsers}
                                    />
                                </div>
                                
                                <div className="form-group">
                                    <label htmlFor="exampleFormControlInput1">Port Number <span>*</span></label>
                                    <input
                                        type="text"
                                        value={portNumber}
                                        className="form-control mt-2"
                                        id="exampleFormControlInput1"
                                        placeholder="Enter Port Number"
                                        onChange={handlePortNumber}
                                    />
                                    <p className='error'>{error}</p>
                                </div>
                                
                            </div>
                        </div>
                        <div className='col-md-8'>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlDescription" className="form-label">
                                    Description
                                </label>
                                <div className="modalDescription">
                                    <Editor
                                        name="project_about"
                                        id="exampleFormControlDescription"
                                        stripPastedStyles={true}
                                        editorState={selectedDesc}
                                        wrapperClassName="demo-wrapper"
                                        className="mt-2"
                                        editorClassName="demo-editor"
                                        onEditorStateChange={handleChangeDescription}
                                        toolbar={{
                                            options: ["inline", "remove", "history"],
                                            inline: {
                                                inDropdown: false,
                                                options: ["bold", "italic", "underline", "strikethrough"],
                                            },
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4 '>
                            <div className="form-group mt-3 pt-2">
                                <label htmlFor="exampleFormControlInput1">Environment Type</label>
                                {environmentTags.length > 0 && environmentTags?.map((item) => (
                                    <div key={item.name} className="form-check listchild quizhead pt-3 ps-2 " style={{ cursor: "auto" }} onClick={() => handleCheckboxChange(item.name)}>
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id={item.id}
                                            value={item.name}
                                            checked={selectedTags.some((tag) => tag.name === item.name)}
                                        />
                                        <label className="form-check-label cursor-pointer" htmlFor={item.name}>
                                            {item.name}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className='col-md-8'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className="form-group mt-3">
                                        <label className='mb-3' htmlFor="exampleFormControlInput1">Environment Image <span>*</span></label>
                                        <div className='img-view for-vid'>
                                           
                                                {thumbnailUrl ? (
                                                    <>
                                                     <div className='fileuploader'>
                                                        <img
                                                            src={thumbnailUrl}
                                                            alt="Uploaded"
                                                            style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
                                                        />
                                                        <span onClick={cancleUploadThumbnail} className="canceldelete">
                                                            <CancelIcon />
                                                        </span>
                                                        </div>
                                                    </>
                                                ) : thumbnail ? (
                                                    <div className='fileuploader justify-content-end' >
                                                    <div className='uploadstatus w-100'>
                                                     
                                                            <div className="upperdiv">
                                                                <div className="uploaddata">
                                                                    <div className="xr">
                                                                        <p>{thumbnail.name}</p>
                                                                    </div>
                                                                    <div className=" xr mb-1">
                                                                        <p className="filesize">{formatSize(thumbnail.size)} </p>
                                                                        <p className="filesize">{thumbnailProgress}%</p>
                                                                    </div>
                                                                    <Progress completed={thumbnailProgress} />
                                                                </div>
                                                            </div>
                                                       
                                                    </div>
                                                    </div>
                                                ) : (
                                                    <div className='fileuploader'>
                                                    <FileUploader
                                                        name="thumbnailInput"
                                                        types={fileTypes}
                                                        label="drag & drop or choose file to upload"
                                                        handleChange={handleImageUpload}
                                                    />
                                                    </div>
                                                )}

                                           
                                        </div>
                                        <div className='uploadpromt'>
                                            File format accepted: {fileTypes.join(", ")}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className="form-group mt-3">
                                        <label className='mb-3' htmlFor="exampleFormControlInput1">Environment Video</label>
                                        <div className='img-view for-vid'>
                                           
                                                {mediaUrl ? (
                                                     <div className='fileuploader'>
                                                    <div>
                                                        <video style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} controls>
                                                            <source src={mediaUrl} type="video/mp4" />
                                                        </video>
                                                        <span onClick={cancleUploadMedia} className="canceldelete">
                                                            <CancelIcon />
                                                        </span>
                                                    </div>
                                                    </div>
                                                ) : media ? (
                                                    <div className='fileuploader justify-content-end' >
                                                    <div className='uploadstatus w-100'>
                                                       
                                                            <div className="upperdiv">
                                                                <div className="uploaddata">
                                                                    <div className="xr">
                                                                        <p>{media.name}</p>
                                                                    </div>
                                                                    <div className=" xr mb-1">
                                                                        <p className="filesize">{formatSize(media.size)} </p>
                                                                        <p className="filesize">{mediaProgress}%</p>
                                                                    </div>
                                                                    <Progress completed={mediaProgress} />
                                                                </div>
                                                            </div>
                                                       
                                                    </div>
                                                    </div>
                                                ) : (
                                                    <div className='fileuploader'>
                                                    <FileUploader
                                                        name="thumbnailInput"
                                                        types={mediaFileTypes}
                                                        label="drag & drop or choose file to upload"
                                                        handleChange={handleMediaUpload}
                                                    />
                                                    </div>
                                                )}
                                            
                                        </div>
                                        <div className='uploadpromt'>
                                            File format accepted: {mediaFileTypes.join(", ")}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            {userData?.user?.permissions?.includes("CONTENT.ENVIRONMENT.CREATE") &&
                <div className="col-md-12 pe-2 mt-4">
                    <div className="footerbtn text-end">
                        <button onClick={()=>navigate('/dashboard/environments')}>Cancel </button>
                        <button disabled={isBtnDisabled || uploadProcessing} onClick={debounceHandleSaveReview} > {
                            loading ? <BtnLoading /> : envUploadText}</button>
                    </div>
                </div>}
        </>
    )
}

export default ENVDetails;