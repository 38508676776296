import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import axios from 'axios';
import { useSelector } from 'react-redux';
import SubSectionBasicDetails from './SubSectionBasicDetails';
import AssetTab from './assetTab';

const SubSectionDetails = () => {
    const configData = useSelector((state) => state.configSlice);
    const userData = useSelector((state) => state.userSlice);
    const params = useParams();
    const uuid = params.uuid;
    const navigate = useNavigate();
    const[basicData, setBasicData] = useState(null);
    const[resourceId, setResourceId] = useState(null);
    const [currentTab, setCurrentTab] = useState("details");

    const handleTabSelect = (selectedTab) => {
        if(selectedTab == "back"){
          return navigate(`/dashboard/2d/lms/details/${resourceId}`,{state: { tab: "sectionManager", type: "2DLMS", name: basicData?.parent?.parent?.name || " ",  }})
       }
        setCurrentTab(selectedTab);
      }

      const getSubsectionBasic = async() =>{
        try {
         let { data } = await axios.get(`${configData.config.UNO_URL}/api/resource/2d/lms/subSection/basic/${uuid}`, {
           headers: {
             Authorization: `Bearer ${userData.token}`,
             }});
          setBasicData(data.data);   
          setResourceId(data.data.parent.parent.uuid);
        } catch (error) {
         console.log(error);
        }
       };

    useEffect(()=>{
        getSubsectionBasic();
    },[uuid])
      

  return (
    
    <div className='filterhere'>
      <Tabs activeKey={currentTab} onSelect={handleTabSelect} >
                <Tab title={<span> <i className="bi-chevron-left"></i> Back </span>} eventKey="back" />  
                
                <Tab eventKey="details" title="Basic Details"> 
                  <SubSectionBasicDetails basicData={basicData} />
                </Tab>

                <Tab eventKey="assetTab" title="Asset"> 
                  <AssetTab basicData={basicData} />
                </Tab>
    
        </Tabs>
    </div>
  )
}

export default SubSectionDetails
