import React, {useEffect, useState} from 'react'
import { FileUploader } from "react-drag-drop-files";
import Modal from 'react-bootstrap/Modal';
import Progress from "react-progressbar";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { FormControl, RadioGroup, Radio, FormControlLabel } from "@mui/material";
import { BtnLoading } from '../../../animations/BtnLoading';
import { debounce } from '../../../utils/useWindowDimensions';
import moment from 'moment';
import Releases from '../../Releases/releases';

const SubSectionAssets = ({ basicData }) => {
    const params = useParams();
    const uuid = params.uuid;
    const configData = useSelector((state) => state.configSlice);
    const userData = useSelector((state) => state.userSlice);
    const [comment, setComment] = useState("");
    const [loading, setLoading] = useState(false);
    const [type, setType] = useState("");
    const [youtubeLink, setYoutubeLink] = useState("");
    const [uploadedFile, setUploadedFile] = useState("");
    const [progressBar, setProgressBar] = useState(false);
    const [file, setFile] = useState(null);
    const [error, setError] = useState(null);
    const [versionList, setVersionList] = useState([]);
    const [uploadError, setUploadError] = useState(null);
    const [fileTypes, setFileTypes] = useState(["MP4"]);
    const [selectedOption, setSelectedOption] = useState(null);

    const staticOptions = [
      { value: 'video', label: 'Video' },
      { value: 'document', label: 'Document' },
      { value: 'youtube', label: 'YouTube' }
    ];

    const uploadAssetFile = async (file) => {
        try {
          setProgressBar(true);
          const formData = new FormData();
          formData.append("file", file);
          setFile(file);

          let { data } = await axios.post(`${configData.config.UNO_URL}/api/resource/2d/lms/upload/asset/${type}/${uuid}`, formData, {
              headers: {
                Authorization: `Bearer ${userData?.token}`,
                "Content-Type ": "multipart/form-data",
              },
            }
          );

          setUploadedFile(data.url);

        } catch (error) {
          console.log(error);
          setError("Something went wrong");
        } finally {
          setProgressBar(false);
        }
      };

    const getAssetVersion = async() =>{
        setVersionList([])
        try {
        let {data} = await axios.get(`${configData.config.UNO_URL}/api/resource/2d/lms/asset/list/versions/${uuid}`, {
          headers: {
            Authorization: `Bearer ${userData.token}`,
            }});

            setVersionList(data.data);

          } catch (error) {
            console.log(error);
          }
     };
    
    useEffect(()=>{
      getAssetVersion()
    },[uuid]);

    useEffect(()=>{
      if(type === "video"){
        setFileTypes(["MP4"])
      }else if(type === "document"){
        setFileTypes(["PDF","PPT","PPTX"])
      }
    },[type]);

    useEffect(()=>{
           if(basicData?.versions[0]?.data?.lastAsset){
            let lastAssetType = basicData.versions[0].data.lastAsset
            const selectedOptionIndex = staticOptions.findIndex(option => option.value === lastAssetType);
            if (selectedOptionIndex !== -1) {
              let selectedOption = staticOptions[selectedOptionIndex]
              setType(selectedOption.value);
              setSelectedOption(selectedOption);
            }

           }
    },[basicData])


const formatSize = (bytes) => {
  if (bytes >= 1048576) {
      return (bytes / 1048576).toFixed(2) + " MB";
  } else if (bytes >= 1024) {
      return (bytes / 1024).toFixed(2) + " KB";
  } else {
      return bytes + " bytes";
  }
};

  // const handleSelectFileType = (e) =>{
  //   setType(e.target.value);
  // }

  const handleCancelFile = () =>{
    setUploadedFile(null)
    setFile(null)
    setProgressBar(false)
    setComment("")
  }

  const uploadModuleAssetFile = async() => {
    setUploadError(null);
      const typeError = type.length === 0 ? "Please select a file type." : "";
      const youtubeError = type === "youtube" && youtubeLink.length === 0 ? " Please enter a YouTube link." : "";
      const uploadError = type !== "youtube" && uploadedFile.length === 0 ? " Please upload a file." : "";
      const commentError = comment.length === 0 ? " Please enter a comment." : "";

      if (typeError || youtubeError || uploadError || commentError) {
        setUploadError(typeError + youtubeError + uploadError + commentError);
        setLoading(false);
        return;
      }
      // if (type.length === 0 ) {
      //   setUploadError(`Kindly select file type.`);
      //   setLoading(false);
      //   return;
      // }

      // if ((type === "youtube" && youtubeLink.length === 0) || (type !== "youtube" && uploadedFile.length === 0) || comment.length === 0 || type.length === 0 ) {
      //   setUploadError(`kindly ${type === "youtube" ? "fill youtube link" : `upload ${type} file`} ${comment.length === 0 ? "and fill comment field" : ""}.`);
      //   setLoading(false);
      //   return;
      // }

    // try {
    //     let data = {
    //         type: type,
    //         assetLink: type === "youtube" ? youtubeLink : uploadedFile,
    //         embedLink : "",
    //         comment: comment
    //     }
    //     await axios.post(`${configData.config.UNO_URL}/api/resource/2d/lms/create/version/${type}/${uuid}`, data, {
    //         headers: {
    //             Authorization: `Bearer ${userData?.token}`,
    //             "Content-Type ": "application/json"
    //         }
    //     });


    //   setComment("");
    //   setUploadedFile(null);
    //   setFile(null);
    //   setError(null);
      
    // }catch (error) {
    //   setUploadError("Something went wrong.");
    // }finally{
    //   setYoutubeLink("")
    //   setComment("");
    //   setUploadedFile(null);
    //   setFile(null);
    //   setError(null);
    //    setLoading(false);
    //    setUploadError(null);
    //    getAssetVersion();
    //  }

  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const debounceHandleSaveReview = debounce(uploadModuleAssetFile, 1000, setLoading);



  const handleChange = (selected) => {
    setType(selected.value);
    setSelectedOption(selected);
  };

  return (
    <div className='forassets'>

       <div className='container-fluid mt-3'>

            <div className='row lms detailspage mt-4'>
                <div className='col-md-6'>
                
                {/* <div className='form-group'>
                    <label> Select Content type <span>*</span> </label>
                    <FormControl fullWidth className="mb-0 mt-2 pt-0 d-flex align-items-start">
                      <RadioGroup
                        row
                        aria-labelledby="price-visibility-label"
                        name="questionType"
                        className="gap-3"
                        onChange={handleSelectFileType}
                        value={type}
                      >
                        <FormControlLabel
                          className="quizlabel"
                          value="video"
                          control={<Radio />}
                          label="Video"
                        />
                        <FormControlLabel
                          className="quizlabel"
                          value="document"
                          control={<Radio />}
                          label="Document"
                        />
                        <FormControlLabel
                          className="quizlabel"
                          value="youtube"
                          control={<Radio />}
                          label="YouTube"
                        />
                  
                      </RadioGroup>
                    </FormControl>
                </div> */}

                <div className="form-group pt-2 mt-0">
                <label htmlFor="exampleFormControlInput1">Select Type<span>*</span></label>
                  <Select
                    name="MediaType"
                    className="basic-single-select mt-2"
                    classNamePrefix="select"
                    placeholder="Select Type"
                    options={staticOptions}
                    value={selectedOption}
                    onChange={handleChange}
                  />
                </div>

                <div className="DragDropMenu uploadfilesss mt-3">
                    {type == "youtube" ?
                      <div className='row'>
                            <div className="form-group">
                              <label htmlFor="exampleFormControlInput1">
                                Video Link <span>*</span>
                              </label>
                              <input
                                type="text"
                                value={youtubeLink}
                                onChange={(e)=>setYoutubeLink(e.target.value)}
                                className="form-control mt-2"
                                id="exampleFormControlInput1"
                                placeholder="Paste Video Link"
                              />
                            </div>
                      </div>  
                    :
                    (type == "video" || type == "document") ?
                      <div className='fileuploader'>
                      <FileUploader
                          handleChange={uploadAssetFile}
                          label="drag & drop or choose file to upload"
                          name="file"
                          types={fileTypes}
                        >
                        <span className='upload-box-info'>
                              drag & drop or choose file to upload
                        </span>
                      </FileUploader>
                      </div>
                    :
                    null
                    }
                </div>
              
                 <div className="uploadstatus">
                    {file &&
                      <div className="upperdiv">
                        <div className="uploaddata">
                            <div className="xr">
                            <p>{file.name}</p>   
                            <p style={{ cursor: "pointer", lineHeight: '1' }} onClick={handleCancelFile}>
                              <i className="bi bi-x-circle-fill"></i>
                            </p>
                            </div>
                            <div className=" xr mb-1">
                                <p className="filesize">{formatSize(file.size)} </p>
                                <p className="filesize"> {progressBar ? "uploading...." : "Done"} </p>
                                
                            </div>
                            <Progress completed="100%" />
                        </div>  
                      </div>
                    } 
                 </div>
                  
                {error && <p className='basicDetailError'> {error}  </p> }

                {type.length > 0 &&
                    <div className="mb-3 mt-2 VComment">
                        <label htmlFor="exampleFormControlTextarea1" className="form-label">Comment<span>*</span></label>
                        <textarea value={comment} onChange={(e)=> setComment(e.target.value)}  className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                    </div>
                 }

                 {uploadError && <p className='uploadAssetsError'> {uploadError} </p> }
                <div className="deploybtn" >
                  {((type.length === 0) || (type === "youtube" && youtubeLink.length === 0) || (type !== "youtube" && uploadedFile.length === 0) || (comment.length === 0) ) ?
                    <button className="btnError"> 
                        Upload 
                    </button> 
                    :
                    <button onClick={debounceHandleSaveReview} className="button"> 
                        { loading ? <> <BtnLoading /> </> : <> Upload </>  }
                    </button> 
                   }
                </div>
                </div>

                <div className='col-md-6 mt-3'>
                    {versionList.length > 0 ?
                        <div className='cardstyle'>

                            <div className="deploybtn d-flex justify-content-between">
                                <div>
                                    <div className='popupselect fortype'>
                                        <p className='mb-0'> {versionList[0]?.createdAt ? moment(versionList[0]?.createdAt).fromNow() : null} </p>
                                    </div>
                                </div>
                            </div>

                            <div className='filedetails'>
                                <p>Version :<span>{versionList[0]?.version}</span></p>
                                <p>Type :<span>{capitalizeFirstLetter(versionList[0]?.data?.type)}</span></p>
                                <p>Asset :<span><a href={versionList[0]?.data?.assetLink} download>{versionList[0]?.data?.assetLink?.split("/")[versionList[0]?.data?.assetLink?.split("/")?.length - 1]}</a></span></p>
                                <p>Uploaded By :<span>{versionList[0]?.activities[0]?.users[0]?.name}</span></p>
                                <p>Timestamp :<span>{versionList[0]?.createdAt ? moment(versionList[0]?.createdAt).format('MMMM Do YYYY, h:mm a') : null}</span></p>
                                <span className='versionlistv'>{versionList[0]?.data?.comment}</span>
                            </div>
                        </div>
                        : null}
                </div>

            </div>
        </div> 

        <div className='ReleaseHead'>
              {versionList.length > 0 ? <h1>Releases</h1> : null}
        </div>
        {versionList?.map((list, index) => {
            return <Releases list={list} key={index + 1} />
        })}

    </div>      
  )
}

export default SubSectionAssets
