import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import AOS from "aos";
import "aos/dist/aos.css";
import { useNavigate } from 'react-router-dom';
import logo from '../../Static/images/logo.png';
import Tooltip from '@mui/material/Tooltip';
import { usePageTitle } from '../PageTitleContext'
import { AllUserIcon, SearchesCIcon, SortIcon } from '../../Static/icons/iconshere';
import axios from 'axios';
import OculusIcon from '../../Static/images/oculusnew.svg'
import VrNEw from '../../Static/images/vrnew.svg'
import MobileNew from '../../Static/images/mobilenew.svg'
import DesktopIcon from '../../Static/images/desktopnew.svg'
import WebglNew from '../../Static/images/webglnew.svg'


const platforms = [
    {
        "name": "Standalone VR",
        "key": "standalonevr",
        "icon": OculusIcon
    },
    {
        "name": "Mobile",
        "key": "mobile",
        "icon": MobileNew
    },
    {
        "name": "Desktop VR",
        "key": "desktopvr",
        "icon": VrNEw
    },
    {
        "name": "Desktop",
        "key": "desktop",
        "icon": DesktopIcon
    },
    {
        "name": "Online",
        "key": "web",
        "icon": WebglNew
    },
];

const initialEnvironment = {
    name: null,
    error: null
}

const ENVDashboard = () => {
    const configData = useSelector((state) => state.configSlice);
    const userData = useSelector((state) => state.userSlice);
    const inputRef = useRef(null)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { setPageTitle } = usePageTitle();
    const [showAddModule, setShowAddModule] = useState(false);
    const [newEnvironment, setNewEnvironment] = useState(initialEnvironment);
    const [environmentList, setEnvironmentList] = useState([]);
    const [search, setSearch] = useState("");
    const [btnText, setBtnText] = useState("Create");

    const openModal = () => {
        setShowAddModule(true);
    }

    const closeModal = () => {
        setShowAddModule(false)
    }

    const getAllEnvironmentList = async () => {
        try {
            const { data } = await axios.get(`${configData.config.UNO_URL}/api/resource/environment/list`, {
                headers: {
                    Authorization: `Bearer ${userData.token}`,
                    "Content-Type ": "application/json"
                }
            })
            let response = data?.data.map((item) => {
                return {
                    ...item,
                    platform: item?.tags?.map((elem) => elem.name)
                };
            });
            setEnvironmentList(response);
        } catch (error) {
            console.log(error);
        }
    }

    const handleCreateEnvironment = async () => {
        if (newEnvironment.name === null) {
            setNewEnvironment({ ...newEnvironment, error: "Please enter environment name." })
            return
        }
        try {
            setBtnText("Processing...")
            var { data } = await axios.post(`${configData.config.UNO_URL}/api/resource/environment/create`, { name: newEnvironment.name, tags: [] }, {
                headers: {
                    Authorization: `Bearer ${userData.token}`
                }
            });
            setNewEnvironment(initialEnvironment);
            closeModal();
            navigate(`/dashboard/environments/details/${data.data.uuid}`,{state: {name: data.data.name, type: "ENVIRONMENT"} })
        } catch (error) {
            if (!error.response.data.status) setNewEnvironment({ ...newEnvironment, error: error.response.data.message })
            console.log(error);
        } finally {
            setBtnText("Create")
        }
    }
    const removeHtmlTags = (htmlString) => {
        const div = document.createElement('div');
        div.innerHTML = htmlString;

        return div.textContent || div.innerText || '';
    };

    useEffect(() => {
        if (showAddModule) {
            inputRef.current.focus();
        }
    }, [showAddModule]);

    useEffect(() => {
        setPageTitle("Environments");
        getAllEnvironmentList()
        AOS.init();
        AOS.refresh();
    }, []);


    return (
        <>

            <div className='stuckhere modules'>
                <div className='row'>
                    <div className="col-md-6">
                        <form className=" app-search app-search1 d-flex justify-content-start ">
                            <div className="position-relative">
                                <SearchesCIcon />
                                <input
                                    type="text"
                                    name="searchName"
                                    className="form-control"
                                    placeholder="Search "
                                    autoComplete="off"
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                            </div>
                        </form>
                    </div>
                    {userData?.user?.permissions?.includes("CONTENT.ENVIRONMENT.CREATE") &&
                        <div className="col-md-6  d-flex align-items-center justify-content-end mainbtns">
                            <button className='createbtn' onClick={openModal}>Add Environment</button>
                        </div>
                    }
                </div>
            </div>

            <div className='lowersection'> {/*Dashboard Cards */}
                <div className='container-fluid px-4'>
                    {environmentList.length > 0 ?
                        <div className='modulelist'>
                            <div className="course-tiles">
                                {
                                    environmentList.map((item) => {
                                        if (search.length > 0 && !item.name.toLowerCase().includes(search.toLowerCase())) return null;
                                        const desc = removeHtmlTags(item?.versions[0].data.description)
                                        return <div key={item.uuid} className="tilecard" >
                                            <div className='modulecard module' onClick={() => navigate(`/dashboard/environments/details/${item.uuid}`, {state: {name: item.name, type: "ENVIRONMENT"} })} >
                                                <div className='cursor-pointer'>
                                                    <div className='moduleleft d-block'>
                                                        <div className='moduleicon'>
                                                            <img src={item?.versions[0].data.thumbnail || logo} className='defaultimg' alt="Default Thumbnail" />
                                                        </div>
                                                        <div className='moduledata'>
                                                            <p className='modulename'>{item.name}</p>
                                                            <div className="detailtags userlistnumber pb-0" style={{ justifyContent: "center" }}>
                                                                <span>
                                                                    <AllUserIcon />
                                                                </span>{item?.versions[0].data.maxUsers} Users allowed
                                                            </div>

                                                            <p className='moduledes'>{desc}</p>

                                                            <div className='moduleright'>
                                                                <div className="devicelist">
                                                                    {
                                                                        item?.platform?.length > 0 && (
                                                                            <>
                                                                                {item.platform.map((e, i) => {
                                                                                    const platformInfo = platforms.find(platform => platform.name === e);

                                                                                    if (platformInfo && platformInfo.icon) {
                                                                                        return (
                                                                                            <Tooltip key={i} title={e} placement="top" arrow>
                                                                                                <img src={platformInfo.icon} alt="" />
                                                                                            </Tooltip>
                                                                                        );
                                                                                    }

                                                                                    return null;
                                                                                })}
                                                                            </>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                        :
                        <div className='modulelist'>
                            <p> No Environment Found </p>
                        </div>
                    }


                </div>

            </div>



            {userData?.user?.permissions?.includes("CONTENT.ENVIRONMENT.CREATE") &&
                <Modal show={showAddModule} size='md' centered onHide={() => setShowAddModule(false)}>
                    <Modal.Body className='NewModule p-4'>
                        <div className='container-fluid'>
                            <div className='row quizmodalhead'>
                                <div className='col-md-12 text-center'>
                                    <h5 className='headquiz'>
                                        Add Environment
                                    </h5>
                                </div>
                            </div>
                            <div className='row my-4'>
                                <div className='col-12 mb-2'>
                                    <div className="form-group">
                                        <label className="label pb-2">Name</label>
                                        <input ref={inputRef} onChange={(e) => setNewEnvironment({ ...newEnvironment, name: e.target.value, error: null })} autoComplete="off" placeholder='Environment Name' name="moduleName" id="moduleName" className="input form-control" type="text" />
                                        <p className='error'>{newEnvironment.error}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-12'>
                                <div className='text-end footerbtn text-end'>
                                    <button onClick={closeModal}>
                                        Cancel
                                    </button>
                                    <button onClick={handleCreateEnvironment}>
                                        {btnText}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            }
        </>

    )
}

export default ENVDashboard