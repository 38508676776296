import React, { useState, useEffect } from "react";
import AOS from "aos";
import { FileUploader } from "react-drag-drop-files";
import { FileIcon, LogHis, UploadStat, AddImageIcon } from "../../Static/icons/iconshere";
import "aos/dist/aos.css";
import Progress from "react-progressbar";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useSelector , useDispatch } from "react-redux";
import { usePageTitle } from '../PageTitleContext'
import  Tooltip  from "@mui/material/Tooltip";

const Videopage = () => {
    const params = useParams();
    const moduleUUID = params?.uuid;
    const location = useLocation();
    const locationData = location.state || {};
    const configData = useSelector((state) => state.configSlice);
    const userData = useSelector((state) => state.userSlice);
    const [uploadedVideoFiles, setUploadedVideoFiles] = useState({
                loading : null,
                file : null,
                url: null,
                duration:null
        });

    const [uploadedThumbnailFiles, setUploadedThumbnailFiles] = useState({
                loading : null,
                file : null,
                url: null
        });

    const [videoList, setVideoList] = useState([]);
    const [moduleName, setModuleName] = useState("");
    const [titleName, setTitleName] = useState("");
    const [description, setDescription] = useState("");
    const { setPageTitle } = usePageTitle();

    useEffect(() => {
        setPageTitle("XR Modules");
      }, []);

    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    useEffect(()=>{
        setModuleName(locationData?.name)
    },[locationData])

    const formatSize = (bytes) => {
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) return '0 Byte';
        const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    };

    const getVideoList = async () => {
            await axios.get(`${configData.config.UNO_URL}/api/resource/module/video/${moduleUUID}`, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`,
                    "Content-Type": "application/json"
                }
            }).then((response) => {
                const sopData = response?.data?.data?.children[0]?.versions[0]?.data?.docs || []
                setVideoList([...sopData]);
            }).catch((error) => {
                console.log(error)
            })
    };

    useEffect(() => {
        getVideoList();
    }, [])

    const handleUpdateVideosList = async(list) =>{
        try {
             
        setVideoList([...list]);
        let _data = {
            docs:[...list]
        }
        resetFormData();
        await axios.post(`${configData.config.UNO_URL}/api/resource/module/video/${moduleUUID}`, _data, {
            headers: {
                Authorization: `Bearer ${userData?.token}`,
                "Content-Type": "application/json"
            }
        });
        getVideoList();
    } catch (error) {
        console.log(error) 
    }

    }

    const videoDeleteItem = async (index, sopId) => {
        try {
          const userConfirmed = window.confirm("Are you sure you want to delete this Video");
      
          if (userConfirmed) {
            const updatedSopList = [...videoList];
            updatedSopList.splice(index, 1);
            handleUpdateVideosList(updatedSopList);
          }
        } catch (error) {
          console.error('Error deleting SOP:', error);
        }
      };
      

    const toggleSopVisibility = (index) => {
        const updatedSopList = [...videoList];
        updatedSopList[index].visible = !updatedSopList[index].visible;
        handleUpdateVideosList(updatedSopList);
    };

    const uploadFile = async (file, type) => {
        try {
            const formData = new FormData();
            formData.append('file', file);

            const {data} = await axios.post(`${configData.config.UNO_URL}/api/resource/upload/video/${moduleUUID}`, formData, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`,
                    "Content-Type ": "multipart/form-data"
                }
            });

            if(data.status){
                if(type === "video"){
                    setUploadedVideoFiles((preState)=>({...preState, url: data.url, loading : false}));
                }else if(type === "image"){
                    setUploadedThumbnailFiles((preState)=>({...preState, url: data.url, loading : false}));
                }
            }
            
        } catch (error) {
            resetFormData();
            console.log(error);
        }
    };
    
    const resetFormData =()=>{
        setUploadedVideoFiles({
            loading : null,
            file : null,
            url:null,
            duration:null
        });
        setUploadedThumbnailFiles({
            loading : null,
            file : null,
            url:null
        });
        setTitleName("");
        setDescription("");
    };
    
    const handleVideoFileChange = async (event,type) => {
        const selectedFile = event.target.files[0];
        const fileType =  selectedFile.type.split("/")[0];
        
        if(fileType == type ){
            //let preview: URL.createObjectURL(selectedFile),
            if(type == "video"){
                const videoElement = document.createElement('video');
                videoElement.src = URL.createObjectURL(selectedFile);
                videoElement.addEventListener('loadedmetadata', function () {
                    setUploadedVideoFiles((pre)=>({...pre, duration: Math.floor(videoElement.duration) }));
                    videoElement.remove();
                });
                
                setUploadedVideoFiles((pre)=>({
                    ...pre,
                    loading : true,
                    file : selectedFile,
                    url : null,
                }));

  
                }else if(type == "image"){
                    setUploadedThumbnailFiles({
                        loading : true,
                        file : selectedFile,
                        url:null
                  });
            }
            uploadFile(selectedFile, type);  
        }else{
            alert("please upload video in video section or image in thumbnail section.")
        }
    };

    const handleSaveVideo = async() =>{
           try {

            let fileUrls = {
                file : uploadedVideoFiles.url,
                name: titleName,
                description : description,
                thumbnail : uploadedThumbnailFiles.url,
                visible : true,
                duration : uploadedVideoFiles.duration
            }

            if(fileUrls.file && fileUrls.thumbnail && fileUrls.name ){
                handleUpdateVideosList([...videoList , { ...fileUrls, visible : true }])
            }else{
             alert("Kindly upload video, upload thumbnail and fill title.");
            }
            
           } catch (error) {
            console.log(error)
           }
    }

    const cancelSelectItem = (type) =>{
           if(type == "video"){
            setUploadedVideoFiles({
                loading : null,
                file : null,
                url:null,
                duration:null
                });
           }else if(type == "image"){
            setUploadedThumbnailFiles({
                loading : null,
                file : null,
                url:null
               });
           }
    } 


    return (
        <div className="container-fluid " data-aos="fade-left">
            <div className="headdetail">
                <span className="pagetitle">
                    {moduleName}
                </span>
            </div>
            <div className="row">
                <div className="col-md-6 forsop">
                <div className="row">
                  <div className="col-md-6 forsop">
                    <p className="titlehere">Add Video</p>
                    <div className="DragDropMenu mt-3">
                        <label htmlFor="fileInputVideo" className="custom-file-upload">
                            <input
                                type="file"
                                id="fileInputVideo"
                                key={uploadedVideoFiles.file ? uploadedVideoFiles.file.name : 'default'}
                                onChange={(event)=>handleVideoFileChange(event, "video")}
                                accept="video/*"
                            />
                            <div>
                                <AddImageIcon /><br />
                                <span>drag & drop or choose file to upload video</span>
                            </div>
                        </label>
                        
                        <div className="uploadstatus">
                                {uploadedVideoFiles.loading != null &&
                                    <div className="upperdiv">
                                        <div className="uploaddata">
                                            <div className="xr">
                                                <Tooltip title={uploadedVideoFiles.file.name}>
                                                <p>{uploadedVideoFiles.file.name}</p>
                                                </Tooltip>
                                                <p style={{ cursor: "pointer", lineHeight: '1' }} onClick={()=> cancelSelectItem("video")} >
                                                <i className="bi bi-x-circle-fill"></i>
                                                </p>


                                              
                                            </div>
                                            <div className="mb-1 xr" >
                                                <p className="filesize">{formatSize(uploadedVideoFiles.file.size)} </p>
                                                { uploadedVideoFiles.loading ?
                                                    <p className="filesize">Uploading...</p>
                                                    :
                                                    <p className="filesize">Done</p>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }
                        </div>

                    </div>
                  </div>

                  <div className="col-md-6 forsop">
                    <p className="titlehere"> Add Thumbnail </p>
                    <div className="DragDropMenu mt-3">
                        <label htmlFor="fileInputImage" className="custom-file-upload">
                            <input
                                type="file"
                                id="fileInputImage"
                                key={uploadedThumbnailFiles.file ? uploadedThumbnailFiles.file.name : 'default'}
                                onChange={(event)=>handleVideoFileChange(event, "image")}
                                accept="image/*"
                            />
                            <div>
                                <AddImageIcon /><br />
                                <span>drag & drop or choose file to upload thumbnail</span>
                            </div>
                        </label>
                        
                        <div className="uploadstatus">
                                {uploadedThumbnailFiles.loading != null &&
                                    <div className="upperdiv">
                                        <div className="uploaddata">
                                            <div className="xr">
                                                <Tooltip title={uploadedThumbnailFiles.file.name}>
                                                <p>{uploadedThumbnailFiles.file.name}</p>
                                                </Tooltip>
                                                <p style={{ cursor: "pointer", lineHeight: '1' }} onClick={()=> cancelSelectItem("image") } >
                                                <i className="bi bi-x-circle-fill"></i>
                                                </p>

                                               
                                            </div>
                                            <div className="mb-1 xr">
                                                <p className="filesize">{formatSize(uploadedThumbnailFiles.file.size)} </p>
                                                { uploadedThumbnailFiles.loading ?
                                                    <p className="filesize" >Uploading...</p>
                                                    :
                                                    <p className="filesize">Done</p>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }
                        </div>

                    </div>
                  </div>


                  <div className="col-md-12 quesformbox mt-2"> 
                    <p>Video Title</p>
                    <input type="text" value={titleName} onChange={(e) => setTitleName(e.target.value) } placeholder="video title" className="input mt-2" />
                  </div>
                  <div className="col-md-12 quesformbox mt-2"> 
                    <p>Video Description</p>
                    <input type="text" value={description} onChange={(e) => setDescription(e.target.value) } placeholder="video description" className="input mt-2" />
                  </div>

                  <div className="footerbtn text-end mt-3">
                    <button type="button" className="btn btn-secondary" onClick={resetFormData} > Cancel </button>
                    <button type="button" className="btn btn-primary" onClick={handleSaveVideo} > Save </button>
                  </div>
                </div>

                </div>

                <div className="col-md-6 px-5">
                    <p className="titlehere">Videos list</p>
                    <div className="splist">

                        {videoList.length > 0 && videoList?.map((item, index) => (
                            <div key={index} className="soplisthere">
                                <div className="sopdiv">
                                    <div className="sopimg">
                                        <FileIcon />
                                    </div>
                                    <div className="sopdata">
                                        <p>{item?.name}</p>
                                        <a className="downloadsop" href={item?.file} target="_blank" download={true}>
                                            Download Video
                                        </a>
                                        <div className="visibletoggle">
                                            Visible:
                                            <div className="statustoggle">
                                                <div className="checkbox-apple">
                                                    <input
                                                        className="yep"
                                                        id={`check-apple-${index}`} // Make each checkbox have a unique ID
                                                        type="checkbox"
                                                        checked={item.visible}
                                                        onChange={() => toggleSopVisibility(index)}
                                                    />
                                                    <label htmlFor={`check-apple-${index}`}></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <span className="deletesopbtn" onClick={() => videoDeleteItem(index)}><i className="bi bi-x-circle-fill"></i></span>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            </div>
        </div>
    );
}

export default Videopage;