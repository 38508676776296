import React, { useState, useEffect } from 'react';
import { Section } from "./Section.js";
import Tooltip from '@mui/material/Tooltip';
import { Draggable, Droppable } from "react-beautiful-dnd";
import { AddIcon, EditIcon } from "../../../Static/icons/iconshere.js";
import { CreateSectionModal } from '../Modal/CreateSectionModal.js';


export function SectionList({ children = [], getSectionList, handleChangeStatus, ...props }) {

  const [showCreateModal, setShowCreateModal] = useState({
    name: "",
    type: null,
    status: null,
    resourceId: null,
    action: null,
    modal: null
  });

  const handleOpenCreateModal = (type, id, name, action, modal) =>{
    setShowCreateModal({
      type: type,
      status: true,
      resourceId: id,
      name: name,
      action: action,
      modal: modal
    });
  }

  const handleCloseModal = () => {
    setShowCreateModal({
    name: "",
    type: null,
    status: null,
    resourceId: null,
    action: null,
    modal: null
    })
  };

  let duration = children.map((e) => parseFloat(e.courseDuration)).filter((value) => !isNaN(value)).reduce((acc, current) => acc + current, 0)

  return (
     <>
        <Draggable draggableId={props.id} index={props.index} type="sectionList">
          {(provided) => (
            <div
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
              style={{
                ...provided.draggableProps.style,
                padding: 0,
                marginTop: 0,

              }}
            >
            
            <div className="section-item">
                <p>
                    {props.index + 1} | {props.name}
                    <Tooltip title="Edit Section Name">
                    <span onClick={()=>handleOpenCreateModal("section", props.uuid, props.name, "update", "Edit")}>
                        <EditIcon/>
                    </span>
                    </Tooltip>
                </p>
                <p>

                  { children.length || 0 } Sub-Sections | {duration || 0} Minutes
                    <div className="checkbox-wrapper-8">
                      <input
                        type="checkbox"
                        id={props.uuid}
                        className="tgl tgl-skewed"
                        checked={props.active}
                        onChange={()=> handleChangeStatus(props.uuid, !props.active)}
                      />
                      <label
                        htmlFor={props.uuid}
                        data-tg-on="Active"
                        data-tg-off="Inactive"
                        className="tgl-btn"
                      />
                  </div>

                    <Tooltip title="Add SubSection" onClick={()=>handleOpenCreateModal("subsection", props.uuid, "", "create", "Add")} >
                    <span> <AddIcon/> </span>
                    </Tooltip>

                </p>
            </div>

              <Droppable droppableId={props.id} type="section">
                {(provided, snapshot) => (
                  <div ref={provided.innerRef} {...provided.droppableProps} style={{margin: "8px", backgroundColor: snapshot.isDraggingOver ? "rgba(202, 55, 60, 0.2)" : "white" }} >

                  { children.length > 0 ?
                    children.map((section, index) => (
                      <Section
                        key={`${section.type}-${index}`}
                        {...section}
                        index={index}
                        handleChangeStatus={ handleChangeStatus }
                      />
                    )) 
                    : 
                    <div className="empty-data child">
                        <p>This section is currently empty, Click below to add a new Sub-Section</p>
                        <button className="createbtn" onClick={()=>handleOpenCreateModal("subsection", props.uuid, "", "create", "Add")}  >Add Sub-Section</button>
                    </div>
                    }
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          )}
        </Draggable>

       <CreateSectionModal showCreateModal={showCreateModal} handleCloseModal={handleCloseModal} getSectionList={getSectionList} />

    </>
  );
}
