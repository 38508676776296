import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import Tooltip from '@mui/material/Tooltip';
import Button from 'react-bootstrap/Button';
import logo from '../../Static/images/logo.png';
import { BreadRightArrow, CateIcon, CloseIcon, DeleteIcon, EditIcon, FolderIcon, LeftArrow, NewFolderIcon, SearchesCIcon } from '../../Static/icons/iconshere';
import { usePageTitle } from '../PageTitleContext';
import axios from 'axios';
import { useSelector } from 'react-redux';
import moment from 'moment';
import NoFiles from '../NoFiles';
import Skeleton from '@mui/material/Skeleton';

const initialCategory = {
    show: false,
    name: null,
    metaData: {},
    error: null
}

const initialAsset = {
    name: null,
    show: false,
    nameError: null
}

const AssetsDashboard = () => {
    const navigate = useNavigate();
    const [newCategory, setNewCategory] = useState(initialCategory);
    const [newAsset, setNewAsset] = useState(initialAsset);
    const configData = useSelector((state) => state.configSlice);
    const userData = useSelector((state) => state.userSlice);
    const [categories, setCategories] = useState([]);
    const [assetsList, setAssetsList] = useState([]);
    const [heirarchy, setHierarchy] = useState([]);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [search, setSearch] = useState("");
    const inputRef = useRef(null);

    
    useEffect(() => {
        if(newAsset.show || newCategory.show){
            inputRef.current.focus();
            inputRef.current.select();
        }
      }, [newAsset.show, newCategory.show]);


    const closeAssetModal = () => {
        setNewAsset(initialAsset);
    };

    const closeModal = () => {
        setNewCategory(initialCategory);
    };

    const handleButtonClick = (event) => {
        event.stopPropagation();
    };

    const { id } = useParams();

    const fetchSubCategories = async (categoryId) => {
        try {
            var { data } = await axios.get(`${configData.config.UNO_URL}/api/tag/category/list/resources/${categoryId}`, {
                headers: {
                    Authorization: `Bearer ${userData.token}`
                }
            });
            setCategories(data.data.children);
            setHierarchy(data.data.heirarchy);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }

    }
    const { setPageTitle } = usePageTitle();

    const saveNewCategory = async () => {
        if(newCategory.name === null) {
            setNewCategory({...newCategory, error : "Please enter category name."})
            return
        }
        setSaving(true);
        try {
            if (newCategory.id) {
                await axios.post(`${configData.config.UNO_URL}/api/tag/category/update`, { name: newCategory.name, parentId: id, id: newCategory.id, metaData: {} }, {
                    headers: {
                        Authorization: `Bearer ${userData.token}`
                    }
                });
            } else await axios.post(`${configData.config.UNO_URL}/api/tag/category/create`, { name: newCategory.name, parentId: id, metaData: {} }, {
                headers: {
                    Authorization: `Bearer ${userData.token}`
                }
            });
            setLoading(true);
            setNewCategory(initialCategory);
            await fetchSubCategories(id);
        } catch (error) {
            console.log(error);
            setNewCategory(pre => ({...pre, error :error.response.data.message }))
        } finally {
            setSaving(false);
        }
    }
    const saveAsset = async () => {
        if(newAsset.name === null) {
            setNewAsset({...newAsset, nameError : "Please enter asset name."})
            return
        }
        setSaving(true);

        let bodyData = { 
                name: newAsset.name, 
                tags: [], 
                categoryId: parseInt(id) 
            }
        
        try {
            var { data } = await axios.post(`${configData.config.UNO_URL}/api/resource/3d/asset/create`, bodyData , {
                headers: {
                    Authorization: `Bearer ${userData.token}`
                }
            });
            setNewAsset(initialAsset);
            navigate(`/dashboard/3d/${data.data.uuid}/details`)
        } catch (error) {
            if (!error.response.data.status) setNewAsset({ show: true, nameError: error.response.data.message })
            console.log(error);
        }
        setSaving(false);
    }
    const deleteCategory = async (item) => {
        try {
            if (window.confirm("Are you sure you want to delete this folder?")) {
                setSaving(true);
                await axios.post(`${configData.config.UNO_URL}/api/tag/category/delete/${item.id}`, {}, {
                    headers: {
                        Authorization: `Bearer ${userData.token}`
                    }
                });
                setLoading(true);
                setNewCategory(initialCategory);
                await fetchSubCategories(id);
            }
        } catch (error) {
            console.log(error);
        }
        setSaving(false);
    }

    const getAssetsList = async () => {
        try {
            const { data } = await axios.get(`${configData.config.UNO_URL}/api/resource/3d/asset/list/${parseInt(id)}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userData.token}`
                }
            });
            setAssetsList(data.data);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        setPageTitle("3D Assets");
    }, []);

    useEffect(() => {
        setLoading(true);
        fetchSubCategories(id);
        getAssetsList();
    }, [id]);

    return (
        <>
            <div className='stuckhere'>
                <div className="row">
                    <div className="col-md-6">
                        <form className=" app-search app-search1 d-flex justify-content-start ">
                            <div className="position-relative">
                                <SearchesCIcon />
                                <input
                                    type="text"
                                    name="searchName"
                                    className="form-control"
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    placeholder="Search "
                                    autoComplete="off"
                                />
                            </div>
                        </form>
                    </div>

                    {  userData?.user?.permissions?.includes("CONTENT.3D.CREATE") &&
                        <div className="col-md-6  d-flex align-items-center justify-content-end mainbtns">
                            <Dropdown >
                                <Dropdown.Toggle id="dropdown-basic" className='createbtn'>
                                    Add New
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="sidebartopbar modulelistoptions">
                                    <div className="listoptionsmodule">
                                        <p onClick={() => { setNewCategory({ show: true, name: null }) }}>
                                            <span ><NewFolderIcon /></span>
                                            Add Category
                                        </p>
                                        <p onClick={() => setNewAsset({ ...initialAsset, show: true })}>
                                            <span ><CateIcon /></span>
                                            Add Asset
                                        </p>
                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    }
                </div>
                <div className='row mt-4'>
                    {heirarchy.length > 1 ? <div className='col-md-12 d-flex align-items-center justify-content-start'>
                        <div className='nav-btw'>
                            <span onClick={() => navigate(`/dashboard/3d/${heirarchy[heirarchy.length - 2].id}`)}>
                                <LeftArrow />
                            </span>
                        </div>
                        <div className='breadcrums'>
                            { heirarchy.map((item, index) => {
                                return <span onClick={() => navigate(`/dashboard/3d/${item.id}`)} key={index} className={item.id === parseInt(id) ? 'active' : null}>
                                    <Tooltip title={item.name}>
                                        <span> {item.name}</span>
                                    </Tooltip>
                                    {item.id === parseInt(id) ? null : <BreadRightArrow />} </span>
                            })}
                        </div>
                    </div> : null}
                </div>
            </div>

            <div className='px-3 environments'>

{
    loading ? (
        <div>
            <div className='col-md-12 filesndfolders assets  mt-4 px-2'>
                <h3 className='mb-3' ><Skeleton width="10vw"/></h3>
                <div className='course-tiles'>
                    <div className='tilecard' >
                        <div className='filebox'>
                                <div className='main'>
                                    <div className='icon'>
                                        <FolderIcon />
                                    </div>
                                    <span> <Skeleton width="100%" /></span>
                                </div>
                        </div>
                    </div>
                    <div className='tilecard' >
                        <div className='filebox'>
                                <div className='main'>
                                    <div className='icon'>
                                        <FolderIcon />
                                    </div>
                                    <span> <Skeleton width="100%" /></span>
                                </div>
                        </div>
                    </div>
                    <div className='tilecard' >
                        <div className='filebox'>
                                <div className='main'>
                                    <div className='icon'>
                                        <FolderIcon />
                                    </div>
                                    <span> <Skeleton width="100%" /></span>
                                </div>
                        </div>
                    </div>
                    <div className='tilecard' >
                        <div className='filebox'>
                                <div className='main'>
                                    <div className='icon'>
                                        <FolderIcon />
                                    </div>
                                    <span> <Skeleton width="100%" /></span>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <>
           {((categories.length == 0 && assetsList.length == 0) || (categories.length > 0)) &&
                <div className='col-md-12 filesndfolders assets  mt-4 px-2'>
                  
                    <h3 className='mb-3' >Categories</h3>
                  
                    {categories.length > 0 ? 
                        <>
                        <div className='course-tiles'>
                            {categories.map((item, index) => {
                                if (search.length > 0 && !item.name.toLowerCase().includes(search.toLowerCase())) return null;
                                return <div onClick={() => navigate(`/dashboard/3d/${item.id}`, { uuid: null })} key={index} className='tilecard' >
                                    <div className='filebox'>
                                        <div className='main'>
                                            <div className='icon'>
                                                <FolderIcon />
                                            </div>
                                            <Tooltip title={item.name}>
                                                <span >{item.name}</span>
                                            </Tooltip>
                                        </div>

                                        {(item.name.toLowerCase() !== "characters" && item.name.toLowerCase() !== "male" && item.name.toLowerCase() !== "female" ) &&
                                            <span className='breddrop'>
                                                <Dropdown onClick={handleButtonClick} >
                                                    <Dropdown.Toggle id="dropdown-basic">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                                            <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" /></svg>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="sidebartopbar modulelistoptions">
                                                        <div className="listoptionsmodule">
                                                            <p onClick={() => setNewCategory({ show: true, ...item })}>
                                                                <EditIcon />
                                                                Edit
                                                            </p>
                                                            <p onClick={() => deleteCategory(item)}>
                                                                <DeleteIcon />
                                                                Delete
                                                            </p>
                                                        </div>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </span>
                                        }
                                    </div>
                                </div>
                            })}
                        </div>
                        </> 
                        : 
                        <div className='No-Cat-Asset'>
                            <NoFiles  message={"No Assets or Sub Categories Found"}/>
                        </div>
                        }
                </div>
            }

                { assetsList.length > 0 &&
                  <div className='col-md-12 filesndfolders assets mt-4 px-2'>
                    <h3 className='mb-3'>Assets</h3>
                  
                     {assetsList.length > 0  ?
                    <div className='course-tiles'>
                        {assetsList.map((e, i) => {
                            if (search.length > 0 && !e.name.toLowerCase().includes(search.toLowerCase())) return null;
                            let avatar = e?.activities[0]?.users[0]?.avatar || null;
                            let userName  = e?.activities[0]?.users[0]?.name || null;
                            let name  = userName ? userName.split(" ").slice(0,2).map(e => e.split("")[0]).join("").toUpperCase() : "";

                            return (
                                <div className='tilecard' key={e.id}>
                                    <div className='assetsbox' onClick={() => navigate(`/dashboard/3d/${id}/${e.uuid}`)} >

                                        <div className='preview'>
                                            <img src={e.thumbnail || logo}
                                                onError={(e) => {
                                                    e.target.src = {logo};
                                                    e.onerror = null;
                                                }}
                                                alt='no-referrer'
                                                referrerPolicy={'no-referrer'}
                                            />
                                        </div>

                                        <div className='details'>
                                            <Tooltip title={e.name}>
                                                <p>{e.name}</p>
                                            </Tooltip>
                                            <p>
                                                <Tooltip title={"Last updated at "+moment(e.updatedAt).format("lll")}>
                                                    {moment(e.createdAt).fromNow()}
                                                </Tooltip>
                                                <Tooltip title={ e?.activities[0]?.users[0]?.name || ""}>
                                                    <span className='placeholder_'>
                                                         {avatar ?
                                                            <img alt='' src={avatar} referrerPolicy={'no-referrer'} />
                                                            :
                                                            name
                                                          }
                                                        </span>
                                                </Tooltip>
                                            </p>
                                        </div>

                                    </div>
                                </div>
                            )
                        })}

                    </div>
                    :

                    <div className='No-Cat-Asset'>
                              
                                <NoFiles message={"No Assets or Sub Categories Found"} />
                       
                        </div>
                    }
                  </div> 
                }
        </>
    )
}

            



            </div>

            <Modal show={newCategory.show} size='md' centered onHide={() => closeModal()}>
                <Modal.Header className='modalheader'>
                    {newCategory.id ? "Update Category" : "Add New Category"}
                    <span onClick={() => closeModal()} ><CloseIcon /></span>
                </Modal.Header>
                <Modal.Body className='pb-0'>
                    <div className='row'>
                        <div className='col-12 standardinput' >
                            <label>
                                Category Name
                                <input ref={inputRef} value={newCategory.name} onChange={(e) => setNewCategory({ ...newCategory, name: e.target.value, error : null })} autoComplete="off" name="moduleName" id="moduleName" className="input form-control" type="text" />
                                <p className='error'> {newCategory.error} </p>
                            </label>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className='footerbtn'>
                    <Button variant="secondary" onClick={closeModal}> Cancel </Button>
                    <Button disabled={saving} variant="primary" onClick={saveNewCategory} > Save </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={newAsset.show} size='md' centered onHide={() => closeAssetModal()}>
                <Modal.Header className='modalheader'>
                    New Asset
                    <span onClick={() => closeAssetModal()} ><CloseIcon /></span>
                </Modal.Header>
                <Modal.Body className='pb-0'>
                    <div className='row'>
                        <div className='col-12 standardinput' >
                            <label>
                                File Name
                                <input ref={inputRef} autoComplete="off" value={newAsset.name} onChange={(e) => setNewAsset({ ...newAsset, name: e.target.value, nameError: null })} name="moduleName" id="moduleName" className="input form-control" type="text" />
                                <p className='error'>{newAsset.nameError}</p>
                            </label>
                        </div>
                        
                    </div>
                </Modal.Body>
                <Modal.Footer className='footerbtn'>
                    <Button variant="secondary" onClick={closeAssetModal}> Cancel </Button>
                    <Button disabled={saving} variant="primary" onClick={() => saveAsset()}> Save </Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default AssetsDashboard;