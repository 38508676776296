import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { CloseIcon } from '../../../Static/icons/iconshere';
import { debounce } from '../../../utils/useWindowDimensions';
import { BtnLoading } from '../../../animations/BtnLoading';

export const CreateSectionModal = ({ showCreateModal, handleCloseModal, getSectionList}) => {
    const [moduleName , setModuleName] = useState("");
    const [error , setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const configData = useSelector((state) => state.configSlice);
    const userData = useSelector((state) => state.userSlice);

    useEffect(()=>{
        setModuleName(showCreateModal.name);
    },[showCreateModal])

    const handleCreateModule = async() =>{ 
    try {
        setError(null);
        if(moduleName.trim().length === 0){
            setError("kindly fill name");
            setLoading(false);
            return
        }
        let _bodyData = {
            name : moduleName.trim()
        }

        await axios.post(`${configData.config.UNO_URL}/api/resource/2d/lms/${showCreateModal.action}/${showCreateModal.type}/${showCreateModal.resourceId}`, _bodyData, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userData.token}`
            }
            });
            
            getSectionList();
            closeModal();
        
        }catch (error) {
        console.log(error);
        setError(error?.response?.data?.message || "something went wrong")
        }finally{
            setLoading(false);
            setTimeout(()=>{
                setError(null);
            },2500)
        }
        
    }
    
    const closeModal = () =>{
        setError(null);
        setLoading(false);
        setModuleName("");
        handleCloseModal();
        }

    const debounceHandleSaveReview = debounce(handleCreateModule, 1000, setLoading);

    return (
        <>
            <Modal show={showCreateModal.status} onHide={closeModal} className="NewModule" centered size="md">
                    <Modal.Header className='modalheader'>
                    {showCreateModal.modal} {showCreateModal.type}  <span onClick={closeModal} ><CloseIcon/></span> </Modal.Header>
                    <Modal.Body>
                        <div className='container-fluid'>
                            <div className='row'>

                                <div className='col-12 mb-2'>
                                    <div className="form-group">
                                        <label className="label">Name</label>
                                        <input autoComplete="off" placeholder='Name' name="moduleName" id="moduleName" value={moduleName} onChange={(e) => setModuleName(e.target.value)} className="input form-control" type="text" />
                                    </div>
                                    {error && <p className='errorMessage' >{error}</p>}
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='footerbtn pt-0'>
                            <Button onClick={closeModal}> Cancel </Button>
                            {loading ? 
                             <Button > <BtnLoading /> </Button>
                              : 
                            <Button onClick={debounceHandleSaveReview}> Save </Button>
                             }
                    
                    </Modal.Footer>
                </Modal>
        
        </>
    )
}

