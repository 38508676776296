import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Select from "react-select";
import { FileUploader } from 'react-drag-drop-files';
import { DummyVersions } from './DummyVersion';
import { BreadRightArrow, CancelIcon, CloseIcon, DownloadIcon, FolderIcon, LeftArrow, SortIcon, UpDownIcon } from '../../Static/icons/iconshere';
import {
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@mui/material";
import axios from 'axios';
import Tooltip from '@mui/material/Tooltip';
import { useSelector } from 'react-redux';
import { assetsTagLists, createNewAssetTags } from '../utils';
import { Download } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { convertFileSize, threeDAssetType } from '../../utils/constants';
import moment from 'moment';
import { BtnLoading } from '../../animations/BtnLoading';
import { debounce } from '../../utils/useWindowDimensions';
const fileTypes = ['png', 'jpeg', 'jpg'] // Use MIME type for PNG images

const inistialState = { upload: false, progress: 100, url: null };

const AssetDetails = () => {
  const [addCate, setAddCate] = useState(false);
  const [thumbnail, setThumbnail] = useState(inistialState);
  const params = useParams();
  const { id } = params;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [assetType, setAssetType] = useState(threeDAssetType);
  const [name, setName] = useState("");
  const configData = useSelector((state) => state.configSlice)
  const userData = useSelector((state) => state.userSlice);
  const tagsData = useSelector((state) => state.tagsSlice);
  const [currentTab, setCurrentTab] = useState(0);
  const [childTab, setChildTab] = useState(0);
  const [selectedAssetTag, setSelectedAssetTag] = useState([]);
  const [selectTag, setSelectTags] = useState([]);
  const [versionList, setVersionList] = useState([]);
  const [levelName, setLevelName] = useState(null);
  const [assetUploadText, setAssetUploadText] = useState("Save");

  const [error, setError] = useState({
    thumbnail: null,
    name: null,
    tags: null,
  });

  const assetTagsList = () => {
    let _data = assetsTagLists();
    setSelectTags(_data);
  }

  useEffect(() => {
    assetTagsList()
  }, [tagsData.assetTagsList])

  const getModuleVersionList = async () => {
    setVersionList([])
    axios.get(`${configData.config.UNO_URL}/api/resource/3d/asset/list/versions/${assetType[currentTab].key}/${id}`, {
      headers: {
        Authorization: `Bearer ${userData?.token}`,
        "Content-Type ": "application/json"
      }
    }).then((res) => {
      setVersionList(res?.data?.data);
    }).catch((err) => {
      console.log(err);
    })
  }

  useEffect(() => {
    setLevelName(null);
    getModuleVersionList();
  }, [assetType[currentTab].key])

  const getBasicDetails = async () => {
    try {
      let { data } = await axios.get(`${configData.config.UNO_URL}/api/resource/3d/asset/details/${id}`, {
        headers: {
          "Content-Type ": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        }
      });
      const assetData = data.data[0];
      setName(assetData.name)
      let tags = assetData.tags.map((e) => ({
        categoryId: e.categoryId,
        label: e.name,
        value: e.id
      }))
      setSelectedAssetTag(tags)
      setThumbnail({ uploaded: true, url: assetData.thumbnail });

    } catch (error) {
      console.log(error);
    }
  }

  const checkValidation = (name, thumbnail, tags) => {
    let validate = true;

    if (name.trim() == "") {
      validate = false;
      setError(pre => ({ ...pre, name: "Name is required." }))
    }
    if (thumbnail == null || thumbnail == "") {
      validate = false
      setError(pre => ({ ...pre, thumbnail: "Thumbnail is required." }))
    }
    // if (tags.length == 0) {
    //   validate = false
    //   setError(pre => ({ ...pre, tags: "Tags are required." }))
    // }

    return validate;

  }

  const detailsSave = async () => {
    setError({
      thumbnail: null,
      name: null,
      tags: null,
    });

    let filter = assetType[currentTab].types[childTab].types.map((e,i)=> ({level : e.name, url : e.url }));
    const filledData = filter.map((item, index, array) => ({
      type  : item.level,
      url: item.url || array.find(otherItem => otherItem.url !== null)?.url || null
    }));
  
    const assetDetails = {
      name: name,
      tags: selectedAssetTag.map((e) => e.value),
      thumbnail: thumbnail.url,
      assetFilterId: configData.config.assetFilterId,


      platform: assetType[currentTab].key,
      type: assetType[currentTab].types[childTab].key,
      assets: filledData
    }
    
    if (checkValidation(assetDetails.name, assetDetails.thumbnail, assetDetails.tags)) {
      try {
        await axios.post(`${configData.config.UNO_URL}/api/resource/3d/asset/save/${assetType[currentTab].key}/${id}`, assetDetails, {
          headers: {
            "Content-Type ": "application/json",
            Authorization: `Bearer ${userData?.token}`,
          }
        });
        window.location.reload();
        setAssetType(threeDAssetType);
      } catch (error) {
        console.log(error)
      }
      
    }else{
      setLoading(false)
    }  
  }

  useEffect(() => {
    getBasicDetails();
  }, [id])

  const handleFileUpload = async (files) => {
    setThumbnail({ ...thumbnail , upload: true })
    const uploadedFile = files;
    if (uploadedFile) {
      const formData = new FormData();
      formData.append('file', uploadedFile);
      var response = await axios.post(`${configData.config.UNO_URL}/api/resource/upload/temp`, formData, {
        headers: {
          Authorization: `Bearer ${userData?.token}`,
          "Content-Type ": "multipart/form-data"
        }
      });
      setThumbnail({ ...thumbnail, upload: false, url: response.data.url })
    }
  };

  const openCateModal = (name) => {
    setLevelName(name);
    setAddCate(true)
  }

  const closeCateModal = () => {
    setLevelName(null);
    setAddCate(false)
  }

  const handleCancel = () => {
    setThumbnail(inistialState);
  };

  const handleChangeUploadFile = async (file, index) => {
    try {
      const uploadedFile = file;
      const formData = new FormData();
      formData.append('file', uploadedFile);
      setAssetType(prevState => {
          const newState = JSON.parse(JSON.stringify(prevState));
          newState[currentTab].types[childTab].types[index].loading = true;
          newState[currentTab].types[childTab].types[index].fileName = uploadedFile.name;
          newState[currentTab].types[childTab].types[index].fileSize = uploadedFile.size;
          return newState;
      });

      let { data } = await axios.post(`${configData.config.UNO_URL}/api/resource/3d/asset/upload/${assetType[currentTab].key}/${id}`, formData, {
          headers: {
            Authorization: `Bearer ${userData?.token}`,
            "Content-Type": "multipart/form-data"
          }
        });

        setAssetType(prevState => {
          let modifyAssetType = JSON.parse(JSON.stringify(prevState));
          modifyAssetType[currentTab].types[childTab].types[index].url =  data.url;
          modifyAssetType[currentTab].types[childTab].types[index].fileName =  uploadedFile.name;
          modifyAssetType[currentTab].types[childTab].types[index].fileSize =  uploadedFile.size;
          modifyAssetType[currentTab].types[childTab].types[index].loading =  false;
          return modifyAssetType;
        });

    } catch (error) {
      console.log(error)
    } finally {
    }
  };

  const handlePlatformChange = (e) => {
    setChildTab(0);
    setCurrentTab(e);
    setAssetType(threeDAssetType);
  }

  const handleChildTabChange = (e) => {
    setChildTab(e.target.value);
    setAssetType(threeDAssetType);
  }

  const cancelAssetsUpload = (index) =>{
    let modifyAssetType = JSON.parse(JSON.stringify(assetType));
    modifyAssetType[currentTab].types[childTab].types[index].url =  null
    modifyAssetType[currentTab].types[childTab].types[index].file =  {}
    modifyAssetType[currentTab].types[childTab].types[index].loading =  false
    setAssetType(modifyAssetType);
  }

function downloadFile(url) {
  const link = document.createElement('a');
  link.href = url;
  link.target = "_blank";
  link.setAttribute('download', true);
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

  const handleDownloadFile = (file) =>{
      let fileUrl = file.data.assets.filter((e) => e.type.toLowerCase() === (levelName?.toLowerCase() || levelName) )[0]?.url || null;
      fileUrl && downloadFile(fileUrl)
  }

const debounceHandleSaveReview = debounce(detailsSave, 1000, setLoading);


let filterList = versionList.filter((e) => ((e.data.type == assetType[currentTab].types[childTab].key) &&  (e.data.platform == assetType[currentTab].key) && (levelName ? e.data.assets.filter((e) => e.type.toLowerCase() === levelName.toLowerCase() ) : true ) ) );
  return (
    <>
      <div className="envdetails detailspage container-flid" style={{ maxWidth: "1500px" }}>
        <div className="mainbox mt-1">
          <div className="row mt-3">
            <div className="col-xl-4 ">

              <div className="form-group ">
                <label className="mb-3 flex-row" htmlFor="exampleFormControlInput1" >
                  File Thumbnail <span>*</span>
                </label>

                <div className="img-view">
                  { thumbnail.url ? (
                    <>
                      <div className='previewimg'  >
                        <img src={thumbnail.url} alt="" />
                        <span onClick={handleCancel} className="canceldelete">
                          <CancelIcon />
                        </span>
                      </div>
                    </>
                  ) : (
                    <div className='fileuploader'>
                      <FileUploader
                        name="thumbnailInput"
                        types={['png', 'jpeg', 'jpg']}
                        label="drag & drop or choose file to upload"
                        handleChange={handleFileUpload} // Use onChange instead of onDrop
                      />
                    </div>

                  )}

                </div>

                <div className="uploadpromt">
                  Image Ratio 1:1<br/>
                  File format accepted: PNG (Preferably without background)
                  <br />
                  {
                    thumbnail?.upload ? (
                      <div className="uploadProgressBar">
                        <div className="progress mt-2" style={{ height: '10px' }}>
                          <div className="progress-bar progress-bar-striped progress-bar-animated bg-danger" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{ width: `${thumbnail.progress}%`, height: '10px' }}></div>
                        </div>
                        <p>Uploading<span className="dot-animation"></span></p>
                      </div>
                    )
                      :
                      ("")
                  }


                </div>
                <p className='error'>{error.thumbnail}</p>
              </div>

              <div className="form-group mt-0">
                <label className=' flex-row' htmlFor="exampleFormControlInput1">Name <span>*</span></label>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="form-control mt-2"
                  id="exampleFormControlInput1"
                  placeholder="Enter File Name"
                />
                <p className='error'>{error.name}</p>

              </div>

              <div className="form-group mt-0">
                <label htmlFor="exampleFormControlInput1">Tags</label>


                <Select
                  isMulti
                  name="jobRoles"
                  className="basic-multi-select mt-2"
                  classNamePrefix="select"
                  placeholder="Select Tags"
                  options={selectTag}
                  value={selectedAssetTag}
                  onChange={setSelectedAssetTag}
                  components={{ NoOptionsMessage: (props) => createNewAssetTags({ ...props, customName: "Asset Tag" }) }}

                />
                <p className='error'>{error.tags}</p>
              </div>              
            </div>

            <div className="col-xl-8">

              <div className="popupselect fortype">
                <p>Platform</p>
                <div className="radio-inputs">
                  <FormControl fullWidth className="mb-3 d-flex align-items-start" >
                    <RadioGroup
                      row
                      aria-labelledby="price-visibility-label"
                      name="questionType"
                      className="gap-3"
                      value={currentTab}
                      onChange={(e) => handlePlatformChange(e.target.value)}
                    >

                      {assetType.map((element, index) => {
                        return (
                          <FormControlLabel
                            key={index}
                            className="quizlabel"
                            value={index}
                            control={<Radio />}
                            label={element.name}
                          />
                        )
                      })}
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>


              {assetType[currentTab] ?
                <div className="popupselect fortype">
                  <p>Asset Type</p>
                  <div className="radio-inputs">
                    <FormControl fullWidth className="mb-3 d-flex align-items-start" >
                      <RadioGroup
                        row
                        aria-labelledby="price-visibility-label"
                        name="questionType"
                        className="gap-3"
                        value={childTab}
                        onChange={handleChildTabChange}
                      >
                        {assetType[currentTab].types.map((e, index) => {
                          return (
                            <FormControlLabel
                              key={index}
                              className="quizlabel"
                              value={index}
                              control={<Radio />}
                              label={e.name}
                            />)

                        })}

                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
                : null}


              {assetType[currentTab] && assetType[currentTab].types[childTab] && assetType[currentTab].types[childTab].types.length ?
                (assetType[currentTab].types[childTab].types.map((e, index) => {
                  return (
                    e.loading ?
                    <div key={index} className="DragDropMenu uploadfilesss for3dassets mt-0">
                    <div className='fileuploader mb-3'>
                      <div className='filetag'>
                      {e.name}
                      </div>
                      <label className='assets-upload'>
                        <div className='uploadstatus'>
                          <div className='upperdiv'>
                            <div className='uploaddata'>
                              <div>
                                <span className='stats withname'>
                                  <p> {e.fileName} </p>
                                </span>
                                <span className='stats withname'> <p> {convertFileSize(e.fileSize)} </p> </span>
                              </div>
                              <div className="uploadProgressBar">
                                <div className="progress mt-2" style={{ height: '10px' }}>
                                  <div className="progress-bar progress-bar-striped progress-bar-animated bg-danger" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{ width: "100%", height: '10px' }}></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </label>
                    </div>
                     </div>
                      :
                    e.url ?
                    <div key={index} className="DragDropMenu uploadfilesss for3dassets mt-0">
                    <div className='fileuploader mb-3'>
                      <div className='filetag'>
                      {e.name}
                      </div>
                      <label className='assets-upload'>
                        <div className='uploadstatus'>
                          <div className='upperdiv'>
                            <div className='uploaddata'>
                              <div>
                                <span className='stats withname'>
                                  <p> {e.fileName} </p>
                                  <span onClick={()=>cancelAssetsUpload(index)} >
                                    <CancelIcon />
                                  </span>
                                </span>
                                <span className='stats withname'> <p> {convertFileSize(e.fileSize)} </p> </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </label>
                    </div>
                     </div>
                      :
                    <div key={index} className="DragDropMenu uploadfilesss for3dassets mt-0">
                      <div className='fileuploader  mb-3'>
                        <div className='filetag'>
                          {e.name}
                        </div>
                        <FileUploader
                          handleChange={(file) => handleChangeUploadFile(file, index)}
                          label={"drag & drop or choose file to upload"}
                          name="file"
                        />
                        <span className='filetag version' onClick={()=>openCateModal(e.name)}>
                         Version history
                        </span>
                      </div>
                    </div>
                    
                  );
                }
                ))
                : null}

            </div>

          </div>

        </div>
        {userData?.user?.permissions?.includes("CONTENT.3D.CREATE") &&
          <div className="col-md-12 pe-2 mt-4">
            <div className="footerbtn text-end">
              <button onClick={() => {setAssetType(threeDAssetType); navigate(-1) }}>Cancel</button>
              <button onClick={debounceHandleSaveReview}>
                {
                  loading? <BtnLoading/> : "Save"
                }
              </button>
            </div>
          </div>
        }
      </div>

      <Modal show={addCate} size='lg' centered onHide={() => closeCateModal()}>
        <Modal.Header className='modalheader'>
          Version History
          <span style={{ cursor: "pointer" }} onClick={() => closeCateModal()} ><CloseIcon /></span>
        </Modal.Header>
        <Modal.Body className='pb-0'>
          <div className='row'>
            <div className='col-md-12'>
              <span className='version-tag'> {assetType[currentTab].name} <BreadRightArrow /> {assetType[currentTab].types[childTab].name} <BreadRightArrow /> {levelName} </span>
            </div>
            <div className="table-responsive workflowtb mt-2 stickyhead ">
              <table className="table align-middle table-nowrap mb-0">
                <thead>
                  <tr>
                    <th className="text-center">Version Name</th>
                    <th className="text-center">Type</th>
                     <th className="text-center" style={{ cursor: "pointer" }}>Timestamp  {/* <UpDownIcon /> */}</th> 
                    <th>Uploaded By</th>
                  </tr>
                </thead>
                <tbody className='versiontable'>
                  {filterList.map((item, index) =>{
                    let avatar = item?.activities[0]?.users[0].avatar || "";
                    return(
                    <tr key={index}>
                      <td className='text-center versiontag' onClick={()=>handleDownloadFile(item)}>  {item.version.toString()} </td>
                      <td className="text-center"> {item.data.type} </td>
                      <td className="text-center"> { moment(item.createdAt).format("lll") }</td>

                      <td className="d-flex justify-content-start gap-2 align-items-center" style={{fontSize:"14px"}}>
                              <span className='placeholder_'>
                                    {avatar && <img alt='' src={avatar} referrerPolicy={'no-referrer'} />}
                              </span>
                              {item.activities[0].users[0].name}
                      </td>

                    </tr>
                    )
                   }
                  
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
      </Modal>

    </>
  );
};

export default AssetDetails;
