import { createSlice} from "@reduxjs/toolkit";

let initialState = {
    allModulesList:[]
}

const moduleSlice = createSlice({
    name:"moduleSlice",
    initialState,
    reducers:{
        addModules:(state,action)=>{
           state.allModulesList = action.payload
        },
        removeModuleList:(state)=>{

        }
    }
});

export const {  addModules , removeModuleList }  =  moduleSlice.actions;
export default moduleSlice.reducer;