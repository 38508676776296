import { addModuleXRTags, addAssetTags, addSkillStream2DTags } from '../../store/slice/tagsSlice';
import { addModules } from '../../store/slice/moduleSlice';
import axios from 'axios';
import { store } from '../../store/store';
import { assetFilterId } from '../../env';

export const getAllXRModuleTags = async () => {
         try {
             let _user = store.getState().userSlice;
             let _config = store.getState().configSlice;
     
             let moduleFilterId = _config?.config?.moduleFilterId;
             let UNO_URL = _config?.config.UNO_URL;
     
            let {data}= await axios.get(`${UNO_URL}/api/tag/list/${moduleFilterId}`, {
                 headers: {
                     Authorization: `Bearer ${_user?.token}`
                 }
             });

            store.dispatch(addModuleXRTags(data.data))
            
         } catch (error) {
             console.log(error);
         }

}

export const getAllSkillStream2DTags = async () => {
         try {
             let _user = store.getState().userSlice;
             let _config = store.getState().configSlice;
     
             let skillStream2DFilterId = _config?.config?.skillStream2DFilterId;
             let UNO_URL = _config?.config.UNO_URL;
     
            let {data}= await axios.get(`${UNO_URL}/api/tag/list/${skillStream2DFilterId}`, {
                 headers: {
                     Authorization: `Bearer ${_user?.token}`
                 }
             });

            store.dispatch(addSkillStream2DTags(data.data))
            
         } catch (error) {
             console.log(error);
         }

}

export const getAssetTagsList = async (assetFilterId) => {
    try {
        let _user = store.getState().userSlice;
        let _config = store.getState().configSlice;
        let UNO_URL = _config?.config.UNO_URL;

         
        let {data} = await axios.get(`${UNO_URL}/api/tag/list/${assetFilterId}`, {
            headers: {
                Authorization: `Bearer ${_user?.token}`
            }
        })
        store.dispatch(addAssetTags(data.data));
               
    } catch (error) {
         console.log(error);
    }
}

export const assetsTagLists = () => {
    let _assetTags = store.getState().tagsSlice?.assetTagsList?.tags?.map(tag => ({
        label: tag.name,
        value: tag.id,
        categoryId: tag.categoryId
    }));
    return _assetTags ? _assetTags : []
}

export const getAllModuleList = async () => {

        let _user = store.getState().userSlice;
        let _config = store.getState().configSlice;
        let UNO_URL = _config?.config.UNO_URL;

        axios.get(`${UNO_URL}/api/resource/module/list`, {
            headers: {
                Authorization: `Bearer ${_user?.token}`
            }
        })
            .then((response) => {
                store.dispatch(addModules(response.data.data))
            })
            .catch((error) => {
                console.log(error);
            })


}

export const createNewCustomTags = (props) => {
    const { innerProps, selectProps, customName } = props;

    const handleCreateNewTag = () => {
        let UNO_URL = store.getState().configSlice?.config.UNO_URL;
        const _findCategory = store.getState().tagsSlice?.allTagsList?.children?.find(item => item.name === `${customName}`);
        let _token = store.getState().userSlice.token
        let createTag = {
            "name": selectProps.inputValue,
            "categoryId": _findCategory.id
        }

        axios.post(`${UNO_URL}/api/tag/create`, createTag, {
            headers: {
                Authorization: `Bearer ${_token}`
            }
        })
            .then((res) => {
                getAllXRModuleTags();
            })
            .catch((err) => {
                console.log(err);
            })
    };

    return (
        <>
            <div className='nodatafoundbtn' {...innerProps}>
                <p>No options found.</p>
                <button onClick={handleCreateNewTag}>Create This Tag</button>
            </div>
        </>
    );
};

export const createNewAssetTags = (props) => {
    const { innerProps, selectProps, customName } = props;

    
    const handleCreateNewTag = () => {
        let id = store.getState().tagsSlice?.assetTagsList.id;
        let UNO_URL = store.getState().configSlice?.config.UNO_URL;
        let _token = store.getState().userSlice.token
        let createTag = {
            "name": selectProps.inputValue,
            "categoryId": id
        }
        axios.post(`${UNO_URL}/api/tag/create`, createTag,{
            headers: {
                Authorization: `Bearer ${_token}`
            }
        }).then((res) => {
                getAssetTagsList();
            })
            .catch((err) => {
                console.log(err);
            })
    };

    return (
        <>
            <div className='nodatafoundbtn' {...innerProps}>
                <p>No options found.</p>
                <button onClick={handleCreateNewTag}>Create This Tag</button>
            </div>
        </>
    );
};

export const moduleTagCategoryWithTags = () => {

    const result = { categories: [] };

    const categoriesConfig = [
        { name: 'Sectors', key: '_sectorsList' },
        { name: 'Packages', key: '_packagesList' },
        { name: 'Job Roles', key: '_jobRolesTags' },
        { name: 'Languages', key: '_languagesTags' },
        { name: 'Difficulty Level', key: '_difficultyLevelTags' },
        { name: 'Validated By', key: '_ValidatedByLevelTags' },
    ];

    const allTagCategoryList = store.getState().tagsSlice?.allTagsList?.children || [];

    categoriesConfig.forEach(({ name, key }) => {
        const category = allTagCategoryList.find(item => item.name === name);

        result[key] = category?.tags?.map(tag => ({
            label: tag.name,
            value: tag.id,
            categoryId: category.id,
        })) || [];
        
        result.categories.push(category);

    });

    return result;

}


export const skillStream2DTagCategoryWithTags = () => {

    const result = { categories: [] };

    const categoriesConfig = [
        { name: 'Category', key: '_categoryLevelTags' },
    ];

    const allTagCategoryList = store.getState().tagsSlice?.skillStream2D?.children || [];

    categoriesConfig.forEach(({ name, key }) => {
        const category = allTagCategoryList.find(item => item.name === name);

        result[key] = category?.tags?.map(tag => ({
            label: tag.name,
            value: tag.id,
            categoryId: category.id,
        })) || [];
        
        result.categories.push(category);

    });

    return result;
}
