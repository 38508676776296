import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { EnvIcon, LMSIcon, ThreeDIcon, XRIcons } from '../../Static/icons/iconshere';

const Sidebar = ({ sideBar, setSideBar }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentLocation = useLocation();
  const currentPath = currentLocation.pathname;
  const [permissionList, setPermissionList] = useState([]);
  const userData = useSelector((state) => state.userSlice);
  const configData = useSelector((state) => state.configSlice);
  const [activeTab, setActiveTab] = useState('xr');

  useEffect(() => {
    setPermissionList(userData?.user?.permissions);
  }, [userData]);

  useEffect(() => {
    if (currentPath.startsWith('/dashboard/xr')) setActiveTab('xr');
    else if (currentPath.startsWith('/dashboard/environments')) setActiveTab('environments');
    else if (currentPath.startsWith('/dashboard/3d')) setActiveTab('assets');
    else if (currentPath.startsWith('/dashboard/2d/lms')) setActiveTab('lms');
  }, [currentPath]);

  return (
    <div className='mainside'>
      <div className={sideBar ? 'sidmenu active' : 'sidmenu'}>
        <div className='mainsidelist'>
          <ul>
            {permissionList.includes("CONTENT.XR") &&
              <>
                <li className={`${activeTab === 'xr' ? 'active' : ''}`} onClick={() => navigate("/dashboard/xr")}>
                  <XRIcons /><p className='ms-3'>XR Modules</p>
                </li>
              </>
            }

            {
              permissionList.includes("CONTENT.3D") &&
                <li onClick={() => navigate(`/dashboard/3d/${configData.config["3dAssetsId"]}`)} className={`${activeTab === 'assets' ? 'active' : ''}`}>
                  <ThreeDIcon /><p className='ms-3'>3D Assets</p>
                </li>
            }

            {permissionList.includes("CONTENT.ENVIRONMENT") &&
                <li onClick={() => navigate("/dashboard/environments")} className={`${activeTab === 'environments' ? 'active' : ''}`}>
                  <EnvIcon /><p className='ms-3'>Environments</p>
                </li>
            }

          {permissionList.includes("CONTENT.2D") &&
            <li onClick={() => navigate("/dashboard/2d/lms")} className={`${activeTab === 'lms' ? 'active' : ''}`}>
                  <LMSIcon/><p className='ms-3'>2D LMS</p>
            </li>
          }

            {/*  <li>
                <i className="bi-collection"></i>
                <p>3d Assets</p>
                </li> */}

          </ul>
        </div>
      </div>
    </div>
  )
}

export default Sidebar