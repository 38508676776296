import React, { useEffect, useState } from "react";
import {
  useParams,
  useNavigate,
} from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { CompletedIcon, FailedIcon } from "../../Static/icons/iconshere";

const DeployPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;
  const configData = useSelector((state) => state.configSlice);
  const userData = useSelector((state) => state.userSlice);
  const [allChannelList, setAllChannelList] = useState([]);
  const [operations, setOperations] = useState([]);
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);



  const getAllUpdateStatus = async () => {
    try {
      var { data } = await axios.get(`${configData.config.UNO_URL}/api/resource/sync/status/${id}`,
        {
          headers: {
            Authorization: `Bearer ${userData?.token}`,
            "Content-Type ": "application/json",
          },
        });
      setOperations(data.data.data)
      if (!["COMPLETED", "FAILED"].includes(data.data.status)) {
        setTimeout(() => getAllUpdateStatus(), 1000)
      }
    } catch (err) {
      console.log(err);
    }
  };


  const getAllChannelList = async () => {
    try {
      var response = await axios.get(`${configData.config.UNO_URL}/api/channel/learn/list`, {
        headers: {
          Authorization: `Bearer ${userData?.token}`,
          "Content-Type ": "application/json"
        }
      });
      let channelList = response?.data?.data || [];
      setAllChannelList(channelList);
      getAllUpdateStatus()
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getAllChannelList();
  }, []);



  return (
    <div className="container-fluid" data-aos="fade-left">

      <div className="headdetail pb-2">
        <p className="backcrum" onClick={() => navigate(-1)}>
          <i className="bi-chevron-left"></i> Back
        </p>
        {/* <span className="pagetitle">Spark Plug</span> */}
      </div>
      <div className="row">
        <div className="col-12">
          <table className="table deploytable">
            <thead>
              <tr>
                <th scope="col">Actions</th>
                <th></th>
                <th scope="col" className="text-center">
                  Status
                </th>
              </tr>
            </thead>

            <tbody>

              {operations.length > 0 ?
                operations?.map((e, i) => {
                  return (
                      <tr key={i} className={e?.status == "INQUEUE" ? "waiting" : e?.status == "INPROGRESS" ? "inprocess" : e?.status == "COMPLETED" ? "completed" : e?.status == "FAILED" ? "failed" : null}>
                        <td>
                          Syncing {e?.type == "MODULE.BASIC.SYNC" ? "Basic Details on channel " :
                            e?.type == "MODULE.TASKS.SYNC" ? "Module Tasks on channel " :
                              e?.type == "MODULE.CRITERIA.SYNC" ? "Passing Criteria on channel " :
                                e?.type == "MODULE.QUIZ.SYNC" ? "Quiz on channel " :
                                  e?.type == "MODULE.SOP.SYNC" ? "SOPs on channel " :
                                    e?.type == "MODULE.ASSET.SYNC" && e?.data?.type == "MODULE.ASSET.MOBILE" ? "Mobile Assets on channel " :
                                      e?.type == "MODULE.ASSET.SYNC" && e?.data?.type == "MODULE.ASSET.DESKTOP" ? "Desktop Assets on Channel " :
                                        e?.type == "MODULE.ASSET.SYNC" && e?.data?.type == "MODULE.ASSET.DESKTOP.VR" ? "Desktop VR Assets on Channel " :
                                          e?.type == "MODULE.ASSET.SYNC" && e?.data?.type == "MODULE.ASSET.STANDALONE.VR" ? "Standalone VR Assets on Channel " :
                                            e?.type == "MODULE.ASSET.SYNC" && e?.data?.type == "MODULE.ASSET.WEB" ? "WebGL Assets on Channel " :
                                              null
                          }

                          {allChannelList.filter((f) => f.id == e.channel)[0].name}

                        </td>

                        <td>

                          {e?.status == "INQUEUE" ?
                            <div className="loader">
                              <span className="hour"></span>
                              <span className="min"></span>
                              <span className="circel"></span>
                            </div>
                            :
                            e?.status == "INPROGRESS" ?
                              <div className="loader">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 512 512"
                                >
                                  <path d="M64 32C28.7 32 0 60.7 0 96v64c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm280 72a24 24 0 1 1 0 48 24 24 0 1 1 0-48zm48 24a24 24 0 1 1 48 0 24 24 0 1 1 -48 0zM64 288c-35.3 0-64 28.7-64 64v64c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V352c0-35.3-28.7-64-64-64H64zm280 72a24 24 0 1 1 0 48 24 24 0 1 1 0-48zm56 24a24 24 0 1 1 48 0 24 24 0 1 1 -48 0z"></path>
                                </svg>
                                <span></span>
                              </div>
                              :
                              e?.status == "COMPLETED" ?
                                <CompletedIcon />
                                :
                                e?.status == "FAILED" ?
                                  <FailedIcon />
                                  :
                                  ""
                          }
                        </td>


                        <td className="text-center">
                          {e.status}
                        </td>

                      </tr>


                  )
                })
                :
                null
              }


            </tbody>
          </table>
        </div>
      </div>

    </div>
  );
};

export default DeployPage;
