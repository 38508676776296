import React, { useState, useEffect } from 'react';
import "aos/dist/aos.css";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Tooltip from '@mui/material/Tooltip';
import { SearchesCIcon } from '../../Static/icons/iconshere';
import logo from '../../Static/images/logo.png';
import { usePageTitle } from '../PageTitleContext';
import Create2DLmsModal from './Modal/create2DLmsModal';
import { skillStream2DTagCategoryWithTags } from '../utils';


const LMS2DList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const configData = useSelector((state) => state.configSlice);
    const userData = useSelector((state) => state.userSlice);
    const [search, setSearch] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [list, setList] = useState([]);
    const tagsData = useSelector((state) => state.tagsSlice);
    const [tagCategories, setTagCategories] = useState([]);


    const { setPageTitle } = usePageTitle();
    
    const get2DLmsModuleList = async() =>{
         try {

            let { data } = await axios.get(`${configData.config.UNO_URL}/api/resource/2d/lms/list`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userData.token}`
                }
            });

            setList(data.data);
         } catch (error) {
            console.log(error);
         }
    }

    useEffect(() => {
        if (tagsData?.skillStream2D?.name) {
            let _data = skillStream2DTagCategoryWithTags();
             setTagCategories([ _data._categoryLevelTags[0].categoryId ]);
        }
    }, [tagsData])

    useEffect(() => {
        setPageTitle("2D LMS");
        get2DLmsModuleList();
    }, []);


    return (
        <>
            <div className='stuckhere modules'>
            <div className='row'>
            <div className="col-md-6">
                <form className=" app-search app-search1 d-flex justify-content-start ">
                    <div className="position-relative">
                    <SearchesCIcon/>
                    <input placeholder="Search Modules...."   className="form-control" type="text" value={search} onChange={(e) => setSearch(e.target.value)} />
                    </div>
                </form>
            </div>
            <div className="col-md-6  d-flex align-items-center justify-content-end mainbtns"> 
                    <button className='createbtn' onClick={()=>setShowModal(true)}>Add Course</button>
            </div>
            
            </div>
            </div>

            <div className='lowersection'>
                <div className='container-fluid px-4'>
                    <div className='modulelist'>
                        <div className="course-tiles">
                            {list.map((item, index)=>{                                
                                if(!item.name.toLowerCase().includes(search.toLowerCase())) return;
                                const MAX_TAGS_TO_DISPLAY = 2;
                                const allTags = item.tags.map(tag => tag.name);
                                const displayedTags = allTags.slice(0, MAX_TAGS_TO_DISPLAY);
                                const remainingTags = allTags.slice(MAX_TAGS_TO_DISPLAY);
                                const remainingTagsCount = allTags.length - displayedTags.length;
                               
                                return(
                                    <div className="tilecard" >
                                        <div className='modulecard module' key={index + 1} onClick={() => navigate(`/dashboard/2d/lms/details/${item.uuid}`, { state: { name: item.name, type: "2DLMS" } })}>                                                                                                  
                                            <div className='moduleicon'>
                                                {item?.versions[0]?.data?.thumbnail ? (
                                                    <img src={item.versions[0].data.thumbnail} alt="Thumbnail" />
                                                ) : (
                                                    <img src={logo} className='defaultimg' alt="Default Thumbnail" />
                                                )}
                                            </div>
                                            <div className='moduledata pb-3'>
                                                <Tooltip title={item.name}>
                                                  <p className='modulename'> {item.name} </p>
                                                </Tooltip>
                                                <div className="detailtags pb-0" style={{ justifyContent: "center" }}>
                                               
                                                    {item?.tags?.length > 0 && (
                                                        displayedTags.map((tag, index) => {
                                                            return (
                                                                <React.Fragment key={index + 1}>
                                                                    <Tooltip title={tag}>
                                                                    <p> {tag} </p>
                                                                    </Tooltip>

                                                                    <Tooltip title={remainingTags.map((remTag) => remTag).join(', ')} placement="top" arrow>
                                                                        <span className='remainitmorebutton'>{index === MAX_TAGS_TO_DISPLAY - 1 && remainingTagsCount > 0 ? <p>+{remainingTagsCount} More</p> : null}</span>
                                                                    </Tooltip>

                                                                </React.Fragment>
                                                            );
                                                        })
                                                        )}
                                                </div>  
                                                <p className='moduledes'>{item.versions[0].data.short}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>



         <Create2DLmsModal showModal={showModal} setShowModal={setShowModal} get2DLmsModuleList={get2DLmsModuleList} />
        </>

    )
}

export default LMS2DList