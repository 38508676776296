import React, { useEffect, useState } from 'react'
import { FileUploader } from "react-drag-drop-files";
import {
    FormControl,
    RadioGroup,
    Radio,
    FormControlLabel,
} from "@mui/material";
import { CancelIcon, UploadStat } from '../../Static/icons/iconshere';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import moment from 'moment';
import Releases from '../Releases/releases';
import { BtnLoading } from '../../animations/BtnLoading';
import Progress from 'react-progressbar';
import { debounce } from '../../utils/useWindowDimensions';
import { environmentPlatforms } from '../../utils/constants';


const fileTypes = ["Zip", "Apk File"];

const ENVAssets = () => {
    const configData = useSelector((state) => state.configSlice);
    const userData = useSelector((state) => state.userSlice);

    const { uuid } = useParams();
    const [uploadFile, setUploadFile] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadedFileURL, setUploadedFileURL] = useState(null)
    const [selectedAssetType, setSelectedAssetType] = useState("ab");
    const [selectedPlatform, setSelectedPlatform] = useState("standalonevr");
    const [fileUploadProcess, setFileUploadProcess] = useState(false);
    const [uploadError, setUploadError] = useState(null);
    const [versionList, setVersionList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [comment, setComment] = useState("");
    const [error, setError] = useState(false);

    const getModuleVersionList = async () => {

        setVersionList([])
        axios.get(`${configData.config.UNO_URL}/api/resource/environment/asset/list/version/${selectedPlatform}/${uuid}`, {
            headers: {
                Authorization: `Bearer ${userData?.token}`,
                "Content-Type ": "application/json"
            }
        })
            .then((res) => {
                setVersionList(res?.data?.data);
            })
            .catch((err) => {
                console.log(err);
            })


    }

    useEffect(() => {
        getModuleVersionList();
    }, [selectedPlatform])

    const handleChangeUploadFile = async (file) => {
        setError(false);
        const formData = new FormData();
        formData.append('file', file);
        setUploadFile(file)
        setFileUploadProcess(true)
        await axios.post(`${configData.config.UNO_URL}/api/resource/environment/upload/${selectedPlatform}/${uuid}`, formData, {
            headers: {
                Authorization: `Bearer ${userData?.token}`,
                "Content-Type ": "multipart/form-data"
            },
            onUploadProgress: (progressEvent) => {
                let progress = (progressEvent.loaded / progressEvent.total) * 100;
                if (progress < 100) {
                    setUploadProgress(Math.floor(progress));
                }
            }
        }).then((res) => {
            setUploadProgress(100)
            setUploadedFileURL(res?.data?.url);
            setFileUploadProcess(false);
        }).catch((err) => {
            console.log(err);
            setFileUploadProcess(false);
            setUploadedFileURL(null);
            setError(true);
        })
    };

    const handleChangePlatform = (file) => {
        setSelectedPlatform(file);
        setUploadedFileURL(null);
        setUploadFile(null);
    }

    const saveEnvironmentAsset = async () => {
        setUploadError(null);

        try {
                if (uploadedFileURL?.length === 0 || comment.trim().length === 0) {
                    setUploadError("Upload file and fill comment field.");
                    setLoading(false);
                    return
                }

                let data = {
                    type: selectedAssetType,
                    platform: selectedPlatform,
                    assetLink: uploadedFileURL,
                    comment: comment
                };

                await axios.post(
                    `${configData.config.UNO_URL}/api/resource/environment/asset/create/version/${selectedPlatform}/${uuid}`,
                    data,
                    {
                        headers: {
                            Authorization: `Bearer ${userData?.token}`,
                            "Content-Type": "application/json"
                        }
                    }
                );

                setComment("");
                setUploadFile(null);

        } catch (error) {
            console.error(error);
            setUploadedFileURL(null);
        } finally {
            setLoading(false)
            getModuleVersionList()
        }
    };

    const formatSize = (bytes) => {
        if (bytes >= 1048576) {
            return (bytes / 1048576).toFixed(2) + " MB";
        } else if (bytes >= 1024) {
            return (bytes / 1024).toFixed(2) + " KB";
        } else {
            return bytes + " bytes";
        }
    };

    const debounceHandleSaveReview = debounce(saveEnvironmentAsset, 1000, setLoading);
    return (
        <>

            <div className='envdetails forassets' >
                <div className='container-fluid'>
                    <div className='row mt-2'>
                        <div className='col-md-6 detailspage'>
                            <div className='platforupload'>
                                <div className="popupselect">
                                    <p>Platforms</p>
                                    <div className="radio-inputs">
                                        <FormControl fullWidth className="mb-3 d-flex align-items-start" >
                                            <RadioGroup
                                                row
                                                aria-labelledby="price-visibility-label"
                                                name="questionType"
                                                className="gap-3"
                                                value={selectedPlatform}
                                                onChange={(e) => handleChangePlatform(e.target.value)}
                                            >
                                                {environmentPlatforms.map((platform, index) => {
                                                    return (
                                                        <FormControlLabel
                                                            key={index}
                                                            className="quizlabel"
                                                            value={platform.key}
                                                            control={<Radio />}
                                                            label={platform.name}
                                                        />
                                                    )
                                                })}
                                            </RadioGroup>
                                        </FormControl>
                                    </div>

                                </div>
                            </div>
                            {userData?.user?.permissions?.includes("CONTENT.ENVIRONMENT.CREATE") &&
                                <>
                                    <div className="DragDropMenu uploadfilesss mt-3">
                                        <div className='fileuploader'>
                                            <FileUploader
                                                handleChange={handleChangeUploadFile}
                                                label={uploadFile?.name ? "drag & drop or choose file to upload" : ""}
                                                name="file"
                                                types={fileTypes}
                                            >
                                                <span className='upload-box-info'>drag & drop or choose only ZIP file to upload</span>
                                            </FileUploader>
                                        </div>

                                        {uploadFile ?
                                            <div className='uploadstatus'>
                                                <div className="uploadstatus">
                                                    {!error &&
                                                        <div className="upperdiv">
                                                            <div className="uploaddata">
                                                                <div className="xr">
                                                                    <p>{uploadFile.name}</p>
                                                                    <p
                                                                        style={{ cursor: "pointer", lineHeight: '1' }}
                                                                        onClick={() => { setUploadFile(null); setUploadProgress(0) }}
                                                                    >
                                                                        <i className="bi bi-x-circle-fill"></i>
                                                                    </p>
                                                                </div>
                                                                <div className=" xr mb-1">
                                                                    <p className="filesize">{formatSize(uploadFile.size)} </p>
                                                                    <p className="filesize">{uploadProgress}%</p>
                                                                </div>
                                                                <Progress completed={uploadProgress} />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>

                                                {error &&
                                                    <div className="uploadProgressBar">
                                                        <p className='basicDetailError'> Error in file upload. try again </p>
                                                    </div>
                                                }

                                            </div>
                                            :
                                            null
                                        }
                                        <div className="mb-0 mt-2 VComment">
                                            <label htmlFor="exampleFormControlTextarea1" className="form-label">Comment <span>*</span></label>
                                            <textarea value={comment} onChange={(e) => setComment(e.target.value)} className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                                            <p className='uploadAssetsError'>{uploadError}</p>
                                        </div>
                                    </div>
                                </>
                            }
                            {userData?.user?.permissions?.includes("CONTENT.ENVIRONMENT.CREATE") &&
                                <div className="deploybtn">
                                    <button className="button" disabled={fileUploadProcess} onClick={debounceHandleSaveReview}>
                                        <div className="text">
                                            {
                                                loading ? <BtnLoading /> : 'Save'
                                            }
                                        </div>
                                    </button>
                                </div>
                            }
                        </div>
                        <div className='col-md-6 mt-3'>
                            {versionList.length > 0 ?
                                <div className='cardstyle'>

                                    <div className="deploybtn d-flex justify-content-between">
                                        <div>
                                            <div className='popupselect fortype'>
                                                <p className='mb-0'> {versionList[0]?.createdAt ? moment(versionList[0]?.createdAt).fromNow() : null} </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='filedetails'>
                                        <p>Version :<span>{versionList[0]?.version}</span></p>
                                        <p>Asset :<span><a href={versionList[0]?.data?.assetLink} download>{versionList[0]?.data?.assetLink?.split("/")[versionList[0]?.data?.assetLink?.split("/")?.length - 1]}</a></span></p>
                                        <p>Uploaded By :<span>{versionList[0]?.activities[0]?.users[0]?.name}</span></p>
                                        <p>Timestamp :<span>{versionList[0]?.createdAt ? moment(versionList[0]?.createdAt).format('MMMM Do YYYY, h:mm a') : null}</span></p>
                                        <span className='versionlistv'>{versionList[0]?.data?.comment}</span>
                                    </div>
                                </div>
                                : null}
                        </div>
                    </div>
                    <div className='ReleaseHead'>
                        {versionList.length > 0 ? <h1>Releases</h1> : null}
                    </div>

                    {versionList?.map((list, index) => {
                        return <Releases list={list} key={index + 1} />
                    })}
                </div>
            </div>

        </>
    )
}

export default ENVAssets