import React from 'react'
import LottiePlayer from "lottie-react";
import NoSOPFoundJson from '../animations/nodatafound.json';


const NoFiles = ({message}) => {
    return (
        <>
            <div className="nodatafoundss">
                <LottiePlayer
                    animationData={NoSOPFoundJson}
                    autoplay
                    loop
                />
                <h5>{message}</h5>
            </div>
        </>
    )
}

export default NoFiles
