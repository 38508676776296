import React, { useEffect, useState } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { useSelector } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import BasicDetailsTab from './BasicDetailsTab';
import axios from 'axios';
import SopTab from '../SOP/soppage';
import SectionManager from './SectionManager/SectionManager';
import Channels from '../Channels/channels';


const LMSDetailsPage = () => {
  const configData = useSelector((state) => state.configSlice);
  const userData = useSelector((state) => state.userSlice);
  const navigate = useNavigate();
  const params = useParams();
  const uuid = params?.uuid;
  const location = useLocation();
  const locationData = location.state || {};
  const [currentTab, setCurrentTab] = useState("details");
  const [resource, setResource] = useState({
    name:"",
    thumbnail:"",
  });


  useEffect(()=>{
    if(locationData?.tab){
      setCurrentTab(locationData.tab)
    }else{
      setCurrentTab("details");
    }
  },[locationData])

  const handleTabSelect = (selectedTab) => {
    if(selectedTab == "back"){
      navigate("/dashboard/2d/lms")
      return
   }
    setCurrentTab(selectedTab);
  }


  return (
    <>
      <div className='filterhere'>
        <Tabs activeKey={currentTab} onSelect={handleTabSelect} >

                <Tab title={<span> <i className="bi-chevron-left"></i> Back </span>} eventKey="back" />  
                
                <Tab eventKey="details" title="Basic Details"> 
                   <BasicDetailsTab setResource={setResource} />
                </Tab>

                <Tab eventKey="sectionManager" title="Course Structure" >
                    <SectionManager resource={resource} />
                </Tab>
              
                <Tab eventKey="sop" title="Knowledge Base" >
                    <div className='mt-5'>
                    <SopTab apiRoute="2d/lms" upload="2d/lms/upload"/>
                    </div>
                </Tab>

                {/* <Tab eventKey="publish" title="Publish" >
                      <Channels />
                </Tab> */}
        </Tabs>
      </div>
    </>
  )
}

export default LMSDetailsPage;
